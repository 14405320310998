import React, { useState, useEffect, useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { FirmDatabase } from '../../database/firmDatabase';
import { debounce } from '@mui/material/utils';
import { captureException } from '../../sentry/sentry';
import { MerchantAccount } from '../../database/baseTypes';

interface AccountAutocompleteProps {
  firmDb?: FirmDatabase;
  value?: string;
  error: boolean;
  helperText?: string;
  inputRef?: React.Ref<any>;
  onSelect: (account?: string) => void;
}

const pinkFocusStyles = {
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ff00bf',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#ff00bf',
  },
};

const AccountAutocomplete = ({
  firmDb,
  value,
  error,
  helperText,
  inputRef,
  onSelect,
}: AccountAutocompleteProps) => {
  const [options, setOptions] = useState<readonly MerchantAccount[]>([]);

  useEffect(() => {
    const fetchAccounts = async () => {
      if (!firmDb) {
        setOptions([]);
        return;
      }

      try {
        const firmDoc = await firmDb.getFirm();
        const accounts = firmDoc.data()?.merchantAccounts || [];
        setOptions(accounts);
      } catch (error) {
        console.error('Error fetching accounts: ', error);
        captureException(error);
        setOptions([]);
      }
    };

    fetchAccounts();
  }, [firmDb]);

  return (
    <Autocomplete
      options={options}
      noOptionsText="No accounts"
      onChange={(_, newValue) => onSelect(newValue?.name ?? undefined)}
      value={options.find((opt) => opt.name === value) || null}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          label="Account"
          fullWidth
          error={error}
          helperText={helperText}
          required
          sx={pinkFocusStyles}
        />
      )}
    />
  );
};

export default AccountAutocomplete;
