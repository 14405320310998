import { useState, useEffect } from 'react';
import Select from 'react-select';
import { SelectOption } from '../../types';
import { Form } from '../types';
import { useMantineColorScheme } from '@mantine/core';

interface ControlledSelectProps {
  fieldName: string;
  options: SelectOption[];
  form: Form;
  selectProps: any;
  label: string;
}

const ControlledSelect = ({
  fieldName,
  options,
  form,
  selectProps,
  label,
}: ControlledSelectProps) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(
    form.values[fieldName as keyof typeof form.values] as string | null,
  );
  const { colorScheme } = useMantineColorScheme();

  // Update local state when form value changes externally
  useEffect(() => {
    setSelectedValue(
      form.values[fieldName as keyof typeof form.values] as string | null,
    );
  }, [form.values[fieldName as keyof typeof form.values]]);

  // Merge our input style with existing styles
  const mergedStyles = {
    ...selectProps.styles,
    input: (base: any) => ({
      ...(selectProps.styles?.input?.(base) || base),
      color:
        colorScheme === 'dark' ? 'var(--mantine-color-dark-0)' : base.color,
    }),
    option: (base: any, state: any) => ({
      ...(selectProps.styles?.option?.(base) || base),
      backgroundColor:
        colorScheme === 'dark'
          ? state.isFocused
            ? 'var(--mantine-color-dark-4)' // darker background for focus/hover in dark mode
            : 'var(--mantine-color-dark-7)'
          : base.backgroundColor,
      '&:active': {
        backgroundColor:
          colorScheme === 'dark'
            ? 'var(--mantine-color-dark-3)' // even darker for active state
            : base.backgroundColor,
      },
    }),
  };

  return (
    <div className='field'>
      <label>{label}</label>
      <Select
        {...selectProps}
        options={options}
        defaultFocusedOption={null}
        styles={mergedStyles}
        value={options.find((opt) => opt.value === selectedValue)}
        onChange={(selected: SelectOption | null) => {
          const newValue = selected?.value || null;
          setSelectedValue(newValue);
          form.setFieldValue(fieldName, newValue);
        }}
      />
    </div>
  );
};

export default ControlledSelect;
