/* optionSets.js naming convention is collectionField,
e.g. matterTypes means that "types" is a field within the "matter" collection. */

import { SelectOption } from './components/types';
import { InsuranceClaimStatus, MatterStatus } from './database/baseTypes';

export type MatterType =
  | 'ARAG'
  | 'MetLife'
  | 'LegalEase'
  | 'FlatFee'
  | 'Hourly'
  | `Don't Know`;

export const matterType = {
  arag: {
    label: 'ARAG',
    options: {
      fullCoverage: { label: 'Full Coverage', value: 'fullCoverage' },
      partialCoverage: { label: 'Partial Coverage', value: 'partialCoverage' },
    },
  },
  metLife: { label: 'MetLife', value: 'metLife' },
  legalEase: { label: 'LegalEase', value: 'legalEase' },
  flatFee: {
    label: 'Flat Fee',
    options: {
      estatePlanning: { label: 'Estate Planning', value: 'estatePlanning' },
      nonEstatePlanning: {
        label: 'Non-Estate-Planning',
        value: 'nonEstatePlanning',
      },
    },
  },
  hourly: { label: 'Hourly', value: 'hourly' },
  subscription: { label: 'Subscription', value: 'subscription' },
  unknown: { label: 'Unknown', value: 'unknown' },
  project: { label: 'Poject', value: 'project' },
};

export const matterTypeSelectOptions: SelectOption<MatterType>[] = [
  { label: 'ARAG', value: 'ARAG' },
  { label: 'MetLife', value: 'MetLife' },
  { label: 'LegalEase', value: 'LegalEase' },
  { label: 'FlatFee', value: 'FlatFee' },
  { label: 'Hourly', value: 'Hourly' },
  { label: `Don't Know`, value: `Don't Know` },
];

export const matterStatusSelectOptions: SelectOption<MatterStatus>[] = [
  { label: 'New', value: 'new' },
  { label: 'Waiting Room', value: 'pendingEngagement' },
  { label: 'Engagement Letter Sent', value: 'engagementLetterSent' },
  { label: 'Open', value: 'open' },
  { label: 'Earned but Not Closed', value: 'earnedButNotClosed' },
  { label: 'Closed', value: 'closed' },
  { label: 'Rejected', value: 'rejectedByAttorney' },
];

export const usStates = [
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'Illinois', value: 'Illinois' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Montana', value: 'Montana' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'New York', value: 'New York' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Washington', value: 'Washington' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'Wyoming', value: 'Wyoming' },
  { label: 'American Samoa', value: 'American Samoa' },
  { label: 'Guam', value: 'Guam' },
  { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'U.S. Virgin Islands', value: 'U.S. Virgin Islands' },
  {
    label: 'U.S. Minor Outlying Islands',
    value: 'U.S. Minor Outlying Islands',
  },
];

export const attorney = [
  { label: 'Russell Wilson', value: 'Ru4DHYm1dVHOr3xhUPLK' },
  { label: 'Matthew Stafford', value: '544brbecCuAJsvqFkREi' },
];

export const transactionMethod = [
  'card',
  'check',
  'eCheck',
  'retainerTransfer',
];

export const transactionType = [
  'retainerTransfer',
  'retainerDeposit',
  'invoicePayment',
  'insurance',
];

export const claimStatusSelectOptions: SelectOption<InsuranceClaimStatus>[] = [
  { value: 'readyToFile', label: 'Ready to File' },
  { value: 'submitted', label: 'Submitted' },
  { value: 'approved', label: 'Approved' },
  { value: 'paymentPending', label: 'Payment Pending' },
  { value: `paid`, label: `Paid` },
  { value: `denied`, label: `Denied` },
];
