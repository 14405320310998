import { useState, useEffect, useRef, useContext } from 'react';
import './Modal.css';
import { AuthenticatedSessionContext } from '../authenticatedSession/AuthenticatedSessionContext';
import { useMatterNoteAdd } from '../database/hooks';
import MatterAutocomplete, { Matter } from './MatterAutocomplete';
import { FirmDatabase } from '../database/firmDatabase';

interface Props {
  fixedMatterId: string | undefined;
  onClose: () => void;
}

const NotesModal = ({ fixedMatterId, onClose }: Props) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const modalRef = useRef<HTMLDivElement>(null);
  const addMatterNote = useMatterNoteAdd();
  const [noteContent, setNoteContent] = useState('');
  const [selectedMatter, setSelectedMatter] = useState<Matter | undefined>(undefined);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const handleSaveNote = async (firmDb: FirmDatabase | undefined) => {
    if (!firmDb) {
      console.warn('Attempting to save note without a database');
      onClose();
      return;
    }

    if (!fixedMatterId && !selectedMatter) {
      alert('Please select a matter');
      return;
    }

    if (!authSession) {
      alert('Please log in to add a note');
      return;
    }

    const matterId = fixedMatterId ?? selectedMatter!.id;

    await addMatterNote(matterId, {
      content: noteContent,
      createdTimestamp: new Date(),
      userId: authSession.userId,
    });

    onClose();
  };

  return (
    <div className='modal-backdrop'>
      <div ref={modalRef} className='modal-container'>
        <h2>New Note</h2>
        <div style={{ width: '100%' }}>
          {!fixedMatterId && (
            <MatterAutocomplete
              firmDb={authSession?.db}
              value={selectedMatter}
              error={!selectedMatter && !fixedMatterId}
              onSelect={setSelectedMatter}
              inputRef={undefined}
              helperText=""
            />
          )}
          <textarea
            className='modal-textarea'
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            placeholder='Write your note here...'
            style={{ width: '100%' }} // Ensures the textarea takes full width
          />
        </div>
        <button className="primary-button" onClick={() => handleSaveNote(authSession?.db)}>Submit</button>
        <button className='outline-button' onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default NotesModal;
