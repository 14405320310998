import { MatterData } from '../../../database/baseTypes';
import { AddEditMatterFormData } from '../types';
import { assertUnreachable, MissingFieldError } from './saveFormData';
import { createPaymentPage } from '../../../cloudFunctions/worldpay';
import { addOrUpdateMatterContacts } from './addOrUpdateMatterContacts';
import { FirmDatabase } from '../../../database/firmDatabase';

export const createNewMatter = async (props: {
  firmDb: FirmDatabase;
  formData: AddEditMatterFormData;
  useContactSearch: boolean;
  usePractistPayments: boolean;
}) => {
  const { firmDb, formData, useContactSearch, usePractistPayments } = props;

  if (!formData.state) {
    throw new MissingFieldError('State is required');
  }

  if (!formData.matterStatus) {
    throw new MissingFieldError('Matter status is required');
  }

  if (!formData.matterType) {
    throw new MissingFieldError('Matter type is required');
  }

  // Create the raw matter data
  const matterData: MatterData = {
    createdTimestamp: new Date(),
    matterType: formData.matterType ?? `Don't Know`,
    name: constructMatterNameFromFormData(formData),
    responsibleAttorney: formData.responsibleAttorneyId ?? undefined,
    state: formData.state,
    status: formData.matterStatus,
    subStatusId: formData.matterSubStatusId ?? undefined,
    adverseParty: formData.adverseParty ?? undefined,
    description: formData.description ?? undefined,
    leadSourceId: formData.leadSourceId ?? undefined,
    scope: formData.scope ?? undefined,
  };

  switch (formData.matterType) {
    case 'ARAG':
      matterData.caseAssist = formData.caseAssist ?? undefined;
      matterData.memberId = formData.memberId ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'MetLife':
      matterData.metLifeClaimNumber = formData.metLifeClaimNumber ?? undefined;
      matterData.socialSecurityNumber =
        formData.socialSecurityNumber ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'LegalEase':
      matterData.caseNumber = formData.caseNumber ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'Hourly':
      matterData.retainer = formData.retainer ?? undefined;
      matterData.responsibleAttyHourlyRate =
        formData.responsibleAttyHourlyRate ?? undefined;
      matterData.referralOriginator =
        formData.referralOriginatorId ?? undefined;
      matterData.engagementOriginator =
        formData.engagementOriginatorId ?? undefined;
      break;
    case 'FlatFee':
      matterData.flatFeeAmt = formData.flatFeeAmt ?? undefined;
      matterData.referralOriginator =
        formData.referralOriginatorId ?? undefined;
      matterData.engagementOriginator =
        formData.engagementOriginatorId ?? undefined;
      break;
    case `Don't Know`:
      break;
    default:
      return assertUnreachable(formData);
      break;
  }

  const matterRef = await firmDb.addMatter(matterData);
  await addOrUpdateMatterContacts(firmDb, matterRef.id, formData);

  if (usePractistPayments) {
    await createPaymentPage({
      accountType: 'operating',
      matterId: matterRef.id,
      matterUsState: formData.state,
      isSandbox: process.env.NODE_ENV === 'development',
    });

    await createPaymentPage({
      accountType: 'trust',
      matterId: matterRef.id,
      matterUsState: formData.state,
      isSandbox: process.env.NODE_ENV === 'development',
    });
  }
};

const constructMatterNameFromFormData = (formData: AddEditMatterFormData) => {
  if (formData.orgContact) {
    return formData.orgContact.companyName;
  }

  if (formData.humanContacts.length > 0) {
    // For multiple human contacts, join their names in the desired format
    // Assuming each contact in humanContacts array has lastName and firstName
    return formData.humanContacts
      .map((contact) => `${contact.lastName}, ${contact.firstName}`)
      .join('; ');
  }

  return 'Error: Missing Matter Name';
};
