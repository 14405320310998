import { Matter } from '../MatterAutocomplete';

type FormField<T> = { value: T; error?: string };
export interface FormState {
  assignedTo: FormField<string | undefined>;
  matter: FormField<Matter | undefined>;
  description: FormField<string | undefined>;
  dueDate: FormField<string | undefined>;
}

export const defaultState = (assignedTo: string | undefined, matter: Matter | undefined): FormState => {
  return {
    assignedTo: { value: assignedTo },
    matter: { value: matter },
    description: { value: undefined },
    dueDate: { value: undefined },
  };
};

export type FormAction =
  | { type: 'SET_DESCRIPTION_VAL'; description: string }
  | { type: 'SET_MATTER_VAL'; matter: Matter | undefined }
  | { type: 'SET_ASSIGNED_TO_VAL'; assignedTo: string | undefined }
  | { type: 'SET_DUE_DATE_VAL'; dueDate: string }
  | { type: 'SET_DESCRIPTION_ERROR'; error: string }
  | { type: 'SET_MATTER_ERROR'; error: string }
  | { type: 'SET_ASSIGNED_TO_ERROR'; error: string }
  | { type: 'SET_DUE_DATE_ERROR'; error: string }
  | { type: 'CLEAR_FORM' };

export const formReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case 'SET_DESCRIPTION_VAL':
      return { ...state, description: { value: action.description } };
    case 'SET_MATTER_VAL':
      return { ...state, matter: { value: action.matter } };
    case 'SET_ASSIGNED_TO_VAL':
      return { ...state, assignedTo: { value: action.assignedTo } };
    case 'SET_DUE_DATE_VAL':
      return { ...state, dueDate: { value: action.dueDate } };
    case 'SET_DESCRIPTION_ERROR':
      return { ...state, description: { ...state.description, error: action.error } };
    case 'SET_MATTER_ERROR':
      return { ...state, matter: { ...state.matter, error: action.error } };
    case 'SET_ASSIGNED_TO_ERROR':
      return { ...state, assignedTo: { ...state.assignedTo, error: action.error } };
    case 'SET_DUE_DATE_ERROR':
      return { ...state, dueDate: { ...state.dueDate, error: action.error } };
    case 'CLEAR_FORM':
      return defaultState(state.assignedTo.value, state.matter.value);
    default:
      return state;
  }
};
