import { FeatureFlag } from './types';

/**
 * This feature flag enables use of Practist Payments.
 *
 * This feature flag is enabled if:
 * 1. The firm ID is 'lyda-law-firm-29vsa' or 'fake-law-firm-78lrx'
 * 2. The user's list of roles includes 'developer'
 */
export const practistPaymentsFeatureFlag: FeatureFlag = {
  id: 'practist-payments',
  description: 'Enable Practist Payments',
  rule: {
    type: 'and',
    rules: [
      {
        type: 'or',
        rules: [
          {
            type: 'firmIdEquals',
            value: 'lyda-law-firm-29vsa',
          },
          {
            type: 'firmIdEquals',
            value: 'fake-law-firm-78lrx',
          },
        ],
      },
      {
        type: 'userHasRole',
        value: 'developer',
      },
    ],
  },
};

/**
 * This feature flag enables an alternative contact search UI when adding or editing a matter.
 */
export const existingContactSearchFlag: FeatureFlag = {
  id: 'existing-contact-search',
  description: 'Enable existing contact search',
  rule: {
    type: 'or',
    rules: [
      {
        type: 'boolean',
        value: true,
      },
    ],
  },
};
