import { UserData } from '../../../database/baseTypes';
import { AddEditMatterFormData } from '../types';
import { FlattenedMatter } from '../../../database/aggregateTypes';
import { isFeatureFlagEnabled } from '../../../featureFlags/isFeatureFlagEnabled';
import {
  practistPaymentsFeatureFlag,
  existingContactSearchFlag,
} from '../../../featureFlags/featureFlagDefinitions';
import { AuthenticatedSession } from '../../../authenticatedSession/authenticatedSession';
import { createNewMatter } from './createNewMatter';
import { updateExistingMatter } from './updateExistingMatter';

export const assertUnreachable = (x: never): never => {
  throw new Error("Didn't expect to get here");
};

export class MissingFieldError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MissingFieldError';
  }
}

export const saveFormData = async (
  authSession: AuthenticatedSession,
  currentUser: UserData | undefined,
  matter: FlattenedMatter | undefined,
  formData: AddEditMatterFormData,
) => {
  const useContactSearch = currentUser
    ? isFeatureFlagEnabled(existingContactSearchFlag, authSession, currentUser)
    : false;

  const usePractistPayments = currentUser
    ? isFeatureFlagEnabled(
        practistPaymentsFeatureFlag,
        authSession,
        currentUser,
      )
    : false;

  if (formData.orgContact && formData.orgContact.companyName.trim() === '') {
    throw new Error('Company name is required');
  }

  if (formData.humanContacts.length === 0) {
    if (formData.orgContact) {
      throw new Error('At least one company representative is required');
    }
    throw new Error('At least one contact is required');
  }

  if (matter) {
    await updateExistingMatter(
      authSession.db,
      matter,
      formData,
      useContactSearch,
    );
  } else {
    await createNewMatter({
      firmDb: authSession.db,
      formData,
      useContactSearch,
      usePractistPayments,
    });
  }
  return;
};
