import '../Modal.css';
import { useEffect, PropsWithChildren } from 'react';
import styles from './QuickEntryModal.module.css';
import { Snackbar, Alert, Button } from '@mui/material';

interface QuickEntryProps {
  title: string;
  statusMessage?: StatusMessage;
  saveButtonText?: string;
  saveButtonDisabled?: boolean;
  saveButtonStyleOverrides?: React.CSSProperties;
  onClose: () => void;
  onSave: () => void;
}

export const QuickEntryModal = ({
  title,
  children,
  statusMessage,
  saveButtonText,
  saveButtonDisabled,
  saveButtonStyleOverrides,
  onClose,
  onSave,
}: PropsWithChildren<QuickEntryProps>) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if ((event.target as Element)?.className === 'modal-backdrop') {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  return (
    <div className='modal-backdrop'>
      <div className={styles.cardBackground}>
        <form
          className={styles.outerContentWrapper}
          onSubmit={(e) => {
            e.preventDefault();
            onSave();
          }}
        >
          <h2>{title}</h2>
          <div className={styles.fieldsWrapper}>{children}</div>
          <div className={styles.footer}>
            <button
              disabled={saveButtonDisabled === true}
              className="primary-button"
              style={saveButtonStyleOverrides}
              type='submit'
            >
              {saveButtonText ? ` ${saveButtonText} ` : 'Submit'}
            </button>
            <button
              className={`outline-button ${styles.cancelButton}`}
              type='button'
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      <Snackbar
        open={!!statusMessage}
        autoHideDuration={statusMessage?.autoHideDuration ?? 3000}
        onClick={statusMessage?.onClose}
        onClose={statusMessage?.onClose}
      >
        <Alert
          className={styles.statusAlert}
          severity={statusMessage?.severity}
          variant='filled'
          action={
            statusMessage?.onUndo && (
              <UndoAction onUndo={statusMessage.onUndo} />
            )
          }
        >
          {statusMessage?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export interface StatusMessage {
  message: string | JSX.Element;
  severity: 'success' | 'error' | 'info';
  autoHideDuration: number;
  onClose: () => void;
  onUndo?: () => void;
}

const UndoAction = ({ onUndo }: { onUndo: () => void }) => {
  return (
    <Button color='inherit' size='small' onClick={onUndo}>
      Undo
    </Button>
  );
};
