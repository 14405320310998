import '@mantine/spotlight/styles.css';
import { Spotlight, SpotlightActionData } from '@mantine/spotlight';
import { Search } from 'lucide-react';
import { useMatters } from '../../database/hooks';
import { useMemo, useState } from 'react';
import TimeEntryModal from '../TimeEntryModal/TimeEntryModal';
import ExpenseEntryModal from '../ExpenseEntryModal/ExpenseEntryModal';
import TaskModal from '../TaskModal/TaskModal';
import NotesModal from '../NotesModal';
import AddEditMatterModal from '../AddEditMatter/AddEditMatterModal';
import { levenshteinDistance, truncateMiddle } from './utils';
import TransactionEntryModal from '../TransactionEntryModal/TransactionEntryModal';
import { useNavigate } from 'react-router-dom';

export function SpotlightSearch({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const allMatters = useMatters();
  const [hasTyped, setHasTyped] = useState(false);
  const [query, setQuery] = useState('');
  const [timeEntryModalOpen, setTimeEntryModalOpen] = useState(false);
  const [expenseEntryModalOpen, setExpenseEntryModalOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [addMatterModalOpen, setAddMatterModalOpen] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);

  // Create matter actions
  const matterActions: SpotlightActionData[] = useMemo(() => {
    if (!allMatters) return [];
    return allMatters.map((matter) => ({
      id: matter.id,
      label: matter.name,
      description:
        matter.matterType && matter.scope
          ? truncateMiddle(`${matter.matterType} - ${matter.scope}`, 80)
          : undefined,
      onClick: () => navigate(`/matter/${matter.id}`),
    }));
  }, [allMatters, navigate]);

  // Create quick actions with keywords
  const quickActions: SpotlightActionData[] = useMemo(
    () => [
      {
        id: 'new-matter',
        label: 'New Matter',
        description: 'Create / add new matter',
        keywords: [
          'create',
          'add',
          'new',
          'matter',
          'client',
          'case',
          'person',
          'company',
        ],
        onClick: () => setAddMatterModalOpen(true),
      },
      {
        id: 'new-time-entry',
        label: 'New Time Entry',
        description: 'Create / add new time entry',
        keywords: [
          'create',
          'add',
          'new',
          'time',
          'entry',
          'hours',
          'timesheet',
          'log',
          'hourly',
        ],
        onClick: () => setTimeEntryModalOpen(true),
      },
      {
        id: 'new-expense-entry',
        label: 'New Expense Entry',
        description: 'Create / add new expense entry',
        keywords: [
          'create',
          'add',
          'new',
          'expense',
          'cost',
          'payment',
          'receipt',
          'bill',
        ],
        onClick: () => setExpenseEntryModalOpen(true),
      },
      {
        id: 'new-task',
        label: 'New Task',
        description: 'Create / add new task',
        keywords: [
          'create',
          'add',
          'new',
          'task',
          'todo',
          'assignment',
          'work',
          'project',
          'to-do',
        ],
        onClick: () => setTaskModalOpen(true),
      },
      {
        id: 'new-note',
        label: 'New Note',
        description: 'Create / add new note',
        keywords: [
          'create',
          'add',
          'new',
          'note',
          'memo',
          'comment',
          'write',
          'record',
        ],
        onClick: () => setNotesModalOpen(true),
      },
      {
        id: 'new-transaction',
        label: 'New Transaction',
        description: 'Create / add new transaction',
        keywords: [
          'create',
          'add',
          'new',
          'transaction',
          'money',
          'payment',
          'deposit',
        ],
        onClick: () => setShowTransactionModal(true),
      },
    ],
    [],
  );

  // Filter actions based on search
  const filteredMatterActions = useMemo(() => {
    if (!query.trim() || !matterActions) return [];
    const searchTerm = query.toLowerCase().trim();

    return matterActions
      .filter((action) => {
        const label = action.label?.toLowerCase() ?? '';
        const description = action.description?.toLowerCase() || '';

        // Split both search and label into words
        const searchWords = searchTerm.split(' ');
        const labelWords = label.split(/[\s-]+/); // split on spaces and hyphens

        // Check if all search words match any label word
        const allSearchWordsMatch = searchWords.every((searchWord) =>
          labelWords.some((labelWord) => {
            // Try direct match first
            if (labelWord.includes(searchWord)) {
              return true;
            }
            // Try fuzzy match
            return levenshteinDistance(labelWord.toLowerCase(), searchWord) < 3;
          }),
        );

        if (allSearchWordsMatch) {
          return true;
        }

        // Fallback to description match
        if (description.includes(searchTerm)) {
          return true;
        }

        return false;
      })
      .sort((a, b) => {
        const aLabel = a.label?.toLowerCase() ?? '';
        const bLabel = b.label?.toLowerCase() ?? '';

        if (aLabel.includes(searchTerm) && !bLabel.includes(searchTerm))
          return -1;
        if (!aLabel.includes(searchTerm) && bLabel.includes(searchTerm))
          return 1;

        return 0;
      });
  }, [matterActions, query]);

  const filteredQuickActions = useMemo(() => {
    if (!query.trim()) return quickActions;
    const searchTerm = query.toLowerCase().trim();

    return quickActions
      .filter((action) => {
        const label = action.label?.toLowerCase() ?? '';
        const description = action.description?.toLowerCase() ?? '';
        const keywords =
          (action as any).keywords?.join(' ').toLowerCase() ?? '';

        if (
          label.includes(searchTerm) ||
          description.includes(searchTerm) ||
          keywords.includes(searchTerm)
        ) {
          return true;
        }

        const searchWords = searchTerm.split(' ');
        const targetWords = [
          ...label.split(' '),
          ...description.split(' '),
          ...((action as any).keywords || []),
        ];

        return searchWords.every((searchWord) =>
          targetWords.some(
            (targetWord) =>
              levenshteinDistance(targetWord.toLowerCase(), searchWord) < 2,
          ),
        );
      })
      .sort((a, b) => {
        const aLabel = a.label?.toLowerCase() ?? '';
        const bLabel = b.label?.toLowerCase() ?? '';

        if (aLabel.includes(searchTerm) && !bLabel.includes(searchTerm))
          return -1;
        if (!aLabel.includes(searchTerm) && bLabel.includes(searchTerm))
          return 1;

        return 0;
      })
      .slice(0, 5);
  }, [quickActions, query]);

  return (
    <>
      <Spotlight
        shortcut='mod + k'
        limit={5}
        nothingFound='Nothing found'
        actions={
          hasTyped
            ? [
                ...filteredQuickActions.map((action) => ({
                  ...action,
                  group: 'Quick Actions',
                })),
                ...filteredMatterActions.map((action) => ({
                  ...action,
                  group: 'Matters',
                })),
              ]
            : []
        }
        styles={{
          actionsList: {
            display: hasTyped ? 'block' : 'none',
          },
        }}
        searchProps={{
          placeholder: 'Search matters and actions...',
          leftSection: <Search size={20} />,
          onChange: (event) => {
            setQuery(event.target.value);
            setHasTyped(event.target.value.length > 0);
          },
        }}
        onSpotlightClose={() => {
          setHasTyped(false);
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
          const searchButton = document.querySelector('.search-button');
          if (searchButton instanceof HTMLElement) {
            searchButton.blur();
          }
        }}
      />

      {timeEntryModalOpen && (
        <TimeEntryModal
          fixedMatterId={undefined}
          onClose={() => setTimeEntryModalOpen(false)}
        />
      )}

      {expenseEntryModalOpen && (
        <ExpenseEntryModal
          fixedMatterId={undefined}
          onClose={() => setExpenseEntryModalOpen(false)}
        />
      )}

      {taskModalOpen && (
        <TaskModal
          fixedMatterId={undefined}
          onClose={() => setTaskModalOpen(false)}
        />
      )}

      {notesModalOpen && (
        <NotesModal
          fixedMatterId={undefined}
          onClose={() => setNotesModalOpen(false)}
        />
      )}

      {addMatterModalOpen && (
        <AddEditMatterModal onClose={() => setAddMatterModalOpen(false)} />
      )}

      {showTransactionModal && (
        <TransactionEntryModal onClose={() => setShowTransactionModal(false)} />
      )}

      {children}
    </>
  );
}
