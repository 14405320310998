import { TextField } from '@mui/material';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { firebase } from '../../firebaseConfig';
import { textFieldStyles } from '../../muiStyles/textFieldStyles';
import DateTextField from '../DateTextField';
import MatterAutocomplete from '../MatterAutocomplete';
import {
  QuickEntryModal,
  StatusMessage,
} from '../QuickEntryModal/QuickEntryModal';
import UserAutocomplete from '../UserAutocomplete';
import { defaultState, formReducer } from './reducer';
import { TaskData } from '../../database/baseTypes';
import { AuthenticatedSessionContext } from '../../authenticatedSession/AuthenticatedSessionContext';
import { useTaskAdd } from '../../database/hooks';
import { YearMonthDay } from '../../database/yearMonthDay';

const TaskModal = ({
  fixedMatterId,
  onClose,
}: {
  fixedMatterId: string | undefined;
  onClose: () => void;
}) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const [state, dispatch] = useReducer(
    formReducer,
    defaultState(authSession?.userId, undefined)
  );
  const [statusMessage, setStatusMessage] = useState<
    StatusMessage | undefined
  >();
  const startingInputRef = useRef<any>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if ((event.target as Element)?.className === 'modal-backdrop') {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  useEffect(() => {
    startingInputRef.current?.focus();
  }, []);

  const addTask = useTaskAdd();

  const handleSave = async () => {
    if (!authSession) {
      return;
    }

    let hasErrors = false;
    if (!fixedMatterId && !state.matter.value) {
      hasErrors = true;
      dispatch({ type: 'SET_MATTER_ERROR', error: 'Required' });
    }
    if (!state.description.value) {
      hasErrors = true;
      dispatch({ type: 'SET_DESCRIPTION_ERROR', error: 'Required' });
    }
    if (!state.assignedTo.value) {
      hasErrors = true;
      dispatch({ type: 'SET_ASSIGNED_TO_ERROR', error: 'Required' });
    }

    if (hasErrors) {
      setStatusMessage({
        message: 'Highlighted fields are required',
        severity: 'error',
        autoHideDuration: 6000,
        onClose: () => setStatusMessage(undefined),
      });
      return;
    }

    const newTaskEntry: TaskData = {
      assignedTo: state.assignedTo.value!,
      matter: fixedMatterId ?? state.matter.value!.key,
      description: state.description.value!,
      dueYmd: state.dueDate.value
        ? YearMonthDay.fromString(state.dueDate.value, 'yyyy-MM-dd')
        : undefined,
      createdTimestamp: new Date(),
      isComplete: false,
      events: [],
    };
    const ref = await addTask(newTaskEntry);

    // Update state synchronously so that we can focus on the starting field without a re-render getting in the way.
    flushSync(() => {
      setStatusMessage({
        message: `New task created successfully!`,
        autoHideDuration: 6000,
        severity: 'success',
        onClose: () => {
          setStatusMessage(undefined);
        },
        onUndo: () => handleUndo(ref),
      });
      dispatch({ type: 'CLEAR_FORM' });
    });

    startingInputRef.current?.focus();
  };

  const handleUndo = async (
    refToDelete: firebase.firestore.DocumentReference<TaskData> | undefined
  ) => {
    if (!refToDelete) {
      return;
    }
    await refToDelete.delete();
    setStatusMessage({
      message: 'Undo successful',
      autoHideDuration: 1500,
      severity: 'info',
      onClose: () => {
        setStatusMessage(undefined);
      },
    });
  };

  return (
    <QuickEntryModal
      title='New Task'
      statusMessage={statusMessage}
      onClose={onClose}
      onSave={handleSave}
    >
      <UserAutocomplete
        firmDb={authSession?.db}
        error={!!state.assignedTo.error}
        selectedUserId={state.assignedTo.value}
        inputRef={startingInputRef}
        onSelect={(userId) =>
          dispatch({
            type: 'SET_ASSIGNED_TO_VAL',
            assignedTo: userId ?? undefined,
          })
        }
      />
      {!fixedMatterId && (
        <MatterAutocomplete
          firmDb={authSession?.db}
          error={!!state.matter.error}
          value={state.matter.value}
          onSelect={(matter) => {
            dispatch({ type: 'SET_MATTER_VAL', matter: matter || undefined });
          }}
        />
      )}
      <TextField
        sx={textFieldStyles}
        label='Description'
        multiline
        rows={4}
        error={!!state.description.error}
        value={state.description.value ?? ''}
        required={true}
        onChange={(e) =>
          dispatch({ type: 'SET_DESCRIPTION_VAL', description: e.target.value })
        }
      />
      <DateTextField
        label='Due Date'
        value={state.dueDate.value}
        error={!!state.dueDate.error}
        required={false}
        onChange={(e) =>
          dispatch({ type: 'SET_DUE_DATE_VAL', dueDate: e.target.value })
        }
      />
    </QuickEntryModal>
  );
};

export default TaskModal;
