import { SelectOption } from '../../types';
import { AddEditMatterFormData } from '../types';
import { NativeSelect } from '@mantine/core';
import { Form } from '../types';

const SelectField = ({
  fieldName,
  label,
  options,
  form,
  ...props
}: {
  fieldName: keyof AddEditMatterFormData;
  label: string;
  options: SelectOption[];
  form: Form;
}) => (
  <NativeSelect
    className='field'
    label={label}
    data={options}
    {...form.getInputProps(fieldName)}
    {...props}
  />
);

export default SelectField;
