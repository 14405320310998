import {
  Button,
  Collapse,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { MantineForm } from '../AddEditMatterForm';
import { AddEditMatterFormData } from '../types';

interface DeveloperPanelProps {
  form: MantineForm;
}

interface JsonViewerProps {
  data: any;
  label: string;
  level?: number;
  isLast?: boolean;
}

const monospaceStyle = {
  fontFamily: 'monospace',
  fontSize: '11px',
};

const verticalLineStyle = {
  position: 'absolute' as const,
  left: 0,
  top: 0,
  bottom: 0,
  width: '1px',
  backgroundColor: '#e0e0e0',
};

const FormStateViewer = ({ form }: { form: MantineForm }) => {
  const renderBooleanValue = (value: boolean) => (
    <Text style={monospaceStyle} c={value ? 'green' : 'red'}>
      {value.toString()}
    </Text>
  );

  return (
    <Stack gap={2}>
      <Group gap={4} wrap='nowrap'>
        <Text style={monospaceStyle} fw={500}>
          isDirty:
        </Text>
        {renderBooleanValue(form.isDirty())}
      </Group>
      <Group gap={4} wrap='nowrap'>
        <Text style={monospaceStyle} fw={500}>
          isTouched:
        </Text>
        {renderBooleanValue(form.isTouched())}
      </Group>
      <Group gap={4} wrap='nowrap'>
        <Text style={monospaceStyle} fw={500}>
          isValid:
        </Text>
        {renderBooleanValue(form.isValid())}
      </Group>
      <Group gap={4} wrap='nowrap'>
        <Text style={monospaceStyle} fw={500}>
          hasErrors:
        </Text>
        {renderBooleanValue(Object.keys(form.errors).length > 0)}
      </Group>
      {Object.keys(form.errors).length > 0 && (
        <Stack gap={2} pl={8}>
          <Text style={monospaceStyle} fw={500}>
            Errors:
          </Text>
          {Object.entries(form.errors).map(([field, error]) => (
            <Group key={field} gap={4} wrap='nowrap'>
              <Text style={monospaceStyle} c='red'>
                {field}:
              </Text>
              <Text style={monospaceStyle} c='red'>
                {error}
              </Text>
            </Group>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

const JsonViewer = ({
  data,
  label,
  level = 0,
  isLast = true,
}: JsonViewerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isObject =
    typeof data === 'object' && data !== null && !Array.isArray(data);
  const isArray = Array.isArray(data);
  const hasChildren = isObject || isArray;

  const renderValue = (value: any) => {
    if (value === null)
      return (
        <Text style={monospaceStyle} c='dimmed'>
          null
        </Text>
      );
    if (typeof value === 'boolean')
      return (
        <Text style={monospaceStyle} c={value ? 'green' : 'red'}>
          {value.toString()}
        </Text>
      );
    if (typeof value === 'number')
      return (
        <Text style={monospaceStyle} c='blue'>
          {value}
        </Text>
      );
    if (typeof value === 'string')
      return (
        <Text style={monospaceStyle} c='teal'>
          "{value}"
        </Text>
      );
    return null;
  };

  const renderContent = () => {
    if (!hasChildren) {
      return (
        <Group gap={4} wrap='nowrap'>
          <Text style={monospaceStyle} fw={500}>
            {label}:
          </Text>
          {renderValue(data)}
        </Group>
      );
    }

    return (
      <Stack gap={2}>
        <Group gap={4} wrap='nowrap'>
          <Button
            variant='subtle'
            size='xs'
            p={0}
            onClick={() => setIsOpen(!isOpen)}
            rightSection={
              isOpen ? (
                <ChevronDownIcon size={12} />
              ) : (
                <ChevronRightIcon size={12} />
              )
            }
          >
            <Text style={monospaceStyle} fw={500}>
              {label}
            </Text>
          </Button>
        </Group>
        <Collapse in={isOpen}>
          <Stack gap={2}>
            {isObject &&
              Object.entries(data).map(([key, value], index, array) => (
                <JsonViewer
                  key={key}
                  data={value}
                  label={key}
                  level={level + 1}
                  isLast={index === array.length - 1}
                />
              ))}
            {isArray &&
              data.map((item: any, index: number) => (
                <JsonViewer
                  key={index}
                  data={item}
                  label={`[${index}]`}
                  level={level + 1}
                  isLast={index === data.length - 1}
                />
              ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };

  return (
    <div style={{ position: 'relative', paddingLeft: level * 8 }}>
      {level > 0 && (
        <>
          <div
            style={{ ...verticalLineStyle, height: isLast ? '50%' : '100%' }}
          />
          <div
            style={{
              position: 'absolute',
              left: level * 8 - 4,
              top: '50%',
              width: '4px',
              height: '1px',
              backgroundColor: '#e0e0e0',
            }}
          />
        </>
      )}
      {renderContent()}
    </div>
  );
};

export const DeveloperPanel = ({ form }: DeveloperPanelProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentValues, setCurrentValues] = useState<
    AddEditMatterFormData | undefined
  >();

  useEffect(() => {
    const handleFormValuesChange = (
      event: CustomEvent<AddEditMatterFormData>,
    ) => {
      setCurrentValues(event.detail);
    };
    // Initial value
    setCurrentValues(form.getValues());
    // Listen for form value changes
    window.addEventListener(
      'formValuesChanged',
      handleFormValuesChange as EventListener,
    );
    return () => {
      window.removeEventListener(
        'formValuesChanged',
        handleFormValuesChange as EventListener,
      );
    };
  }, [form]);

  return (
    <Paper
      shadow='md'
      p='xs'
      style={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 1000,
        maxWidth: '500px',
        maxHeight: '80vh',
        overflow: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '4px',
        }}
      >
        <Text style={monospaceStyle} fw={700}>
          Developer Panel
        </Text>
        <Button
          variant='subtle'
          size='xs'
          onClick={() => setIsOpen(!isOpen)}
          rightSection={
            isOpen ? <ChevronUpIcon size={12} /> : <ChevronDownIcon size={12} />
          }
        >
          {isOpen ? 'Collapse' : 'Expand'}
        </Button>
      </div>

      <Collapse in={isOpen}>
        <Stack gap={4}>
          <FormStateViewer form={form} />
          <Divider size='xs' />
          <JsonViewer
            data={currentValues ?? form.getValues()}
            label='Form Values'
          />
        </Stack>
      </Collapse>
    </Paper>
  );
};
