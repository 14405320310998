import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import Lottie from 'lottie-react';
import successAnimation from '../../assets/success-animation.json';

export const SaveDialog = ({
  signatureStatus,
  pctSaved,
  onCancelClick: handleCancelClick,
  onSaveACopyClick: handleSaveACopyClick,
}: {
  signatureStatus: 'unsigned' | 'signed' | 'saving' | 'saved';
  pctSaved: number;
  onCancelClick: () => void;
  onSaveACopyClick: () => void;
}) => {
  return (
    <Dialog open={['saving', 'saved'].includes(signatureStatus)}>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '360px',
          minHeight: '360px',
        }}
      >
        {signatureStatus == 'saving' ? (
          <div
            style={{
              height: '166px',
              width: '166px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgressWithLabel value={pctSaved} />
          </div>
        ) : (
          <Lottie
            animationData={successAnimation}
            style={{ height: '166px' }}
            loop={false}
          />
        )}
        <Typography
          variant='h4'
          align='center'
          sx={{ marginTop: '-24px', marginBottom: '16px' }}
        >
          {signatureStatus == 'saving' ? 'Saving' : 'Success!'}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant='body1'
            align='center'
            sx={{
              marginBottom: '24px',
              color: '#666',
              whiteSpace: 'pre-line',
            }}
          >
            {signatureStatus == 'saving'
              ? `This should only take a moment.`
              : `We've received your signed engagement letter and will reach out soon with next steps.`}
          </Typography>
        </div>
        {signatureStatus == 'saving' ? (
          <button
            style={{ fontWeight: '400' }}
            className='outline-button'
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        ) : (
          <button style={{ fontWeight: '400' }} onClick={handleSaveACopyClick} className="primary-button">
            Save a PDF copy
          </button>
        )}
      </DialogContent>
    </Dialog>
  );
};

function CircularProgressWithLabel({ value }: { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant='determinate'
        value={value}
        sx={{ color: '#79f655' }}
        size={59}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant='caption'
          component='div'
          sx={{ color: '#000' }}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}
