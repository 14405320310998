import { Suspense, lazy, useContext } from 'react';
import { AuthenticatedSessionContext } from './authenticatedSession/AuthenticatedSessionContext';
import { LoadingView } from './components/LoadingView';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthenticatedSession } from './authenticatedSession/authenticatedSession';
const NotFound = lazy(() => import('./components/NotFound/NotFound'));
const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'));
const Flywheel = lazy(() => import('./views/Matters/Flywheel/Flywheel'));
const Matters = lazy(() => import('./views/Matters/Matters'));
const Fees = lazy(() => import('./views/Fees'));
const Expenses = lazy(() => import('./views/Expenses'));
const Tasks = lazy(() => import('./views/Tasks/Tasks'));
const BizDev = lazy(() => import('./views/BizDev'));
const Invoices = lazy(() => import('./views/Invoices/Invoices'));
const OpsHR = lazy(() => import('./views/Financials/OpsHR'));
const Resources = lazy(() => import('./views/KnowledgeBase/KnowledgeBase'));
const Wellness = lazy(() => import('./views/Wellness'));
const Settings = lazy(() => import('./views/Settings/Settings'));
const MatterDashboard = lazy(
  () => import('./views/MatterDashboard/MatterDashboard'),
);
const ClaimsSpreadsheet = lazy(
  () => import('./views/ClaimsSpreadsheet/ClaimsSpreadsheet'),
);
const MattersSpreadsheet = lazy(
  () => import('./views/Matters/MattersSpreadsheet/MattersSpreadsheet'),
);
const ClioSync = lazy(() => import('./components/ClioSync/ClioSync'));

const AppRoutes = ({ authSession }: { authSession: AuthenticatedSession }) => {
  return (
    <Suspense fallback={<LoadingView />}>
      <Routes>
        <Route path='/' element={<Navigate to='/dashboard' />} />
        <Route path='/login' element={<Navigate to='/dashboard' />} />
        <Route path='/dashboard/:modal' element={<Dashboard />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/flywheel' element={<Flywheel />} />
        <Route path='/matters/:tab' element={<Matters />} />
        <Route path='/matters' element={<Matters />} />
        <Route path='/fees' element={<Fees />} />
        <Route path='/expenses' element={<Expenses />} />
        <Route path='/tasks' element={<Tasks />} />
        <Route path='/biz-dev' element={<BizDev />} />
        <Route path='/invoices' element={<Invoices />} />
        <Route path='/ops-hr' element={<OpsHR />} />
        <Route path='/resources' element={<Resources />} />
        <Route path='/wellness' element={<Wellness />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/matter/:id' element={<MatterDashboard />} />
        <Route path='/spreadsheet' element={<MattersSpreadsheet />} />
        <Route path='/claims' element={<ClaimsSpreadsheet />} />
        <Route path='/clio-sync' element={<ClioSync />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
