import { ReactNode } from "react";
import { Text, Title } from "@mantine/core";

export type TermsVersion = {
  versionNumber: string;
  effectiveDate: string;
  title: string;
  content: ReactNode;
  changelog?: string;
};

export const termsVersions: TermsVersion[] = [
  {
    versionNumber: "0.0",
    effectiveDate: "2024-1-22",
    title: "Wow, we have Terms of Service! 🤓",
    content: (
      <>
        <Text size="sm">
          Welcome to Practist! By using Practist, you agree to the following terms of service:
        </Text>
        <Title order={4} mt="sm">
          This is only a test.
        </Title>
        <Text size="sm" mt="sm">
          By clicking "Accept," you acknowledge that you have read, understood, and agree to comply
          with these Terms of Service.
        </Text>
      </>
    ),
    changelog: "Test release of our Terms of Service.",
  },
  {
    versionNumber: "1.0",
    effectiveDate: "2025-01-28",
    title: "Wow, We Have Terms of Service... 🤓",
    content: (
      <>
        <Text size="sm">
        Welcome to Practist! This document is a combined Terms of Service and Privacy Policy
        (collectively, the &quot;Agreement&quot;) between you (whether an individual or representing
        a business entity) (&quot;User,&quot; &quot;you,&quot; or &quot;your&quot;) and Practist,
        Inc. (&quot;Practist,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;). It
        governs your use of our website, web-based and mobile applications, and any related products
        or services (the &quot;Services&quot;). By creating an account or otherwise using the
        Services, you acknowledge that you have read, understood, and agree to be bound by this
        Agreement. If you do not agree, you should discontinue use of the Services.
      </Text>
      <Title order={4} mt="sm">
        1. Acceptance of the Terms
      </Title>
      <Text size="sm" mt="sm">
        <strong>1.1. Eligibility.</strong> You must be of legal age to form a binding contract and, if
        acting on behalf of an organization, have the authority to bind that organization.
      </Text>
      <Text size="sm" mt="sm">
        <strong>1.2. Agreement.</strong> By creating an account, signing up for, or otherwise using
        the Services, you signify your acceptance of this Agreement and your willingness to be bound
        by it.
      </Text>
      <Text size="sm" mt="sm">
        <strong>1.3. Updates.</strong> We may update or amend this Agreement at any time by posting a
        revised version on our website. Your continued use after any posted changes constitutes your
        acceptance of those changes.
      </Text>

      <Title order={4} mt="sm">
        2. Term
      </Title>
      <Text size="sm" mt="sm">
        <strong>2.1. Term.</strong> This Agreement will remain in effect until terminated by you or by
        Practist.
      </Text>
      <Text size="sm" mt="sm">
        <strong>2.2. Termination by You.</strong> You may discontinue use of the Services at any time
        by ceasing access and providing written notice to Practist.
      </Text>
      <Text size="sm" mt="sm">
        <strong>2.3. Termination by Practist.</strong> We may terminate or suspend access to the
        Services at any time, with or without cause or notice. Grounds for termination may include,
        but are not limited to, violation of this Agreement, suspected fraudulent or illegal
        activity, or non-payment (if applicable).
      </Text>
      <Text size="sm" mt="sm">
        <strong>2.4. Effect of Termination.</strong> Upon termination, all rights granted to you under
        this Agreement will end immediately, and you must stop using the Services.
      </Text>
      <Text size="sm" mt="sm">
        <strong>2.5. Data Portability.</strong> We will, upon your written request made within ninety
        (90) days of termination, provide you with a file of your data in a standard format. After
        ninety (90) days, we may delete or destroy your data, unless otherwise required by law.
      </Text>

      <Title order={4} mt="sm">
        3. Modifications to Services
      </Title>
      <Text size="sm" mt="sm">
        <strong>3.1. Changes.</strong> Practist reserves the right to modify, suspend, or discontinue
        any aspect of the Services at any time.
      </Text>
      <Text size="sm" mt="sm">
        <strong>3.2. Maintenance.</strong> From time to time, we may temporarily suspend the Services
        for operational reasons, such as maintenance or security updates. We will endeavor to
        provide advance notice for planned downtime but may not always do so.
      </Text>
      <Text size="sm" mt="sm">
        <strong>3.3. No Liability.</strong> You agree that Practist is not liable for any loss,
        damage, or inconvenience caused by your inability to access or use the Services during
        planned or unplanned downtime.
      </Text>

      <Title order={4} mt="sm">
        4. Description of Services
      </Title>
      <Text size="sm" mt="sm">
        Practist is a practice management software solution which may offer the following features:
      </Text>
      <Text size="sm" mt="sm">
        - <strong>Practice Management</strong>: Tools for scheduling, task management,
        document storage, time tracking, billing, and more.
      </Text>
      <Text size="sm">
        - <strong>Client Intake &amp; CRM</strong>: Tools for client intake, lead tracking,
        and communication.
      </Text>
      <Text size="sm">
        - <strong>Payment Processing</strong>: Integrated payment processing
        functionality to handle credit cards, debit cards, crypto payments, and e-check/ACH,
        facilitated by Worldpay.
      </Text>
      <Text size="sm" mt="sm">
        We may add, remove, or modify features at our discretion.
      </Text>

      <Title order={4} mt="sm">
        5. Account Setup and Security
      </Title>
      <Text size="sm" mt="sm">
        <strong>5.1. Account Information.</strong> You agree to provide accurate, current, and
        complete information when creating an account.
      </Text>
      <Text size="sm" mt="sm">
        <strong>5.2. Account Credentials.</strong> You are responsible for maintaining the
        confidentiality of your login credentials and for all activities that occur under your
        account.
      </Text>
      <Text size="sm" mt="sm">
        <strong>5.3. Unauthorized Access.</strong> You agree to immediately notify Practist of any
        unauthorized use of your account or any other breach of security.
      </Text>
      <Text size="sm" mt="sm">
        <strong>5.4. Administrator Roles.</strong> If you create an organization account, you may
        designate one or more individuals as Authorized Administrators, who will have the authority
        to manage your organization's subscription, users, and configuration settings.
      </Text>

      <Title order={4} mt="sm">
        6. User Obligations
      </Title>
      <Text size="sm" mt="sm">
        <strong>6.1. Compliance with Law.</strong> You represent and warrant that you will use the
        Services in accordance with all applicable laws and regulations.
      </Text>
      <Text size="sm" mt="sm">
        <strong>6.2. Prohibited Conduct.</strong> You agree not to (a) use the Services to submit or
        transmit any content that is unlawful, defamatory, harassing, abusive, or otherwise
        objectionable; (b) introduce viruses, worms, or other malicious code; (c) infringe on any
        third party&apos;s intellectual property rights; or (d) engage in fraudulent or illegal
        activity.
      </Text>
      <Text size="sm" mt="sm">
        <strong>6.3. Conflicts of Interest.</strong> Any attorney employed by Practist will implement
        an ethics screen in the event of any conflict of interest between your practice and their
        law practice. You acknowledge that Practist is a technology platform and does not provide
        legal advice.
      </Text>
      <Text size="sm" mt="sm">
        <strong>6.4. Consent to AI Usage.</strong> You acknowledge and agree that Practist may use
        artificial intelligence and other automated technologies to support, enhance, or provide
        certain features of the Services. You consent to Practist’s use of these technologies for
        the purpose of delivering and improving the Services, subject to our Privacy Policy.
      </Text>

      <Title order={4} mt="sm">
        7. Payment Terms and Fees
      </Title>
      <Text size="sm" mt="sm">
        <strong>7.1. Subscription Fees.</strong> Currently, Practist does not charge a subscription
        fee to use the platform. Practist reserves the right to introduce or change fees at any time
        with advance notice.
      </Text>
      <Text size="sm" mt="sm">
        <strong>7.2. Mandatory Payment Processing.</strong> You agree to collect all electronic fees
        or payments through Practist and to use our integrated payment solution, powered by Worldpay.
        You agree to onboard with Worldpay and comply with its separate terms of service.
      </Text>
      <Text size="sm" mt="sm">
        <strong>7.3. Processing Rates:</strong>
      </Text>
      <Text size="sm">
        - <strong>Credit/Debit Transactions</strong>: 2.9% + $0.30 per transaction, plus any card
        brand network fees passed through without markup.
      </Text>
      <Text size="sm">
        - <strong>Crypto Transactions</strong>: 2% per transaction.
      </Text>
      <Text size="sm">
        - <strong>E-Check/ACH</strong>: 1% + $0.30 per transaction.
      </Text>
      <Text size="sm">
        - <strong>Card Brand Network Fees</strong>: Passed to you at the end of each month with no
        markup.
      </Text>
      <Text size="sm" mt="sm">
        <strong>7.4. Changes in Fees.</strong> Practist may modify payment processing fees at any
        time, upon notice to you. Your continued use of the Services after such notice constitutes
        acceptance.
      </Text>
      <Text size="sm" mt="sm">
        <strong>7.5. Billing Disputes.</strong> If you believe a billing error has occurred, you must
        contact Practist in writing within 30 days of the date on the invoice or applicable
        statement.
      </Text>

      <Title order={4} mt="sm">
        8. User &amp; Client Data
      </Title>
      <Text size="sm" mt="sm">
        <strong>8.1. Ownership.</strong> You retain all rights, title, and interest to any data you
        upload or store (&quot;User &amp; Client Data&quot;).
      </Text>
      <Text size="sm" mt="sm">
        <strong>8.2. Data Accuracy.</strong> You are solely responsible for the accuracy, quality, and
        legality of User &amp; Client Data.
      </Text>
      <Text size="sm" mt="sm">
        <strong>8.3. License to Practist.</strong> You grant Practist a worldwide, non-exclusive,
        royalty-free license to store, process, and otherwise handle User &amp; Client Data solely
        for the purpose of providing the Services.
      </Text>
      <Text size="sm" mt="sm">
        <strong>8.4. No Monitoring.</strong> Practist does not proactively monitor the contents of
        User &amp; Client Data, but we reserve the right to remove or disable access to any data
        that may violate this Agreement or applicable law.
      </Text>

      <Title order={4} mt="sm">
        9. Confidentiality
      </Title>
      <Text size="sm" mt="sm">
        <strong>9.1. Definition.</strong> &quot;Confidential Information&quot; includes all
        information disclosed by one party to the other, in writing or orally, that is not generally
        known to the public.
      </Text>
      <Text size="sm" mt="sm">
        <strong>9.2. Protection.</strong> Each party will protect the other&apos;s Confidential
        Information with at least the same level of care it uses to protect its own confidential
        information, but in no event less than a reasonable standard of care.
      </Text>
      <Text size="sm" mt="sm">
        <strong>9.3. Exceptions.</strong> Confidential Information does not include information that
        (a) becomes publicly available without breach of this Agreement, (b) was independently
        developed, or (c) was rightfully received from a third party without an obligation of
        confidentiality.
      </Text>
      <Text size="sm" mt="sm">
        <strong>9.4. Legally Required Disclosure.</strong> If legally compelled to disclose
        Confidential Information, the receiving party will promptly notify the disclosing party
        (unless prohibited by law) and reasonably cooperate with any attempt to contest or limit
        disclosure.
      </Text>

      <Title order={4} mt="sm">
        10. Intellectual Property
      </Title>
      <Text size="sm" mt="sm">
        <strong>10.1. Practist IP.</strong> Practist (and its licensors, if applicable) owns all
        rights, title, and interest, including all related intellectual property rights, in and to
        the Services, including any modifications, enhancements, or derivative works.
      </Text>
      <Text size="sm" mt="sm">
        <strong>10.2. Feedback.</strong> If you provide feedback, suggestions, or ideas about the
        Services (&quot;Feedback&quot;), we may use such Feedback without any obligation to you, and
        you irrevocably assign all right, title, and interest in that Feedback to Practist.
      </Text>
      <Text size="sm" mt="sm">
        <strong>10.3. Trademarks.</strong> &quot;Practist&quot; and any associated logos or service
        names are trademarks of Practist. No right or license is granted to use them.
      </Text>

      <Title order={4} mt="sm">
        11. Export Compliance
      </Title>
      <Text size="sm" mt="sm">
        You agree to comply with all applicable U.S. and international export laws and regulations,
        including restrictions on destinations, end users, and end use.
      </Text>

      <Title order={4} mt="sm">
        12. Third-Party Services and Links
      </Title>
      <Text size="sm" mt="sm">
        <strong>12.1. Third-Party Services.</strong> The Services may contain links to third-party
        websites or services that are not owned or controlled by Practist. We are not responsible
        for the content, privacy policies, or practices of any third-party websites or services.
      </Text>
      <Text size="sm" mt="sm">
        <strong>12.2. No Liability.</strong> Practist assumes no liability for any loss or damage
        arising from your dealings with these third parties. Your use of third-party websites or
        services is at your own risk and subject to the terms and conditions of such third parties.
      </Text>

      <Title order={4} mt="sm">
        13. Disclaimer of Warranties
      </Title>
      <Text size="sm" mt="sm">
        <strong>13.1. Informational Templates.</strong> If Practist provides any sample documents,
        questionnaires, or other templates (collectively, &quot;Templates&quot;), such Templates are
        offered solely for informational or illustrative purposes. They do not constitute legal
        advice, and you assume all responsibility for ensuring any documents or communications you
        use or share comply with applicable laws and regulations. You should consult with qualified
        legal professionals as needed.
      </Text>
      <Text size="sm" mt="sm">
        <strong>13.2. As-Is.</strong> THE SERVICES ARE PROVIDED ON AN &quot;AS IS&quot; AND
        &quot;AS AVAILABLE&quot; BASIS.
      </Text>
      <Text size="sm" mt="sm">
        <strong>13.3. No Warranty.</strong> PRACTIST HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
        WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
      </Text>
      <Text size="sm" mt="sm">
        <strong>13.4. No Guarantee.</strong> PRACTIST DOES NOT WARRANT THAT (A) THE SERVICES WILL MEET
        YOUR REQUIREMENTS OR EXPECTATIONS, (B) THE SERVICES WILL BE TIMELY, UNINTERRUPTED, OR
        ERROR-FREE, OR (C) ANY ERRORS OR DEFECTS WILL BE CORRECTED.
      </Text>
      <Text size="sm" mt="sm">
        <strong>13.5. Use at Your Own Risk.</strong> ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED
        THROUGH THE SERVICES IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE
        FOR ANY RESULTING DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA.
      </Text>

      <Title order={4} mt="sm">
        14. Limitation of Liability
      </Title>
      <Text size="sm" mt="sm">
        <strong>14.1. Indirect Damages.</strong> IN NO EVENT SHALL PRACTIST BE LIABLE FOR ANY INDIRECT,
        INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS
        OF PROFITS, REVENUE, DATA, OR USE, INCURRED BY YOU OR ANY THIRD PARTY, WHETHER IN AN ACTION
        IN CONTRACT OR TORT, EVEN IF PRACTIST HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </Text>
      <Text size="sm" mt="sm">
        <strong>14.2. Liability Cap.</strong> PRACTIST&apos;S TOTAL LIABILITY FOR ANY CLAIM ARISING OUT
        OF OR RELATING TO THIS AGREEMENT SHALL NOT EXCEED THE AMOUNTS PAID BY YOU TO PRACTIST (IF
        ANY) IN THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO LIABILITY.
      </Text>
      <Text size="sm" mt="sm">
        <strong>14.3. Certain Jurisdictions.</strong> SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
        LIMITATION OF CERTAIN WARRANTIES OR DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
      </Text>

      <Title order={4} mt="sm">
        15. Indemnification
      </Title>
      <Text size="sm" mt="sm">
        <strong>15.1. By You.</strong> You agree to indemnify, defend, and hold harmless Practist, its
        affiliates, officers, agents, and employees, from and against any and all claims,
        liabilities, damages, losses, or expenses, including reasonable attorneys&apos; fees,
        arising out of or in any way connected with (a) your use of the Services, (b) your violation
        of this Agreement, or (c) your violation of any law or regulation.
      </Text>
      <Text size="sm" mt="sm">
        <strong>15.2. By Practist.</strong> Practist will indemnify and hold you harmless from any
        claims that the Services infringe a valid U.S. patent, copyright, or trademark, provided
        that you promptly notify Practist of such claim and allow us sole control over the defense
        or settlement.
      </Text>

      <Title order={4} mt="sm">
        16. Arbitration
      </Title>
      <Text size="sm" mt="sm">
        <strong>16.1. Dispute Resolution.</strong> Any dispute, controversy, or claim arising out of or
        relating to this Agreement or the breach thereof shall be finally resolved by binding
        arbitration administered by a reputable arbitration organization.
      </Text>
      <Text size="sm" mt="sm">
        <strong>16.2. Location and Language.</strong> The arbitration shall be conducted in English, in
        Denver, Colorado, unless otherwise required by law.
      </Text>
      <Text size="sm" mt="sm">
        <strong>16.3. Exception.</strong> Notwithstanding this arbitration clause, either party may
        seek injunctive or equitable relief in any court of competent jurisdiction to prevent actual
        or threatened infringement, misappropriation, or violation of a party&apos;s intellectual
        property rights.
      </Text>
      <Text size="sm" mt="sm">
        <strong>16.4. Exclusive Forum.</strong> For any dispute, claim, or controversy not subject to
        arbitration, the parties consent to the exclusive jurisdiction of the state or federal
        courts located in Denver, Colorado. Each party waives any objection to such venue, including
        but not limited to objections based on personal jurisdiction or forum non conveniens.
      </Text>

      <Title order={4} mt="sm">
        17. Miscellaneous Provisions
      </Title>
      <Text size="sm" mt="sm">
        <strong>17.1. Force Majeure.</strong> Neither party shall be liable for delays or inability to
        perform due to causes beyond their reasonable control, including acts of God, natural
        disasters, pandemics, terrorist acts, or governmental actions.
      </Text>
      <Text size="sm" mt="sm">
        <strong>17.2. Assignment.</strong> You may not assign or transfer this Agreement or any rights
        or obligations hereunder without the prior written consent of Practist. Practist may freely
        assign or transfer this Agreement.
      </Text>
      <Text size="sm" mt="sm">
        <strong>17.3. Severability.</strong> If any provision of this Agreement is held to be invalid
        or unenforceable, that provision will be enforced to the maximum extent permissible, and the
        remaining provisions will remain in full force and effect.
      </Text>
      <Text size="sm" mt="sm">
        <strong>17.4. No Waiver.</strong> The failure by Practist to enforce any provision of this
        Agreement shall not constitute a waiver of future enforcement of that or any other
        provision.
      </Text>
      <Text size="sm" mt="sm">
        <strong>17.5. Entire Agreement.</strong> This Agreement, including any documents or policies
        referenced herein, constitutes the entire agreement between you and Practist concerning the
        Services and supersedes all prior or contemporaneous communications.
      </Text>
      <Text size="sm" mt="sm">
        <strong>17.6. Governing Law.</strong> This Agreement shall be governed by and construed in
        accordance with the laws of the State of Colorado, without regard to conflict-of-law
        principles. Any claims not subject to arbitration under Section 16 shall be brought
        exclusively in the state or federal courts located in Denver, Colorado. The parties hereby
        consent to the personal jurisdiction of such courts for the purposes of litigating any such
        claims.
      </Text>
      <Text size="sm" mt="sm">
        <strong>17.7. Notices.</strong> We may provide notices via email to the address associated with
        your account or by posting an update within the Services. You may provide notice to Practist
        by emailing  mark@practist.io or mailing a written notice to 5335 W. 48th Ave., Suite 501, Denver, CO 80212.
      </Text>
      <Text size="sm" mt="sm">
        <strong>17.8. No Third-Party Beneficiaries.</strong> This Agreement does not confer any rights
        or remedies upon any third party other than you and Practist.
      </Text>

      <Title order={3} mt="xl">
        PRIVACY POLICY
      </Title>
      <Text size="sm" mt="sm">
        Last Updated: [Month Day, Year]
      </Text>
      <Text size="sm" mt="sm">
        This Privacy Policy describes how Practist collects, uses, processes, and discloses personal
        data from users of our Services, as well as individuals who visit our website. By using the
        Services, you agree to the collection and use of information in accordance with this Privacy
        Policy.
      </Text>

      <Title order={4} mt="sm">
        1. Scope
      </Title>
      <Text size="sm" mt="sm">
        This Privacy Policy applies to information we collect through our website, applications, and
        any other interactions you have with Practist. It does not apply to third-party websites or
        services that we do not control, even if linked to from our Services.
      </Text>

      <Title order={4} mt="sm">
        2. Information We Collect
      </Title>
      <Text size="sm" mt="sm">
        <strong>2.1. Account Information</strong>: Name, email address, firm name, and similar details
        you provide when creating or managing an account.
      </Text>
      <Text size="sm" mt="sm">
        <strong>2.2. Billing and Payment Information</strong>: Payment details (e.g., credit card
        information, bank account details) when you conduct transactions through the Services. These
        are securely transmitted to Worldpay.
      </Text>
      <Text size="sm" mt="sm">
        <strong>2.3. Usage Data</strong>: We collect data about your use of the Services, such as
        login times, pages viewed, browser type, IP address, and device information.
      </Text>
      <Text size="sm" mt="sm">
        <strong>2.4. Client Data</strong>: Data you enter or upload about your clients or their
        matters within the platform. You retain ownership of this data.
      </Text>
      <Text size="sm" mt="sm">
        <strong>2.5. Cookies and Tracking</strong>: We use cookies, pixels, and similar technologies
        to personalize and improve your experience. You can manage cookie preferences via your
        browser settings.
      </Text>
      <Text size="sm" mt="sm">
        <strong>2.6. Communications</strong>: Information you provide when you contact us with
        questions or support requests, or respond to surveys or marketing communications.
      </Text>

      <Title order={4} mt="sm">
        3. How We Use Your Information
      </Title>
      <Text size="sm" mt="sm">
        <strong>3.1. Service Provision</strong>: To set up your account, process transactions, provide
        customer support, and operate the Services.
      </Text>
      <Text size="sm" mt="sm">
        <strong>3.2. Improvement and Development</strong>: To analyze usage trends and improve or
        develop new features.
      </Text>
      <Text size="sm" mt="sm">
        <strong>3.3. Communication</strong>: To send administrative or service-related communications,
        such as billing updates, security alerts, and other notifications.
      </Text>
      <Text size="sm" mt="sm">
        <strong>3.4. Marketing</strong>: With your consent or as otherwise permitted by law, we may
        send you promotional materials. You can opt out at any time.
      </Text>
      <Text size="sm" mt="sm">
        <strong>3.5. Legal Obligations</strong>: We may process your data to comply with legal
        obligations, enforce our Agreement, or protect the rights, property, or safety of Practist,
        our users, or the public.
      </Text>

      <Title order={4} mt="sm">
        4. Sharing and Disclosure
      </Title>
      <Text size="sm" mt="sm">
        <strong>4.1. Service Providers</strong>: We may share your information with third-party
        service providers who perform services on our behalf, such as payment processing, data
        hosting, or analytics.
      </Text>
      <Text size="sm" mt="sm">
        <strong>4.2. Legal Requirements</strong>: We may disclose personal information to comply with
        a subpoena, court order, or other legal process, or to protect our rights or the rights of
        others.
      </Text>
      <Text size="sm" mt="sm">
        <strong>4.3. Business Transfers</strong>: If we are acquired by or merge with another entity,
        your information may be transferred to the new owner.
      </Text>
      <Text size="sm" mt="sm">
        <strong>4.4. Consent</strong>: We may share your information with third parties if you give us
        permission to do so.
      </Text>

      <Title order={4} mt="sm">
        5. Data Security
      </Title>
      <Text size="sm" mt="sm">
        <strong>5.1. Security Measures</strong>: We implement industry-standard security measures
        designed to protect your personal information from unauthorized access, disclosure,
        alteration, or destruction.
      </Text>
      <Text size="sm" mt="sm">
        <strong>5.2. No Guarantee</strong>: No data transmission over the Internet or method of
        electronic storage is 100% secure. We cannot guarantee absolute security.
      </Text>

      <Title order={4} mt="sm">
        6. Retention
      </Title>
      <Text size="sm" mt="sm">
        We will retain your personal information for as long as your account is active or as needed
        to provide the Services and comply with our legal obligations. You may request deletion of
        your account and associated data at any time by contacting us, subject to any legal
        requirements.
      </Text>

      <Title order={4} mt="sm">
        7. International Transfers
      </Title>
      <Text size="sm" mt="sm">
        If you are accessing the Services from outside the United States, you consent to the
        transfer of your data to the United States or other jurisdictions in which we operate.
      </Text>

      <Title order={4} mt="sm">
        8. Children&apos;s Privacy
      </Title>
      <Text size="sm" mt="sm">
        The Services are not intended for individuals under 18. We do not knowingly collect personal
        data from children. If you become aware of any data we have collected from children under
        18, please contact us.
      </Text>

      <Title order={4} mt="sm">
        9. Your Rights
      </Title>
      <Text size="sm" mt="sm">
        Depending on your jurisdiction, you may have certain rights regarding your personal
        information, including the right to access, correct, or delete it. To exercise these rights,
        please contact us at  mark@practist.io. We will process your request in accordance with
        applicable laws.
      </Text>

      <Title order={4} mt="sm">
        10. SMS Messaging
      </Title>
      <Text size="sm" mt="sm">
        If the Services include SMS or text message features, you represent and warrant that any
        recipient of your messages has consented to receive them, and you will comply with all
        applicable laws including the Telephone Consumer Protection Act (TCPA). You agree to
        indemnify and hold Practist harmless from any claims arising from your violation of SMS or
        telemarketing laws.
      </Text>

      <Title order={4} mt="sm">
        11. GDPR Compliance (EU/EEA Users)
      </Title>
      <Text size="sm" mt="sm">
        For users in the European Union (EU) or European Economic Area (EEA), Practist complies with
        the General Data Protection Regulation (GDPR). You have the right to request access to,
        correction of, or deletion of your personal data. If you have questions about our GDPR
        compliance or wish to exercise your GDPR rights, please contact us at mark@practist.io.
      </Text>

      <Title order={4} mt="sm">
        12. Changes to This Privacy Policy
      </Title>
      <Text size="sm" mt="sm">
        We may revise this Privacy Policy from time to time. If we make significant changes, we will
        notify you, for example via email or by posting a notice on our website, prior to the change
        becoming effective.
      </Text>

      <Title order={4} mt="sm">
        13. Contact Us
      </Title>
      <Text size="sm" mt="sm">
        If you have any questions or concerns about this Agreement or our privacy practices, please
        contact us at:
      </Text>
      <Text size="sm" mt="sm">
        Practist, Inc.
        <br />
        5335 W. 48th Ave., Suite 501
        Denver, CO 80212
        <br />
        mark@practist.io
      </Text>
      <Text size="sm" mt="sm">
        BY USING PRACTIST AND CLICKING ACCEPT, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO THESE TERMS
        OF SERVICE AND PRIVACY POLICY.
      </Text>
    </>
  ),
  changelog: "First substantive terms.",
},
];