import { Stack } from '@mantine/core';
import classes from './OrganizationOutline.module.css';
import { Form } from '../../types';
import {
  ContactSearchField,
  ContactSearchSelection,
} from '../../../ContactSearchField/ContactSearchField';
import { OrganizationContactForm } from './OrganizationContactForm';
import HumanContactForm from './HumanContactForm';

interface Props {
  form: Form;
  primaryContactTempId: string | null;
  autoFocus: 'companyName' | 'contactSearch' | 'none';
  onContactSearchSelection: (selection: ContactSearchSelection) => void;
  getExistingContactIds: () => string[];
  onHumanWantsPrimary: (tempId: string) => void;
  onHumanDelete: (tempId: string) => void;
  onOrganizationDelete: () => void;
}

export const OrganizationOutline = ({
  form,
  onContactSearchSelection,
  getExistingContactIds,
  primaryContactTempId,
  autoFocus,
  onHumanWantsPrimary,
  onHumanDelete,
  onOrganizationDelete,
}: Props) => {
  return (
    <Stack gap='xs' className={classes.outlineContainer}>
      <OrganizationContactForm
        form={form}
        onDelete={onOrganizationDelete}
        autoFocus={autoFocus === 'companyName'}
      />

      <div className={classes.representativesContainer}>
        {form.getValues().humanContacts.map((rep, index) => (
          <div
            key={`${rep.firstName}-${rep.lastName}-${index}`}
            className={classes.representativeRow}
          >
            <div className={classes.connectingLine} />
            <HumanContactForm
              title={`Representative ${index + 1}`}
              form={form}
              humanIndex={index}
              isPrimary={primaryContactTempId === rep.tempId}
              onWantsPrimary={() => onHumanWantsPrimary(rep.tempId)}
              onDelete={() => onHumanDelete(rep.tempId)}
            />
          </div>
        ))}
        <div key={`add-representative`} className={classes.representativeRow}>
          <div className={classes.connectingLine} />
          <ContactSearchField
            mode='human'
            onSelect={onContactSearchSelection}
            excludeContacts={getExistingContactIds()}
            autoFocus={autoFocus === 'contactSearch'}
            isDisabled={false}
          />
        </div>
      </div>
    </Stack>
  );
};
