import { useState, useEffect, useContext, useRef } from 'react';
import './AddEditMatterForm.css';
import {
  usStates,
  matterStatusSelectOptions,
  matterTypeSelectOptions,
  MatterType,
} from '../../optionSets';
import { SelectOption } from '../types';
import { AuthenticatedSessionContext } from '../../authenticatedSession/AuthenticatedSessionContext';
import {
  AddEditMatterFormData,
  FormType,
  HumanContact,
  OrganizationContact,
} from './types';
import ConflictsSearchBox from './subcomponents/ConflictsSearchBox';
import MatterTypeFields from './subcomponents/MatterTypeFields';
import { useUserSelectOptions } from '../hooks';
import { FlattenedMatter } from '../../database/aggregateTypes';
import {
  assertUnreachable,
  MissingFieldError,
  saveFormData,
} from './saveFormData/saveFormData';
import SelectField from './subcomponents/SelectField';
import { UseFormReturnType, useForm as useMantineForm } from '@mantine/form';
import {
  Alert,
  LoadingOverlay,
  Overlay,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { captureException } from '../../sentry/sentry';
import { LoadingView } from '../LoadingView';
import { useCurrentUser, useFirm } from '../../database/hooks';
import ControlledSelect from './subcomponents/ControlledSelect';
import { AuthenticatedSession } from '../../authenticatedSession/authenticatedSession';
import { UserDocument } from '../../database/baseTypes';
import AlternateContactsSection from './subcomponents/ContactsSection/ContactsSection';
import { DeveloperPanel } from './subcomponents/DeveloperPanel';
import { ChevronsUpDownIcon, CircleAlertIcon } from 'lucide-react';

interface Props {
  matter?: FlattenedMatter;
  formType: FormType;
  onBack: () => void;
  onClose: () => void;
}

export type MantineForm = UseFormReturnType<
  AddEditMatterFormData,
  (values: AddEditMatterFormData) => AddEditMatterFormData
>;

type SaveStatus = { status: 'loading' } | { status: 'error'; message: string };

const AlternateAddEditMatterForm = ({
  matter,
  formType,
  onBack,
  onClose,
}: Props) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const { currentUser } = useCurrentUser();
  const userSelectOptions = useUserSelectOptions();
  const [selectedConflict, setSelectedConflict] = useState<SelectOption | null>(
    null,
  );
  const [saveStatus, setSaveStatus] = useState<SaveStatus>();
  const [modalShake, setModalShake] = useState(false);
  const { firm } = useFirm();
  const leadSourceOptions = [
    { label: 'None', value: 'none' },
    ...(firm?.leadSources
      ?.filter((source) => !source.deletedTimestamp)
      .map((source) => ({
        label: source.name,
        value: source.id,
      })) ?? []),
  ];

  const defaultOrgContact: OrganizationContact | null = matter?.contacts
    .organizations[0]
    ? {
        companyName: matter.contacts.organizations[0].companyName,
        firestoreId: matter.contacts.organizations[0].id,
      }
    : formType === 'company'
    ? { companyName: '', firestoreId: null }
    : null;
  const defaultTempId = 'default_temp_id';

  const makeInitialFormValues = (
    matter: FlattenedMatter | undefined,
  ): AddEditMatterFormData => {
    const humanContacts: HumanContact[] = [];
    if (matter?.contacts.humans) {
      humanContacts.push(
        ...matter.contacts.humans.map((human) => {
          return {
            tempId: human.id,
            firestoreId: human.id,
            firstName: human.firstName,
            lastName: human.lastName,
            phoneNumbers: human.phoneNumbers ?? [],
            primaryPhoneNumberId: human.primaryPhoneNumberId ?? null,
            emailAddresses: human.emailAddresses ?? [],
            primaryEmailAddressId: human.primaryEmailAddressId ?? null,
          };
        }),
      );
    }
    return {
      humanContacts,
      primaryContactTempId: matter?.primaryContact?.id ?? defaultTempId,
      orgContact: defaultOrgContact,
      state: matter?.state ?? usStates[0].value,
      matterStatus: matter?.status ?? 'new',
      matterSubStatusId: matter?.subStatusId ?? null,
      responsibleAttorneyId: matter?.responsibleAttorney?.id ?? null,
      leadSourceId:
        matter?.leadSource?.id ?? leadSourceOptions[0]?.value ?? null,
      description: matter?.description ?? null,
      scope: matter?.scope ?? null,
      conflict: null,
      adverseParty: matter?.adverseParty ?? null,
      matterType: (matter?.matterType as MatterType) ?? null,
      caseAssist: matter?.caseAssist ?? null,
      memberId: matter?.memberId ?? null,
      caseValue: matter?.caseValue ?? null,
      insuranceCaseType: matter?.insuranceCaseType ?? null,
      metLifeClaimNumber: matter?.metLifeClaimNumber ?? null,
      socialSecurityNumber: matter?.socialSecurityNumber ?? null,
      caseNumber: matter?.caseNumber ?? null,
      retainer: matter?.retainer ?? null,
      responsibleAttyHourlyRate: matter?.responsibleAttyHourlyRate ?? null,
      referralOriginatorId: matter?.referralOriginator?.id ?? null,
      engagementOriginatorId: matter?.engagementOriginator?.id ?? null,
      flatFeeAmt: matter?.flatFeeAmt ?? null,
    };
  };

  const mantineForm: MantineForm = useMantineForm<AddEditMatterFormData>({
    mode: 'uncontrolled',
    initialValues: makeInitialFormValues(matter),
    onValuesChange: (values) => {
      if (currentUser?.roles.includes('developer')) {
        // Update the DeveloperPanel without keeping state in this component (which would trigger a full re-render).
        window.dispatchEvent(
          new CustomEvent('formValuesChanged', { detail: values }),
        );
      }
    },
  });

  // These are arrays of types of matters for person and company. They are hardcoded and don't change.
  const matterTypes = matterTypeSelectOptions.map((option) => option.value);

  const subStatusOptions = firm?.subStatuses
    ?.filter((subStatus) => {
      if (subStatus.deletedTimestamp) {
        return false;
      }
      return mantineForm.getValues().matterStatus === subStatus.parentStatus;
    })
    .map((subStatus) => ({
      label: subStatus.name,
      value: subStatus.id,
    }));

  useEffect(() => {
    if (modalShake) {
      const timer = setTimeout(() => {
        setModalShake(false);
      }, 500); // The duration of the shake animation

      return () => clearTimeout(timer);
    }
  }, [modalShake]);

  // This function is called when a matter type button is clicked.
  // It sets the selected matter type to the clicked type.
  const handleMatterTypeClick = (type: MatterType) => {
    switch (type) {
      case 'ARAG':
        mantineForm.setValues({
          matterType: 'ARAG',
          caseAssist: '',
          memberId: '',
          caseValue: '',
          insuranceCaseType: '',
        });
        break;
      case 'MetLife':
        mantineForm.setValues({
          matterType: 'MetLife',
          metLifeClaimNumber: '',
          socialSecurityNumber: '',
          caseValue: '',
          insuranceCaseType: '',
        });
        break;
      case 'LegalEase':
        mantineForm.setValues({
          matterType: 'LegalEase',
          caseNumber: '',
          caseValue: '',
          insuranceCaseType: '',
        });
        break;
      case 'Hourly':
        mantineForm.setValues({
          matterType: 'Hourly',
          retainer: '',
          responsibleAttyHourlyRate: '',
          referralOriginatorId: null,
          engagementOriginatorId: null,
        });
        break;
      case 'FlatFee':
        mantineForm.setValues({
          matterType: 'FlatFee',
          flatFeeAmt: '',
          referralOriginatorId: null,
          engagementOriginatorId: null,
        });
        break;
      case `Don't Know`:
        mantineForm.setValues({
          matterType: `Don't Know`,
        });
        break;
      default:
        return assertUnreachable(type);
    }
  };

  const DropdownIndicator = () => {
    return (
      <div
        style={{ paddingRight: '10px', display: 'flex', alignItems: 'center' }}
      >
        <ChevronsUpDownIcon size={14} color='#b3b3b3' strokeWidth={2} />
      </div>
    );
  };

  const IndicatorSeparator = () => null;

  const selectProps = {
    isClearable: false,
    components: {
      DropdownIndicator,
      IndicatorSeparator,
    },
    options: userSelectOptions,
    menuPortalTarget: document.body,
    styles: {
      menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
      }),
      control: (base: any) => ({
        ...base,
        borderColor: '#e0e0e0',
        '&:hover': {
          borderColor: '#e0e0e0',
        },
        fontSize: '14px',
        minHeight: '36px',
        height: '36px',
        marginTop: '1px',
      }),
      option: (base: any) => ({
        ...base,
        fontSize: '14px',
      }),
      singleValue: (base: any) => ({
        ...base,
        fontSize: '14px',
      }),
    },
  };

  if (userSelectOptions.length === 0) {
    // Wait until these load, else the attorney-related select fields will be empty.
    return <LoadingView />;
  }

  return (
    <div id='add-edit-matter-form' className={`${modalShake ? 'shake' : ''}`}>
      <Title order={1}>New Matter</Title>

      <section className='form-body'>
        <form
          style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}
          onSubmit={mantineForm.onSubmit(async (data) => {
            await submitForm(
              authSession,
              currentUser,
              matter,
              data,
              selectedConflict ?? undefined,
              setSaveStatus,
              setModalShake,
              onClose,
            );
          })}
        >
          <AlternateContactsSection form={mantineForm} />

          <section title='Matter Details' className='matter-details'>
            <Title order={2}>Matter Details</Title>
            <div className='input-row'>
              <SelectField
                fieldName='state'
                label='State'
                options={usStates}
                form={mantineForm}
              />
              <SelectField
                fieldName='matterStatus'
                label='Status'
                options={matterStatusSelectOptions}
                form={mantineForm}
              />
            </div>
            {(subStatusOptions ?? []).length > 0 && (
              <div className='input-row'>
                <p className='field'></p>
                <SelectField
                  fieldName='matterSubStatusId'
                  label='Sub Status'
                  options={subStatusOptions ?? []}
                  form={mantineForm}
                />
              </div>
            )}
            <div className='input-row'>
              <ControlledSelect
                fieldName='responsibleAttorneyId'
                label='Responsible Attorney'
                options={userSelectOptions}
                form={mantineForm}
                selectProps={selectProps}
              />
              <SelectField
                fieldName='leadSourceId'
                label='Lead Source'
                options={leadSourceOptions}
                form={mantineForm}
              />
            </div>
            <Textarea
              label='Description'
              key={mantineForm.key('description')}
              resize='vertical'
              {...mantineForm.getInputProps('description')}
            />
            <div className='input-row'>
              <TextInput
                className='field'
                label='Scope (for engagement letter)'
                key={mantineForm.key('scope')}
                {...mantineForm.getInputProps('scope')}
              />
              <ConflictsSearchBox
                onChange={(val) => setSelectedConflict(val ?? null)}
              />
            </div>
            <div className='input-row'>
              <TextInput
                className='field'
                label='Adverse Party'
                key={mantineForm.key('adverseParty')}
                {...mantineForm.getInputProps('adverseParty')}
              />
            </div>
            <section className='matter-type'>
              <Title order={3}>Select Matter Type</Title>
              <div className='matter-type-options'>
                {matterTypes.map((type) => (
                  <button
                    className={`matter-type-button ${
                      type === mantineForm.getValues().matterType
                        ? 'selected'
                        : ''
                    }`}
                    type='button'
                    key={type}
                    onClick={() => handleMatterTypeClick(type)}
                  >
                    {type}
                  </button>
                ))}
              </div>

              <MatterTypeFields
                matterType={mantineForm.getValues().matterType ?? undefined}
                attorneys={userSelectOptions}
                form={mantineForm}
              />
            </section>
            <section className='form-footer'>
              {saveStatus?.status === 'error' && (
                <Alert color='red' icon={<CircleAlertIcon />}>
                  {saveStatus.message}
                </Alert>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <button
                  className='primary-button'
                  style={{ position: 'relative' }}
                  type='submit'
                  disabled={saveStatus?.status === 'loading'}
                >
                  Submit
                  <LoadingOverlay
                    loaderProps={{ color: '#ff00bf', size: 'sm' }}
                    overlayProps={{ backgroundOpacity: 0 }}
                    visible={saveStatus?.status === 'loading'}
                  />
                </button>
                <button
                  type='button'
                  disabled={saveStatus?.status === 'loading'}
                  className='outline-button'
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </section>
          </section>
        </form>
        {saveStatus?.status === 'loading' && <Overlay color='#fff' />}
      </section>

      {currentUser?.roles.includes('developer') && (
        <DeveloperPanel form={mantineForm} />
      )}
    </div>
  );
};

const submitForm = async (
  authSession: AuthenticatedSession | undefined,
  currentUser: UserDocument | undefined,
  matter: FlattenedMatter | undefined,
  data: AddEditMatterFormData,
  selectedConflict: SelectOption | undefined,
  onStatusChange: (status: SaveStatus | undefined) => void,
  onModalShake: (shake: boolean) => void,
  onClose: () => void,
) => {
  if (!authSession) {
    console.warn(
      'Attempting to create a matter without an authenticated session',
    );
    return;
  }

  if (selectedConflict) {
    onStatusChange({
      status: 'error',
      message: 'Cannot submit form if there is a name in the Conflicts input.',
    });
    onModalShake(true);
    return;
  }

  onStatusChange({ status: 'loading' });
  try {
    await saveFormData(authSession, currentUser, matter, data);
    // After the form is submitted, clear the conflicts error and close the popup.
    onStatusChange(undefined);
    onClose();
    const event = new CustomEvent('formSubmitted');
    window.dispatchEvent(event);
  } catch (e) {
    console.error(`Save failed:`, e);
    onStatusChange({
      status: 'error',
      message: `Save failed: ${(e as Error).message}`,
    });
    if (!(e instanceof MissingFieldError)) {
      captureException(e);
    }
  }
};

export default AlternateAddEditMatterForm;
