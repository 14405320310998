import { textFieldStyles } from '../../muiStyles/textFieldStyles';

export const amountFieldStyles = {
  ...textFieldStyles,
  '& input': {
    width: '200px',
  },
  '& .MuiInputLabel-shrink': {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  '& .MuiInputBase-root': {
    '&.Mui-disabled': {
      // WARNING: These .Mui-disabled styles are specific to the 'Amount' field.
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Hide the rounded outline to remove any hint of selectability
      },
      '& .MuiOutlinedInput-input': {
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)', // Set the text color to its un-disabled state.
        textAlign: 'end', // Align right.
      },
    },
  },
};
