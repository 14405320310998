import { textFieldStyles } from './textFieldStyles';

export const autocompleteInputStyles = {
  ...textFieldStyles,
  '& .MuiAutocomplete-inputRoot': {
    '&.Mui-focused': {
      // [RP-2024/04/14] - I'm having trouble getting the box shadow to work
      // well with the shrunken label, so disabling for now.
      boxShadow: 'none', // '0 0 8px rgba(255, 0, 191, 0.3)',
    },
    // The box shadow sits inside the Autocomplete field's border so hide it.
    '& input.MuiAutocomplete-input': {
      boxShadow: 'none',
    },
  },
};
