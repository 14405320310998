import { Matter } from '../MatterAutocomplete';

type TimeEntryFormField<T> = { value: T; error?: string };
export interface TimeEntryFormState {
  userId: TimeEntryFormField<string | undefined>;
  matter: TimeEntryFormField<Matter | undefined>;
  date: TimeEntryFormField<string>;
  hours: TimeEntryFormField<number | null>;
  rate: TimeEntryFormField<number | null>;
  amount: number;
  description: TimeEntryFormField<string | null>;
}

export const defaultState = (
  userId: string | undefined,
  matterId: string | undefined,
  date: string,
  initialHours?: number
): TimeEntryFormState => ({
  userId: { value: userId },
  matter: { value: matterId ? { 
    key: matterId,
    label: '',
    matterType: '',
    responsibleAttyHourlyRate: '',
    scope: '',
    id: matterId
  } : undefined },
  date: { value: date },
  hours: { value: initialHours ?? null },
  rate: { value: null },
  description: { value: '' },
  amount: 0,
});

export interface TimeEntryFormAction {
  type:
    | 'SET_DATE_VAL'
    | 'SET_DESCRIPTION_VAL'
    | 'SET_HOURS_VAL'
    | 'SET_MATTER_VAL'
    | 'SET_RATE_VAL'
    | 'SET_USER_VAL'
    | 'SET_DATE_ERROR'
    | 'SET_DESCRIPTION_ERROR'
    | 'SET_HOURS_ERROR'
    | 'SET_MATTER_ERROR'
    | 'SET_RATE_ERROR'
    | 'SET_USER_ERROR'
    | 'CLEAR_FORM';
  payload: any;
}

export const timeEntryFormReducer = (
  state: TimeEntryFormState,
  action: TimeEntryFormAction
): TimeEntryFormState => {
  switch (action.type) {
    case 'SET_USER_VAL': {
      return { ...state, userId: { value: action.payload } };
    }
    case 'SET_MATTER_VAL': {
      const rate = defaultRateForMatter(action.payload);
      return {
        ...state,
        matter: { value: action.payload },
        rate: { value: rate },
        amount: rate * (state.hours.value ?? 0)
      };
    }
    case 'SET_DATE_VAL': {
      return { ...state, date: { value: action.payload } };
    }
    case 'SET_HOURS_VAL': {
      const hours = Math.max(Number(action.payload), 0); // In case value is negative.
      return { ...state, hours: { value: hours }, amount: hours * (state.rate.value ?? 0) };
    }
    case 'SET_RATE_VAL': {
      const rate = Math.max(Number(action.payload), 0); // In case value is negative.
      return { ...state, rate: { value: rate }, amount: rate * (state.hours.value ?? 0) };
    }
    case 'SET_DESCRIPTION_VAL': {
      return { ...state, description: { value: action.payload } };
    }
    case 'SET_DATE_ERROR': {
      return { ...state, date: { ...state.date, error: action.payload } };
    }
    case 'SET_DESCRIPTION_ERROR': {
      return { ...state, description: { ...state.description, error: action.payload } };
    }
    case 'SET_HOURS_ERROR': {
      return { ...state, hours: { ...state.hours, error: action.payload } };
    }
    case 'SET_MATTER_ERROR': {
      return { ...state, matter: { ...state.matter, error: action.payload } };
    }
    case 'SET_RATE_ERROR': {
      return { ...state, rate: { ...state.rate, error: action.payload } };
    }
    case 'SET_USER_ERROR': {
      return { ...state, userId: { ...state.userId, error: action.payload } };
    }
    case 'CLEAR_FORM': {
      return defaultState(
        state.userId.value, 
        state.matter.value?.key, 
        state.date.value, 
        state.hours.value || undefined
      );
    }
    default:
      return state;
  }
};

const defaultRateForMatter = (matter?: Matter): number => {
  if (matter?.matterType === 'Hourly') {
    return Number(matter?.responsibleAttyHourlyRate);
  } else {
    return 0;
  }
};
