import { Autocomplete, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FirmDatabase } from '../database/firmDatabase';
import { autocompleteInputStyles } from '../muiStyles/autocompleteStyles';

export interface AutocompleteOption {
  key: string;
  label: string;
}

interface UserAutocompleteProps {
  firmDb: FirmDatabase | undefined;
  selectedUserId?: string;
  error: boolean;
  helperText?: string;
  inputRef?: React.Ref<any>;
  onSelect: (userId?: string) => void;
}

const UserAutocomplete = ({
  firmDb,
  selectedUserId,
  error,
  helperText,
  inputRef,
  onSelect,
}: UserAutocompleteProps) => {
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  useEffect(() => {
    if (!firmDb) {
      setOptions([]);
      return;
    }

    firmDb.getUsers().then((users) => {
      setOptions(
        users.docs.map((doc) => ({
          ...doc.data(),
          key: doc.id,
          label: `${doc.data().firstName} ${doc.data().lastName}`,
        }))
      );
    });
  }, [firmDb]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option?.key == value?.key}
      autoComplete
      options={options}
      noOptionsText='No users'
      onChange={(_, newValue) => onSelect(newValue?.key)}
      value={options.find((option) => option?.key == selectedUserId) ?? null}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            sx={autocompleteInputStyles}
            label='User'
            error={error}
            helperText={helperText}
            fullWidth
            inputRef={inputRef}
            required={true}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.key}>
            <Grid container alignItems='center' rowGap='0px'>
              <Grid item>{option.label}</Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default UserAutocomplete;
