import React, { useEffect } from 'react';
import { FlattenedMatter } from '../../database/aggregateTypes';
import './AddEditMatterModal.css';
import AlternateAddEditMatterForm from './AddEditMatterForm';
interface Props {
  matter?: FlattenedMatter;
  onClose: () => void;
}

const AddEditMatterModal: React.FC<Props> = (props) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const className = (event.target as Element)?.className ?? '';
      // Wrapping className in String initializer because we're seeing an object
      // instead of a string when the back button is clicked.
      if (String(className).startsWith('modal-backdrop') === true) {
        props.onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [props.onClose]);

  return (
    <div id='add-edit-matter-modal' className='modal-backdrop'>
      <AlternateAddEditMatterForm
        matter={props.matter}
        formType={'person'}
        onBack={() => props.onClose()}
        onClose={props.onClose}
      />
    </div>
  );
};

export default AddEditMatterModal;
