import { AuthenticatedSession } from '../authenticatedSession/authenticatedSession';
import { UserData } from '../database/baseTypes';
import { FeatureFlag, FeatureFlagRule } from './types';

export const isFeatureFlagEnabled = (
  featureFlag: FeatureFlag,
  authSession: AuthenticatedSession,
  currentUser: UserData,
) => {
  return isRuleSatisfied(featureFlag.rule, authSession, currentUser);
};

const isRuleSatisfied = (
  rule: FeatureFlagRule,
  authSession: AuthenticatedSession,
  currentUser: UserData,
): boolean => {
  switch (rule.type) {
    case 'firmIdEquals':
      return rule.value === authSession.tenantId;
    case 'userHasRole':
      return currentUser.roles?.includes(rule.value) ?? false;
    case 'boolean':
      return rule.value;
    case 'and':
      return rule.rules.every((r) =>
        isRuleSatisfied(r, authSession, currentUser),
      );
    case 'or':
      return rule.rules.some((r) =>
        isRuleSatisfied(r, authSession, currentUser),
      );
    default:
      // Sanity check
      const exhaustiveCheck: never = rule;
      return exhaustiveCheck;
  }
};
