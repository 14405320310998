import { createPdf, TCreatedPdf } from 'pdfmake/build/pdfmake';
import { loadImageDataUrl } from '../../storage/images';
import { Margins, PageSize } from 'pdfmake/interfaces';

const PDF_FONTS = {
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

// pdfmake standard page sizes: https://github.com/bpampuch/pdfmake/blob/be1bf25fdc0cb6c77d40d661592dd6ee21d45dab/src/standardPageSizes.js
const LETTER_SIZE = {
  name: 'LETTER' as PageSize,
  width: 612,
  height: 792,
};

const DEFAULT_PAGE_MARGINS: Margins = [30, 30, 30, 30]; // [left, top, right, bottom]

export const composeEngagementLetterPdf = async (
  firmId: string | undefined,
  textBlocks: string[] | undefined,
  signatureDataUrl: string | undefined,
): Promise<TCreatedPdf | undefined> => {
  if (!firmId) {
    console.warn('Attempting to generate a PDF without a firm ID');
    return;
  }
  if (!textBlocks) {
    console.warn('Attempting to generate a PDF without text blocks');
    return;
  }
  if (!signatureDataUrl) {
    console.warn('Attempting to generate a PDF without a signature');
    return;
  }

  const logoInfo = await loadImageDataUrl(firmId, 'engagementLetterLogo');
  const maxLogoWidth = LETTER_SIZE.width / 2;
  const logoWidth = Math.min(logoInfo.width, maxLogoWidth);

  const pdfDoc = createPdf(
    {
      pageSize: LETTER_SIZE,
      pageMargins: DEFAULT_PAGE_MARGINS,
      content: [
        {
          image: logoInfo.dataUrl,
          width: logoWidth,
          alignment: 'center',
        },
        {
          text: 'Engagement Letter',
          style: 'title1',
        },
        ...textBlocks.map((textBlock) => {
          return {
            unbreakable: true,
            text: textBlock,
            style: 'body',
          };
        }),
        {
          image: signatureDataUrl,
          width: 400,
          alignment: 'left',
        },
      ],

      styles: {
        title1: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
          margin: [0, 20, 0, 16],
        },
        body: {
          fontSize: 12,
          bold: false,
          alignment: 'left',
          margin: [0, 0, 0, 18],
          lineHeight: 1.5,
        },
      },
      defaultStyle: {
        font: 'Roboto',
      },
    },
    undefined,
    PDF_FONTS,
  );
  return pdfDoc;
};
