import {
  TypesenseHighlight,
  TypesenseHighlightObject,
  TypesenseHit,
  TypesenseHuman,
  TypesenseOrganization,
} from '../../typesense/types';
import { highlightedSegments } from './util';
import { Group, Stack, Text } from '@mantine/core';
import { Building2Icon, UserIcon, MailIcon, PhoneIcon } from 'lucide-react';

export const ContactHit = ({
  hit,
}: {
  hit: TypesenseHit<TypesenseHuman | TypesenseOrganization>;
}) => {
  const contact = hit.document as TypesenseHuman | TypesenseOrganization;
  const isOrganization = 'companyName' in contact;
  const icon = isOrganization ? (
    <Building2Icon size={16} />
  ) : (
    <UserIcon size={16} />
  );

  const primaryText = isOrganization ? (
    highlightedField(contact, ['companyName'], hit.highlight)
  ) : (
    <>
      {highlightedField(contact, ['firstName'], hit.highlight)}{' '}
      {highlightedField(contact, ['lastName'], hit.highlight)}
    </>
  );

  const body = isOrganization ? (
    <OrganizationBody hit={hit} org={contact as TypesenseOrganization} />
  ) : (
    <HumanBody hit={hit} human={contact as TypesenseHuman} />
  );

  return (
    <Group key={hit.document.id}>
      {icon}
      <Stack gap='0.25em'>
        <Text size='sm' fw={500} lts='0.03em'>
          {primaryText}
        </Text>
        {body}
      </Stack>
    </Group>
  );
};

const HumanBody = ({
  hit,
  human,
}: {
  hit: TypesenseHit<TypesenseHuman | TypesenseOrganization>;
  human: TypesenseHuman;
}) => {
  return (
    <Group gap='xs'>
      {human.emailAddresses.map((email, index) => (
        <HighlightedFieldBadge
          key={email}
          hit={hit}
          keyPath={['emailAddresses', index.toString()]}
          icon={<MailIcon size={10} />}
        />
      ))}
      {human.phoneNumbers.map((phone, index) => (
        <HighlightedFieldBadge
          key={phone}
          hit={hit}
          keyPath={['phoneNumbers', index.toString()]}
          icon={<PhoneIcon size={10} />}
        />
      ))}
    </Group>
  );
};

const OrganizationBody = ({
  hit,
  org,
}: {
  hit: TypesenseHit<TypesenseHuman | TypesenseOrganization>;
  org: TypesenseOrganization;
}) => {
  return (
    <>
      {org.representatives.length > 0 && (
        <Group>
          {org.representatives.map((representative, index) => (
            <FieldBadge
              key={representative.id}
              value={`${representative.firstName} ${representative.lastName}`}
              icon={<UserIcon size={10} />}
            />
          ))}
        </Group>
      )}
    </>
  );
};

const FieldBadge = ({
  icon,
  value,
}: {
  key: string;
  icon: React.ReactNode;
  value: string;
}) => {
  return (
    <Group gap='0.25em'>
      {icon}
      <span style={{ fontSize: '0.8em', letterSpacing: '0.02em' }}>
        {value}
      </span>
    </Group>
  );
};

const HighlightedFieldBadge = ({
  hit,
  keyPath,
  icon,
}: {
  hit: TypesenseHit<TypesenseHuman | TypesenseOrganization>;
  keyPath: string[];
  icon: React.ReactNode;
}) => {
  return (
    <Group key={keyPath.join('.')} gap='0.25em'>
      {icon}
      <span style={{ fontSize: '0.8em', letterSpacing: '0.02em' }}>
        {highlightedField(hit.document, keyPath, hit.highlight)}
      </span>
    </Group>
  );
};

function highlightedField<T>(
  object: T,
  keyPath: any[],
  highlight: TypesenseHighlight<T> | undefined,
) {
  const value = keyPath.reduce((acc, key) => acc?.[key], object);

  if (!highlight || !value) {
    return <span key={value}>{value}</span>;
  }
  let fieldHighlight = keyPath.reduce((acc, key) => acc?.[key], highlight);

  if (
    fieldHighlight &&
    typeof fieldHighlight === 'object' &&
    'matchedTokens' in fieldHighlight
  ) {
    const highlightObject = fieldHighlight as TypesenseHighlightObject;
    return highlightedSegments(highlightObject, value).map((segment, index) =>
      segment.isHighlighted ? (
        <mark key={index}>{segment.text}</mark>
      ) : (
        <span key={index}>{segment.text}</span>
      ),
    );
  }

  return <span key={value}>{value}</span>;
}
