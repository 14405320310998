import { Matter } from '../MatterAutocomplete';

type FormField<T> = { value: T; error?: string };
export interface FormState {
  matter: FormField<Matter | undefined>;
  date: FormField<string>;
  amount: FormField<number | undefined>;
  account: FormField<string | undefined>;
  method: FormField<string | undefined>;
  type: FormField<string | undefined>;
}

export const defaultState = (matter: Matter | undefined): FormState => {
  return {
    matter: { value: matter },
    date: { value: new Date().toLocaleDateString('en-CA') },
    amount: { value: undefined },
    account: { value: undefined },
    method: { value: undefined },
    type: { value: undefined }
  };
};

export type FormAction =
  | { type: 'SET_DATE_VAL'; date: string }
  | { type: 'SET_AMOUNT_VAL'; amount: number }
  | { type: 'SET_MATTER_VAL'; matter: Matter | undefined }
  | { type: 'SET_ACCOUNT_VAL'; account: string | undefined }
  | { type: 'SET_METHOD_VAL'; method: string | undefined }
  | { type: 'SET_TYPE_VAL'; typeValue: string | undefined }
  | { type: 'SET_DATE_ERROR'; error: string }
  | { type: 'SET_AMOUNT_ERROR'; error: string }
  | { type: 'SET_MATTER_ERROR'; error: string }
  | { type: 'SET_ACCOUNT_ERROR'; error: string }
  | { type: 'SET_METHOD_ERROR'; error: string }
  | { type: 'SET_TYPE_ERROR'; error: string }
  | { type: 'CLEAR_FORM' };

export const formReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case 'SET_DATE_VAL':
      return { ...state, date: { value: action.date } };
    case 'SET_AMOUNT_VAL':
      return { ...state, amount: { value: action.amount } };
    case 'SET_MATTER_VAL':
      return { ...state, matter: { value: action.matter } };
    case 'SET_ACCOUNT_VAL':
      return { ...state, account: { value: action.account } };
    case 'SET_METHOD_VAL':
      return { ...state, method: { value: action.method } };
    case 'SET_TYPE_VAL':
      return { ...state, type: { value: action.typeValue } };
    case 'SET_DATE_ERROR':
      return { ...state, date: { ...state.date, error: action.error } };
    case 'SET_AMOUNT_ERROR':
      return { ...state, amount: { ...state.amount, error: action.error } };
    case 'SET_MATTER_ERROR':
      return { ...state, matter: { ...state.matter, error: action.error } };
    case 'SET_ACCOUNT_ERROR':
      return { ...state, account: { ...state.account, error: action.error } };
    case 'SET_METHOD_ERROR':
      return { ...state, method: { ...state.method, error: action.error } };
    case 'SET_TYPE_ERROR':
      return { ...state, type: { ...state.type, error: action.error } };
    case 'CLEAR_FORM':
      return defaultState(state.matter.value);
    default:
      return state;
  }
}; 