import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FirmDatabase } from '../database/firmDatabase';
import { autocompleteInputStyles } from '../muiStyles/autocompleteStyles';

export interface Matter {
  key: string;
  label: string;
  matterType: string;
  responsibleAttyHourlyRate: string;
  scope: string;
  id: string;
}

interface MatterAutocompleteProps {
  firmDb?: FirmDatabase;
  value?: Matter;
  error: boolean;
  helperText?: string;
  inputRef?: React.Ref<any>;
  onSelect: (matter?: Matter) => void;
  required?: boolean;
}

const MatterAutocomplete = ({
  firmDb,
  value,
  error,
  helperText,
  inputRef,
  onSelect,
  required,
}: MatterAutocompleteProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [allMatters, setAllMatters] = useState<Matter[]>([]);
  const [options, setOptions] = useState<readonly Matter[]>([]);

  useEffect(() => {
    const fetchAllMatters = async () => {
      if (!firmDb) {
        return;
      }
      const snapshot = await firmDb.getMatters([]);
      const mattersList = snapshot.docs.map((doc) => ({
        key: doc.id,
        label: doc.data().name ?? '', // Provides default value
        matterType: doc.data().matterType ?? '', // Provides default value
        responsibleAttyHourlyRate: doc.data().responsibleAttyHourlyRate ?? '', // Provides default value
        scope: doc.data().scope ?? '', // Provides default value
        id: doc.id,
      }));
      setAllMatters(mattersList);
    };

    fetchAllMatters();
  }, [firmDb]);

  useEffect(() => {
    const filterMatters = () => {
      if (searchTerm === '') {
        setOptions([]);
        return;
      }
      const filteredOptions = allMatters.filter((matter) =>
        matter.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setOptions(filteredOptions);
    };

    filterMatters();
  }, [searchTerm, allMatters]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option?.key === value?.key}
      options={options}
      noOptionsText='Start typing to find matter...'
      onInputChange={(_, newValue) => setSearchTerm(newValue)}
      onChange={(_, newValue) => onSelect(newValue ?? undefined)}
      value={value ?? null}
      disableClearable={required}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputRef={inputRef}
            sx={autocompleteInputStyles}
            label='Matter'
            fullWidth
            error={error}
            helperText={helperText}
            required={true}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.key} style={{ display: 'block' }}>
            <Grid container alignItems='flex-start'>
              <Grid item xs={12}>
                <Typography variant='body1'>
                  {option.label}
                  {option.matterType && (
                    <Typography component="span" variant='body2' color='text.secondary'>
                      {` — ${option.matterType}`}
                    </Typography>
                  )}
                </Typography>
              </Grid>
              {option.scope && (
                <Grid item xs={12}>
                  <Typography variant='body2' color='text.secondary'>
                    {truncateMiddle(option.scope, 80)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default MatterAutocomplete;

function truncateMiddle(text: string | null, maxChars: number): string {
  if (!text || text.length <= maxChars) {
    return text || '';
  }
  const availableChars = maxChars - 3; // -3 for the ellipsis
  const prefixLength = Math.ceil(availableChars / 2);
  const suffixLength = availableChars - prefixLength;

  return `${text.substring(0, prefixLength)}...${text.substring(text.length - suffixLength)}`;
}
