import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { transactionMethod } from '../../optionSets';

interface MethodAutocompleteProps {
  value?: string;
  error: boolean;
  helperText?: string;
  inputRef?: React.Ref<any>;
  onSelect: (method?: string) => void;
  sx?: any; // Add sx prop
}

const pinkFocusStyles = {
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ff00bf',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#ff00bf',
  }
};

const MethodAutocomplete = ({
  value,
  error,
  helperText,
  inputRef,
  onSelect,
  sx,
}: MethodAutocompleteProps) => {
  return (
    <Autocomplete
      options={transactionMethod}
      noOptionsText='No methods'
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      onChange={(_, newValue) => onSelect(newValue ?? undefined)}
      value={value ?? ''}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          label='Method'
          fullWidth
          error={error}
          helperText={helperText}
          required
          sx={pinkFocusStyles}
        />
      )}
    />
  );
};

export default MethodAutocomplete;
