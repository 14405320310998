// src/components/LandingPage/Footer.js
import React from 'react';
import './LandingPage.css';

const Footer = ({ toggleModal }: { toggleModal: () => void }) => {
  return (
    <div className='footer'>
      <div className='footer-columns'>
        <div className='footer-column'>
          <h2>
            Law firm software
            <br />
            to increase efficiency.
          </h2>
          <p>
            Practist's workflows help law firms move more quickly, more easily,
            and with fewer errors. We want you to have the luxury of spending
            your time on the law, not on your software.
          </p>
        </div>
        <div className='footer-column'>
          <h2>
            Law firm software
            <br />
            to increase profit.
          </h2>
          <p>
            With greater efficiency comes greater profitability. With the
            automations of Practist, you can reduce staff time and
            administrative costs – improving your firm’s bottom line.
          </p>
        </div>
        <div className='footer-column'>
          <h2>
            Law firm software
            <br />
            for happier lawyers.
          </h2>
          <p>
            The only practice management software with wellness features built
            in, and the friendliest interface. Practist understands that you are
            not just a lawyer, you are a person.
          </p>
        </div>
        <div className='footer-column'>
          <h2>
            Law firm software
            <br />
            for access to justice.
          </h2>
          <p>
            When law firms’ costs go down and their efficiency goes up, legal
            services become more accessible. Practist prioritizes efficiency
            because Practist is built for access to justice.
          </p>
        </div>
      </div>
      <h2 className='footer-heading' style={{ marginBottom: '2rem' }}>
        Finally, law firm software you love using.
      </h2>

      <button className='primary-button' onClick={toggleModal}>
        Join Waitlist
      </button>

      <a
        className='privacy-policy'
        href='https://practist.io/privacy'
        target='_blank'
      >
        Privacy Policy
      </a>
      <p style={{ marginTop: '1rem' }}>Copyright 2024 Practist, Inc.</p>
    </div>
  );
};

export default Footer;
