import { Title, Stack } from '@mantine/core';
import { Form, HumanContact, OrganizationContact } from '../../types';
import { v4 as uuidv4 } from 'uuid';
import {
  ContactSearchField,
  ContactSearchSelection,
} from '../../../ContactSearchField/ContactSearchField';
import classes from './ContactsSection.module.css';
import { useAuthenticatedSession } from '../../../../authenticatedSession/AuthenticatedSessionContext';
import { FirmDatabase } from '../../../../database/firmDatabase';
import { TypesenseHuman } from '../../../../typesense/types';
import { OrganizationOutline } from './OrganizationOutline';
import HumanContactForm from './HumanContactForm';

interface Props {
  form: Form;
}

const AlternateContactsSection = ({ form }: Props) => {
  const authSession = useAuthenticatedSession();

  // Get IDs of already added contacts to exclude from search results
  const getExistingContactIds = (): string[] => {
    const existingIds: string[] = [];
    const allContacts: (HumanContact | OrganizationContact)[] = [
      ...form.getValues().humanContacts,
    ];
    const organizationContact = form.getValues().orgContact;
    if (organizationContact) {
      allContacts.push(organizationContact);
    }
    allContacts.forEach((contact) => {
      // Add firestoreId if it exists (for existing humans/organizations)
      if (contact.firestoreId) {
        existingIds.push(contact.firestoreId);
      }
    });
    return existingIds;
  };

  const handleContactSearchSelection = async (
    selection: ContactSearchSelection,
  ) => {
    if (!authSession) {
      return;
    }

    if (selection.type === 'existingHuman') {
      const human = await convertHumanSearchResultToHumanContact(
        authSession.db,
        selection.value,
      );
      if (form.getValues().humanContacts.length === 0) {
        form.setValues({
          primaryContactTempId: human.tempId,
        });
      }
      form.insertListItem('humanContacts', human);
    } else if (selection.type === 'existingOrganization') {
      const organization = selection.value;

      form.setFieldValue('orgContact', {
        firestoreId: organization.id,
        companyName: organization.companyName,
      });
    } else if (selection.type === 'newHuman') {
      const contactTempId = uuidv4();
      const primaryPhoneNumberId = uuidv4();
      const primaryEmailAddressId = uuidv4();
      const human: HumanContact = {
        tempId: contactTempId,
        firestoreId: null,
        firstName: '',
        lastName: '',
        phoneNumbers: [{ id: primaryPhoneNumberId, value: '' }],
        primaryPhoneNumberId,
        emailAddresses: [{ id: primaryEmailAddressId, value: '' }],
        primaryEmailAddressId,
      };

      if (form.getValues().humanContacts.length === 0) {
        form.setValues({
          primaryContactTempId: contactTempId,
        });
      }

      form.insertListItem('humanContacts', human);
    } else if (selection.type === 'newOrganization') {
      const organization: OrganizationContact = {
        firestoreId: null,
        companyName: selection.query,
      };

      form.setValues({ orgContact: organization });
    }
  };

  return (
    <section className={classes.contactsSection}>
      {form.getValues().orgContact ? (
        <Stack>
          <Title order={2}>Client</Title>
          <OrganizationOutline
            form={form}
            onContactSearchSelection={handleContactSearchSelection}
            getExistingContactIds={getExistingContactIds}
            primaryContactTempId={form.getValues().primaryContactTempId}
            autoFocus={
              form.getValues().orgContact?.firestoreId
                ? 'contactSearch'
                : 'companyName'
            }
            onHumanWantsPrimary={(tempId) => {
              form.setValues({
                primaryContactTempId: tempId,
              });
            }}
            onHumanDelete={(tempId) => {
              const index = form
                .getValues()
                .humanContacts.findIndex((c) => c.tempId === tempId);
              if (index >= 0) {
                form.removeListItem('humanContacts', index);
              }
            }}
            onOrganizationDelete={() => {
              form.setValues({
                orgContact: null,
              });
            }}
          />
        </Stack>
      ) : (
        <>
          <Stack>
            <Title order={2}>
              {form.getValues().humanContacts.length === 0
                ? 'Client(s)'
                : form.getValues().humanContacts.length > 1
                ? 'Clients'
                : 'Client'}
            </Title>
            <ul
              style={{
                paddingInlineStart: '0px',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              {form.getValues().humanContacts.map((contact, index) => (
                <li key={contact.tempId}>
                  <HumanContactForm
                    key={contact.tempId}
                    form={form}
                    title={`Contact ${index + 1}`}
                    humanIndex={index}
                    isPrimary={
                      form.getValues().primaryContactTempId === contact.tempId
                    }
                    onWantsPrimary={() => {
                      form.setValues({
                        primaryContactTempId: contact.tempId,
                      });
                    }}
                    onDelete={() => {
                      form.removeListItem('humanContacts', index);
                    }}
                  />
                </li>
              ))}
              <li>
                <ContactSearchField
                  mode='all'
                  onSelect={handleContactSearchSelection}
                  excludeContacts={getExistingContactIds()}
                  autoFocus={true}
                  isDisabled={false}
                />
              </li>
            </ul>
          </Stack>
        </>
      )}
    </section>
  );
};

export default AlternateContactsSection;

const convertHumanSearchResultToHumanContact = async (
  firmDb: FirmDatabase,
  human: TypesenseHuman,
): Promise<HumanContact> => {
  const humanSnapshot = await firmDb.getHuman(human.id);
  const humanData = humanSnapshot.data();
  if (!humanData) {
    console.error(`Human with ID ${human.id} not found in firestore`);
    throw new Error(`Human with ID ${human.id} not found in firestore`);
  }

  const phoneNumbers = humanData.phoneNumbers ?? [];
  const primaryPhoneNumberId = humanData.primaryPhoneNumberId ?? uuidv4();
  if (phoneNumbers.length === 0) {
    phoneNumbers.push({ id: primaryPhoneNumberId, value: '' });
  }

  const emailAddresses = humanData.emailAddresses ?? [];
  const primaryEmailAddressId = humanData.primaryEmailAddressId ?? uuidv4();
  if (emailAddresses.length === 0) {
    emailAddresses.push({ id: primaryEmailAddressId, value: '' });
  }

  return {
    tempId: uuidv4(),
    firestoreId: humanSnapshot.id,
    firstName: humanData.firstName,
    lastName: humanData.lastName,
    phoneNumbers,
    primaryPhoneNumberId,
    emailAddresses,
    primaryEmailAddressId,
  };
};
