import { TextField } from '@mui/material';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { AuthenticatedSessionContext } from '../../authenticatedSession/AuthenticatedSessionContext';
import { firebase } from '../../firebaseConfig';
import { textFieldStyles } from '../../muiStyles/textFieldStyles';
import MatterAutocomplete from '../MatterAutocomplete';
import '../Modal.css';
import UserAutocomplete from '../UserAutocomplete';
import styles from './TimeEntryModal.module.css';
import { defaultState, timeEntryFormReducer } from './reducer';
import { amountFieldStyles } from './styles';
import { flushSync } from 'react-dom';
import DateTextField from '../DateTextField';
import {
  QuickEntryModal,
  StatusMessage,
} from '../QuickEntryModal/QuickEntryModal';
import { TimeEntryData } from '../../database/baseTypes';
import { useTimeEntryAdd } from '../../database/hooks';
import { YearMonthDay } from '../../database/yearMonthDay';

interface TimeEntryModalProps {
  fixedMatterId: string | undefined;
  initialHours?: number;
  onClose: (submitted?: boolean) => void;
}

const TimeEntryModal = ({
  fixedMatterId,
  initialHours,
  onClose,
}: TimeEntryModalProps) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const [state, dispatch] = useReducer(
    timeEntryFormReducer,
    defaultState(
      authSession?.userId,
      undefined,
      new Date().toLocaleDateString('en-CA'),
      initialHours
    )
  );
  const [statusMessage, setStatusMessage] = useState<
    StatusMessage | undefined
  >();
  const startingInputRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if ((event.target as Element)?.className === 'modal-backdrop') {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  useEffect(() => {
    startingInputRef.current?.focus();
  }, []);

  const addTimeEntry = useTimeEntryAdd();

  const handleSave = async () => {
    if (!authSession) {
      return;
    }

    let hasErrors = false;
    if (state.hours.value == null) {
      hasErrors = true;
      dispatch({ type: 'SET_HOURS_ERROR', payload: 'Required' });
    }
    if (state.rate.value == null) {
      hasErrors = true;
      dispatch({ type: 'SET_RATE_ERROR', payload: 'Required ' });
    }
    if (!state.date.value) {
      hasErrors = true;
      dispatch({ type: 'SET_DATE_ERROR', payload: 'Required' });
    }
    const ymd = YearMonthDay.fromString(state.date.value!, 'yyyy-MM-dd');
    if (!ymd) {
      hasErrors = true;
      dispatch({ type: 'SET_DATE_ERROR', payload: 'Unexpected date format' });
    }

    if (!fixedMatterId && !state.matter.value) {
      hasErrors = true;
      dispatch({ type: 'SET_MATTER_ERROR', payload: 'Required' });
    }
    if (!state.userId.value) {
      hasErrors = true;
      dispatch({ type: 'SET_USER_ERROR', payload: 'Required' });
    }
    if (!state.description.value) {
      hasErrors = true;
      dispatch({ type: 'SET_DESCRIPTION_ERROR', payload: 'Required' });
    }

    if (hasErrors) {
      setStatusMessage({
        message: 'Highlighted fields are required',
        severity: 'error',
        autoHideDuration: 6000,
        onClose: () => {
          setStatusMessage(undefined);
        },
      });
      return;
    }

    const newTimeEntry = {
      earnedYmd: ymd!,
      hours: state.hours.value,
      rate: state.rate.value || 0,
      amount: state.amount || 0,
      matter: fixedMatterId ?? state.matter.value!.key,
      description: state.description.value!,
      user: state.userId.value!,
      createdTimestamp: new Date(),
    };
    const ref = await addTimeEntry({...newTimeEntry, hours: state.hours.value || 0});
    flushSync(() => {
      setStatusMessage({
        message: 'New time entry created successfully!',
        autoHideDuration: 20000,
        severity: 'success',
        onClose: () => {
          setStatusMessage(undefined);
        },
        onUndo: () => handleUndo(ref, fixedMatterId ?? state.matter.value!.key),
      });
      dispatch({ type: 'CLEAR_FORM', payload: undefined });
    });

    startingInputRef.current?.focus();
  };

  const handleUndo = async (
    refToDelete:
      | firebase.firestore.DocumentReference<TimeEntryData>
      | undefined,
    matterId: string
  ) => {
    if (!refToDelete) {
      return;
    }
    await refToDelete.delete();
    // await refToDelete.delete();
    setStatusMessage({
      message: 'Undo successful',
      autoHideDuration: 1500,
      severity: 'info',
      onClose: () => {
        setStatusMessage(undefined);
      },
    });
  };

  const formatCurrency = (val: number): string => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(val.valueOf());
  };

  return (
    <QuickEntryModal
      title='New Time Entry'
      statusMessage={statusMessage}
      onClose={() => onClose(false)}
      onSave={async () => {
        await handleSave();
        onClose(true);
      }}
    >
      <UserAutocomplete
        firmDb={authSession?.db}
        error={!!state.userId.error}
        selectedUserId={state.userId.value}
        inputRef={startingInputRef}
        onSelect={(userId) =>
          dispatch({ type: 'SET_USER_VAL', payload: userId })
        }
      />
      {!fixedMatterId && (
        <MatterAutocomplete
          firmDb={authSession?.db}
          error={!!state.matter.error}
          value={state.matter.value}
          onSelect={(matter) => {
            dispatch({ type: 'SET_MATTER_VAL', payload: matter });
          }}
        />
      )}
      <DateTextField
        value={state.date.value}
        error={!!state.date.error}
        required={true}
        onChange={(e) =>
          dispatch({ type: 'SET_DATE_VAL', payload: e.target.value })
        }
      />
      <div className={styles.rateHoursAmountRow}>
        <TextField
          sx={textFieldStyles}
          label='$ Rate'
          variant='outlined'
          type='number'
          onChange={(e) =>
            dispatch({ type: 'SET_RATE_VAL', payload: e.target.value })
          }
          value={state.rate.value ?? ''}
          error={!!state.rate.error}
        />
        <TextField
          sx={textFieldStyles}
          label='Hours'
          variant='outlined'
          type='number'
          onChange={(e) =>
            dispatch({ type: 'SET_HOURS_VAL', payload: e.target.value })
          }
          value={state.hours.value ?? ''}
          error={!!state.hours.error}
        />
        <TextField
          sx={amountFieldStyles}
          label='Amount'
          variant='outlined'
          value={formatCurrency(state.amount ?? 0)}
          disabled
        />
      </div>
      <TextField
        sx={textFieldStyles}
        label='Description'
        multiline
        rows={4}
        error={!!state.description.error}
        value={state.description.value ?? ''}
        required={true}
        onChange={(e) =>
          dispatch({ type: 'SET_DESCRIPTION_VAL', payload: e.target.value })
        }
      />
    </QuickEntryModal>
  );
};

export default TimeEntryModal;
