import { TextField } from '@mui/material';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { AuthenticatedSessionContext } from '../../authenticatedSession/AuthenticatedSessionContext';
import { TransactionData } from '../../database/baseTypes';
import { useTransactionAdd } from '../../database/hooks';
import { textFieldStyles } from '../../muiStyles/textFieldStyles';
import DateTextField from '../DateTextField';
import MatterAutocomplete from '../MatterAutocomplete';
import AccountAutocomplete from '../../views/Financials/AccountAutocomplete';
import MethodAutocomplete from '../../views/Financials/MethodAutocomplete';
import TypeAutocomplete from '../../views/Financials/TypeAutocomplete';
import {
  QuickEntryModal,
  StatusMessage,
} from '../QuickEntryModal/QuickEntryModal';
import { defaultState, formReducer } from './reducer';
import { notifications } from '@mantine/notifications';
import { Check } from 'lucide-react';

const formatCurrency = (val: number): string => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(val.valueOf());
};

const TransactionEntryModal = ({
  fixedMatterId,
  onClose,
}: {
  fixedMatterId?: string;
  onClose: () => void;
}) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const [state, dispatch] = useReducer(formReducer, defaultState(undefined));
  const [statusMessage, setStatusMessage] = useState<
    StatusMessage | undefined
  >();
  const amountInputRef = useRef<HTMLInputElement>(null);
  const addTransaction = useTransactionAdd();

  useEffect(() => {
    setTimeout(() => {
      if (amountInputRef.current) {
        amountInputRef.current.focus();
      }
    }, 0);
  }, []);

  const handleSave = async () => {
    if (!authSession) return;

    let hasErrors = false;
    if (!state.date.value) {
      hasErrors = true;
      dispatch({ type: 'SET_DATE_ERROR', error: 'Required' });
    }
    if (!fixedMatterId && !state.matter.value) {
      hasErrors = true;
      dispatch({ type: 'SET_MATTER_ERROR', error: 'Required' });
    }
    if (!state.amount.value) {
      hasErrors = true;
      dispatch({ type: 'SET_AMOUNT_ERROR', error: 'Required' });
    }
    if (!state.account.value) {
      hasErrors = true;
      dispatch({ type: 'SET_ACCOUNT_ERROR', error: 'Required' });
    }
    if (!state.method.value) {
      hasErrors = true;
      dispatch({ type: 'SET_METHOD_ERROR', error: 'Required' });
    }
    if (!state.type.value) {
      hasErrors = true;
      dispatch({ type: 'SET_TYPE_ERROR', error: 'Required' });
    }

    if (hasErrors) {
      notifications.show({
        title: 'Error',
        message: 'All fields are required',
        color: 'red.4',
        autoClose: 3000,
      });
      return;
    }

    const newTransaction: TransactionData = {
      account: state.account.value!,
      amount: state.amount.value || 0,
      matter: fixedMatterId ?? state.matter.value!.key,
      createdTimestamp: new Date(state.date.value),
      completedTimestamp: new Date(state.date.value),
      financialAccountId: state.account.value!,
      method: state.method.value!,
      type: state.type.value!,
      source: 'directInput',
    };

    try {
      await addTransaction(newTransaction);

      notifications.show({
        title: 'Transaction Created ✨',
        message: `New transaction for $${state.amount.value} created successfully`,
        color: 'lime.4',
        autoClose: 3000,
      });

      onClose();
    } catch (error) {
      console.error('Error creating transaction:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to create transaction',
        color: 'red.4',
        autoClose: 3000,
      });
    }
  };

  return (
    <QuickEntryModal
      title='New Transaction'
      statusMessage={statusMessage}
      onClose={onClose}
      onSave={handleSave}
    >
      <TextField
        sx={textFieldStyles}
        label='$ Amount'
        variant='outlined'
        type='number'
        fullWidth
        value={state.amount.value || ''}
        onChange={(e) => {
          const value = e.target.value;
          if (value === '' || !isNaN(parseFloat(value))) {
            dispatch({
              type: 'SET_AMOUNT_VAL',
              amount: parseFloat(value) || 0,
            });
          }
        }}
        onBlur={(e) => {
          if (state.amount.value) {
            dispatch({
              type: 'SET_AMOUNT_VAL',
              amount: Math.round(state.amount.value * 100) / 100,
            });
          }
        }}
        error={!!state.amount.error}
        inputRef={amountInputRef}
        required
        autoFocus
        placeholder='0.00'
        inputProps={{
          step: '0.01',
          min: '0',
        }}
      />
      {!fixedMatterId && (
        <MatterAutocomplete
          firmDb={authSession?.db}
          error={!!state.matter.error}
          value={state.matter.value}
          onSelect={(matter) => dispatch({ type: 'SET_MATTER_VAL', matter })}
        />
      )}
      <DateTextField
        value={state.date.value}
        error={!!state.date.error}
        required={true}
        onChange={(e) =>
          dispatch({ type: 'SET_DATE_VAL', date: e.target.value })
        }
      />
      <AccountAutocomplete
        firmDb={authSession?.db}
        error={!!state.account.error}
        value={state.account.value}
        onSelect={(account) => dispatch({ type: 'SET_ACCOUNT_VAL', account })}
      />
      <MethodAutocomplete
        error={!!state.method.error}
        value={state.method.value}
        onSelect={(method) => dispatch({ type: 'SET_METHOD_VAL', method })}
      />
      <TypeAutocomplete
        error={!!state.type.error}
        value={state.type.value}
        onSelect={(typeVal) =>
          dispatch({ type: 'SET_TYPE_VAL', typeValue: typeVal })
        }
      />
    </QuickEntryModal>
  );
};

export default TransactionEntryModal;
