import React, { useState, useEffect, useRef } from "react";
import { Modal, Text, Box, Title, Tooltip } from "@mantine/core";
import { TermsVersion } from "./TermsVersions";

interface TermsModalProps {
  versionData: TermsVersion;
  onAccept: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ versionData, onAccept }) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const termsContentRef = useRef<HTMLDivElement | null>(null);

  // Scroll handler that sets whether we are at the bottom
  const handleScroll = () => {
    if (termsContentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = termsContentRef.current;
      // Provides a little buffer for floating-point differences
      if (scrollTop + clientHeight >= scrollHeight - 2) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    }
  };

  // On mount, check if content is short enough that scrolling isn't needed
  // If so, enable acceptance immediately
  useEffect(() => {
    if (termsContentRef.current) {
      const { scrollHeight, clientHeight } = termsContentRef.current;
      if (scrollHeight <= clientHeight) {
        setIsScrolledToBottom(true);
      }
    }
  }, [versionData.content]);

  return (
    <Modal
      opened={true}
      onClose={() => {}}
      title={
        <>
          <Title order={2} mb="xs">
            {versionData.title}
          </Title>
          <Title order={4}>
            Terms of Service and Privacy Policy - Version {versionData.versionNumber}
          </Title>
        </>
      }
      withCloseButton={false}
      closeOnEscape={false}
      closeOnClickOutside={false}
      centered
      size="lg"
      styles={{
        body: {
          padding: 0,
          overflow: "hidden",
        },
      }}
    >
      <div
        ref={termsContentRef}
        onScroll={handleScroll}
        style={{
          maxHeight: "60vh",
          overflowY: "auto",
          padding: "1rem",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <Box>
          {versionData.changelog && (
            <Box mb="md">
              <Text size="lg" mb="xs" fw={600}>
                What's New
              </Text>
              <Text size="sm" color="dimmed">
                {versionData.changelog}
              </Text>
            </Box>
          )}
          <Box>
            <Text size="sm" style={{ whiteSpace: "pre-line" }}>
              {versionData.content}
            </Text>
          </Box>
        </Box>
      </div>
      <Box mt="md" mb="lg" style={{ textAlign: "center" }}>
        <Tooltip
          label={!isScrolledToBottom ? "Scroll to the bottom to accept" : ""}
          position="top"
          disabled={isScrolledToBottom}
        >
          <button
            onClick={isScrolledToBottom ? onAccept : undefined}
            disabled={!isScrolledToBottom}
            className="primary-button" 
            style={{
              cursor: isScrolledToBottom ? "pointer" : "not-allowed",
              opacity: isScrolledToBottom ? 1 : 0.5,
              backgroundColor: isScrolledToBottom ? "transparent" : "#d6d6d6",
            }}
          >
            Accept
          </button>
        </Tooltip>
      </Box>
    </Modal>
  );
};

export default TermsModal;