import { NumberInput } from '@mantine/core';
import { Form } from '../types';

const CurrencyInput = ({
  form,
  label,
  name,
  id,
}: {
  form: Form;
  label: string;
  name: string;
  id?: string;
}) => {
  return (
    <NumberInput
      prefix='$'
      className='field'
      {...form.getInputProps(name)}
      id={id ?? name}
      label={label}
    />
  );
};

export default CurrencyInput;
