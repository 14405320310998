import Typesense, { Client } from 'typesense';
import { getScopedTypesenseKey } from '../cloudFunctions/typesense';
import {
  DocumentSchema,
  SearchResponse,
  SearchResponseHighlight,
  SearchResponseHit,
} from 'typesense/lib/Typesense/Documents';
import {
  MultiSearchRequestSchema,
  UnionSearchResponse,
} from 'typesense/lib/Typesense/MultiSearch';
import {
  TypesenseHighlight,
  TypesenseHit,
  TypesenseHuman,
  TypesenseOrganization,
  TypesenseSearchResponse,
  TypesenseUnionSearchResponse,
} from './types';

export class TypesenseClient {
  #client?: Client;

  constructor() {
    this.#client = undefined;
  }

  async searchHumans(props: {
    query: string;
  }): Promise<TypesenseSearchResponse<TypesenseHuman>> {
    return this.search<TypesenseHuman>({
      collection: 'humans',
      query: props.query,
      queryBy: ['firstName', 'lastName', 'emailAddresses', 'phoneNumbers'],
    });
  }

  async searchOrganizations(props: {
    query: string;
  }): Promise<TypesenseSearchResponse<TypesenseOrganization>> {
    return this.search<TypesenseOrganization>({
      collection: 'organizations',
      query: props.query,
      queryBy: ['companyName'],
    });
  }

  async searchHumansAndOrganizations(props: {
    query: string;
  }): Promise<
    TypesenseUnionSearchResponse<TypesenseHuman | TypesenseOrganization>
  > {
    const humanSearch: MultiSearchRequestSchema = {
      collection: 'humans',
      q: props.query,
      query_by: ['firstName', 'lastName', 'emailAddresses', 'phoneNumbers'],
      infix: 'always',
      per_page: 2,
    };
    const organizationSearch: MultiSearchRequestSchema = {
      collection: 'organizations',
      q: props.query,
      query_by: ['companyName'],
      infix: 'always',
      per_page: 2,
    };
    const result = await this.multiSearch<
      (TypesenseHuman | TypesenseOrganization)[]
    >({
      searches: [humanSearch, organizationSearch],
    });
    return result;
  }

  async search<T extends DocumentSchema>(
    props: {
      collection: string;
      query: string;
      queryBy: string[];
    },
    isRetry?: boolean,
  ): Promise<TypesenseSearchResponse<T>> {
    const client = await this.#getClient(isRetry);
    try {
      const result = await client
        .collections<T>(props.collection)
        .documents()
        .search({
          q: props.query,
          query_by: props.queryBy.join(','),
          infix: 'always',
        });
      return convertSearchResponse<T>(result);
    } catch (error) {
      if (error instanceof Typesense.Errors.RequestUnauthorized) {
        this.#client = undefined;
        if (isRetry) {
          throw error;
        }
        return this.search(props, true);
      }
      throw error;
    }
  }

  async multiSearch<T extends DocumentSchema[]>(
    props: {
      searches: MultiSearchRequestSchema[];
    },
    isRetry?: boolean,
  ): Promise<TypesenseUnionSearchResponse<T[number]>> {
    const client = await this.#getClient(isRetry);

    try {
      const result = await client.multiSearch.perform<T, true>(
        {
          searches: props.searches,
          union: true,
        },
        { enable_highlight_v1: false },
      );
      return convertUnionSearchResponse(result);
    } catch (error) {
      if (error instanceof Typesense.Errors.RequestUnauthorized) {
        this.#client = undefined;
        if (isRetry) {
          throw error;
        }
        return this.multiSearch(props, true);
      }
      throw error;
    }
  }

  async #getClient(forceApiKeyRefresh?: boolean) {
    if (!this.#client) {
      this.#client = await this.#createClient(forceApiKeyRefresh);
    }
    return this.#client;
  }

  async #createClient(forceApiKeyRefresh?: boolean) {
    const result = await getScopedTypesenseKey({
      forceRefresh: forceApiKeyRefresh,
    });
    if (result.status === 'success') {
      const scopedKey = result.payload.scopedKey;
      const host =
        process.env.NODE_ENV === 'development'
          ? 'localhost'
          : 'y6ighl2xe4qnu8w5p-1.a1.typesense.net';
      const port = process.env.NODE_ENV === 'development' ? 8108 : 443;
      const protocol =
        process.env.NODE_ENV === 'development' ? 'http' : 'https';
      return new Typesense.Client({
        nodes: [{ host, port, protocol }],
        apiKey: scopedKey,
      });
    }
    throw new TypesenseKeyError('Failed to generate scoped Typesense key');
  }
}

// Define specific error types for better handling
export class TypesenseKeyError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TypesenseKeyError';
  }
}

const convertSearchResponse = <T extends DocumentSchema>(
  response: SearchResponse<T>,
): TypesenseSearchResponse<T> => {
  return {
    found: response.found,
    outOf: response.out_of,
    page: response.page,
    hits: response.hits?.map((hit) => convertTypesenseHit(hit)),
  };
};

const convertUnionSearchResponse = <T extends DocumentSchema>(
  response: UnionSearchResponse<T>,
): TypesenseUnionSearchResponse<T> => {
  return {
    found: response.found,
    hits: response.hits?.map((hit) => convertTypesenseHit(hit)),
    outOf: response.out_of,
    page: response.page,
    searchCutoff: response.search_cutoff,
    searchTimeMs: response.search_time_ms,
  };
};

const convertTypesenseHit = <T extends DocumentSchema>(
  hit: SearchResponseHit<T>,
): TypesenseHit<T> => {
  return {
    // I see the collection property in the response but it isn't included in the SearchResponseHit type
    collection: (hit as any).collection,
    document: hit.document,
    highlight: convertTypesenseHighlight(hit.highlight),
    textMatch: hit.text_match,
    textMatchInfo: hit.text_match_info
      ? {
          bestFieldScore: hit.text_match_info.best_field_score,
          bestFieldWeight: hit.text_match_info.best_field_weight,
          fieldsMatched: hit.text_match_info.fields_matched,
          score: hit.text_match_info.score,
          tokensMatched: hit.text_match_info.tokens_matched,
        }
      : undefined,
  };
};

const convertTypesenseHighlight = <T>(
  highlight: SearchResponseHighlight<T>,
): TypesenseHighlight<T> => {
  // Check if this is a highlight object with matched_tokens and snippet
  if (
    highlight &&
    typeof highlight === 'object' &&
    'matched_tokens' in highlight
  ) {
    const highlightObject = highlight as unknown as {
      matched_tokens: string[];
      snippet: string;
    };
    return {
      matchedTokens: highlightObject.matched_tokens,
      snippet: highlightObject.snippet,
    } as TypesenseHighlight<T>;
  }

  // Otherwise, recursively convert nested highlight objects
  return Object.fromEntries(
    Object.entries(highlight).map(([key, value]) => [
      key,
      convertTypesenseHighlight(value),
    ]),
  ) as TypesenseHighlight<T>;
};
