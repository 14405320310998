import { AddEditMatterFormData, Form, OrganizationContact } from '../../types';
import { Building2Icon, PencilIcon, Trash2Icon } from 'lucide-react';
import {
  ActionIcon,
  Group,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import styles from './OrganizationContactForm.module.css';

export const OrganizationContactForm = ({
  form,
  onDelete,
  autoFocus,
}: {
  form: Form;
  onDelete: () => void;
  autoFocus: boolean;
}) => {
  return (
    <Paper withBorder p='md' w='100%' className={styles.humanContactCard}>
      <Stack gap='0'>
        <Header title='Company' canDelete={true} onDelete={onDelete} />
        <Group>
          <Building2Icon size={20} />
          <TextInput
            label='Name'
            autoFocus={autoFocus}
            key={form.key('orgContact.companyName')}
            {...form.getInputProps('orgContact.companyName')}
            flex={1}
          />
        </Group>
      </Stack>
    </Paper>
  );
};

const Header = ({
  title,
  canDelete,
  onDelete,
}: {
  title: string;
  canDelete: boolean;
  onDelete: () => void;
}) => {
  return (
    <Group justify='space-between'>
      <Group align='baseline' gap='sm' h='2rem'>
        <Title order={3} style={{ margin: '0', color: '#333' }}>
          {title}
        </Title>
      </Group>
      {canDelete && (
        <ActionIcon aria-label={`Delete ${title}`} onClick={onDelete}>
          <Trash2Icon size={16} />
        </ActionIcon>
      )}
    </Group>
  );
};
