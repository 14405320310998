import { firebase } from '../firebaseConfig';
import { UpdateAchStatus } from '../../functions/src/functions/worldpay/achStatus/updateAchStatus.js';
import { AddOrUpdateWorldpaySecrets } from '../../functions/src/functions/worldpay/addOrUpdateWorldpaySecrets.js';
import { GetWorldpaySecrets } from '../../functions/src/functions/worldpay/getWorldpaySecrets.js';
import { WorldpayCheckSale } from '../../functions/src/functions/worldpay/worldpayCheckSale';
import { WorldpayTransactionSetup } from '../../functions/src/functions/worldpay/worldpayTransactionSetup';
import { GetPaymentPageUnauthenticated } from '../../functions/src/functions/worldpay/getPaymentPageUnauthenticated';
import { GetPaymentPagesAuthenticated } from '../../functions/src/functions/worldpay/getPaymentPagesAuthenticated';
import { CreatePaymentPage } from '../../functions/src/functions/worldpay/createPaymentPage';

export type { UpdateAchStatus };
export type { AddOrUpdateWorldpaySecrets };
export type { GetWorldpaySecrets };
export type { WorldpayCheckSale };
export type { WorldpayTransactionSetup };
export type { GetPaymentPageUnauthenticated };
export type { GetPaymentPagesAuthenticated };
export type { CreatePaymentPage };

// =============================================================================
// Create Payment Page
// =============================================================================

export const createPaymentPage = async (
  props: CreatePaymentPage.Props,
): Promise<CreatePaymentPage.Result> => {
  const result = await firebase.functions().httpsCallable('createPaymentPage')(
    props,
  );
  return result.data as CreatePaymentPage.Result;
};

// =============================================================================
// Get Payment Pages (Authenticated)
// =============================================================================

export const getPaymentPagesAuthenticated = async (
  props: GetPaymentPagesAuthenticated.Props,
): Promise<GetPaymentPagesAuthenticated.Result> => {
  const result = await firebase
    .functions()
    .httpsCallable('getPaymentPagesAuthenticated')(props);
  return result.data as GetPaymentPagesAuthenticated.Result;
};

// =============================================================================
// Get Payment Page (Unauthenticated)
// =============================================================================

export const getPaymentPageUnauthenticated = async (
  props: GetPaymentPageUnauthenticated.Props,
): Promise<GetPaymentPageUnauthenticated.Result> => {
  const result = await firebase
    .functions()
    .httpsCallable('getPaymentPageUnauthenticated')(props);
  return result.data as GetPaymentPageUnauthenticated.Result;
};

// =============================================================================
// Worldpay Transaction Setup
// =============================================================================

export const worldpayTransactionSetup = async (
  props: WorldpayTransactionSetup.Props,
): Promise<WorldpayTransactionSetup.Result> => {
  const result = await firebase
    .functions()
    .httpsCallable('worldpayTransactionSetup')(props);
  return result.data as WorldpayTransactionSetup.Result;
};

// =============================================================================
// Worldpay Check Sale
// =============================================================================

export const worldpayCheckSale = async (
  props: WorldpayCheckSale.Props,
): Promise<WorldpayCheckSale.Result> => {
  const result = await firebase.functions().httpsCallable('worldpayCheckSale')(
    props,
  );
  return result.data as WorldpayCheckSale.Result;
};

// =============================================================================
// Get Worldpay Secrets
// =============================================================================

export const getWorldpaySecrets =
  async (): Promise<GetWorldpaySecrets.Result> => {
    const result = await firebase
      .functions()
      .httpsCallable('getWorldpaySecrets')();
    return result.data as GetWorldpaySecrets.Result;
  };

// =============================================================================
// Add or Update Worldpay Secrets
// =============================================================================

export const addOrUpdateWorldpaySecrets = async (
  props: AddOrUpdateWorldpaySecrets.Props,
): Promise<AddOrUpdateWorldpaySecrets.Result> => {
  const result = await firebase
    .functions()
    .httpsCallable('addOrUpdateWorldpaySecrets')(props);
  return result.data as AddOrUpdateWorldpaySecrets.Result;
};

// =============================================================================
// Update ACH Status
// =============================================================================

export const updateAchStatus = async (
  props: UpdateAchStatus.Props,
): Promise<UpdateAchStatus.Result> => {
  const result = await firebase.functions().httpsCallable('updateAchStatus')(
    props,
  );
  return result.data as UpdateAchStatus.Result;
};
