import { TextField } from '@mui/material';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { AuthenticatedSessionContext } from '../../authenticatedSession/AuthenticatedSessionContext';
import { ExpenseEntryData } from '../../database/baseTypes';
import { useExpenseEntryAdd } from '../../database/hooks';
import { firebase } from '../../firebaseConfig';
import { textFieldStyles } from '../../muiStyles/textFieldStyles';
import DateTextField from '../DateTextField';
import MatterAutocomplete from '../MatterAutocomplete';
import {
  QuickEntryModal,
  StatusMessage,
} from '../QuickEntryModal/QuickEntryModal';
import { defaultState, formReducer } from './reducer';
import { YearMonthDay } from '../../database/yearMonthDay';

const ExpenseEntryModal = ({
  fixedMatterId,
  onClose,
}: {
  fixedMatterId: string | undefined;
  onClose: () => void;
}) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const [state, dispatch] = useReducer(
    formReducer,
    defaultState(undefined, new Date().toLocaleDateString('en-CA'))
  );
  const [statusMessage, setStatusMessage] = useState<
    StatusMessage | undefined
  >();
  const startingInputRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if ((event.target as Element)?.className === 'modal-backdrop') {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  useEffect(() => {
    startingInputRef.current?.focus();
  }, []);

  const addExpenseEntry = useExpenseEntryAdd();

  const handleSave = async () => {
    if (!authSession) {
      return;
    }

    let hasErrors = false;
    if (!state.date.value) {
      hasErrors = true;
      dispatch({ type: 'SET_DATE_ERROR', payload: 'Required' });
    }
    const ymd = YearMonthDay.fromString(state.date.value, 'yyyy-MM-dd');
    if (!ymd) {
      hasErrors = true;
      dispatch({ type: 'SET_DATE_ERROR', payload: 'Unexpected date format' });
    }
    if (!fixedMatterId && !state.matter.value) {
      hasErrors = true;
      dispatch({ type: 'SET_MATTER_ERROR', payload: 'Required' });
    }
    if (!state.description.value) {
      hasErrors = true;
      dispatch({ type: 'SET_DESCRIPTION_ERROR', payload: 'Required' });
    }
    if (!state.amount.value) {
      hasErrors = true;
      dispatch({ type: 'SET_AMOUNT_ERROR', payload: 'Required' });
    }

    if (hasErrors) {
      setStatusMessage({
        message: 'Highlighted fields are required',
        severity: 'error',
        autoHideDuration: 6000,
        onClose: () => {
          setStatusMessage(undefined);
        },
      });
      return;
    }

    const newExpenseEntry = {
      incurredYmd: ymd!,
      amount: `${state.amount.value || 0}`,
      matter: fixedMatterId ?? state.matter.value!.key,
      description: state.description.value!,
      user: authSession.userId,
      createdTimestamp: new Date(),
    };
    const ref = await addExpenseEntry(newExpenseEntry);

    flushSync(() => {
      setStatusMessage({
        message: `New expense created successfully!`,
        autoHideDuration: 6000,
        severity: 'success',
        onClose: () => {
          setStatusMessage(undefined);
        },
        onUndo: () => handleUndo(ref),
      });

      dispatch({ type: 'CLEAR_FORM', payload: { matter: state.matter.value } });
    });

    startingInputRef.current?.focus();
  };

  const handleUndo = async (
    refToDelete:
      | firebase.firestore.DocumentReference<ExpenseEntryData>
      | undefined
  ) => {
    if (!refToDelete) {
      return;
    }
    await refToDelete.delete();
    setStatusMessage({
      message: 'Undo successful',
      autoHideDuration: 1500,
      severity: 'info',
      onClose: () => {
        setStatusMessage(undefined);
      },
    });
  };

  return (
    <QuickEntryModal
      title='New Expense Entry'
      statusMessage={statusMessage}
      onClose={onClose}
      onSave={handleSave}
    >
      {!fixedMatterId && (
        <MatterAutocomplete
          firmDb={authSession?.db}
          error={!!state.matter.error}
          value={state.matter.value}
          inputRef={startingInputRef}
          onSelect={(matter) => {
            dispatch({ type: 'SET_MATTER_VAL', payload: matter });
          }}
        />
      )}
      <DateTextField
        value={state.date.value ?? undefined}
        error={!!state.date.error}
        required={true}
        onChange={(e) =>
          dispatch({ type: 'SET_DATE_VAL', payload: e.target.value })
        }
      />
      <TextField
        sx={textFieldStyles}
        label='$ Amount'
        variant='outlined'
        type='number'
        onChange={(e) =>
          dispatch({ type: 'SET_AMOUNT_VAL', payload: e.target.value })
        }
        value={state.amount.value ?? ''}
        error={!!state.amount.error}
        required
      />
      <TextField
        sx={textFieldStyles}
        label='Description'
        multiline
        rows={4}
        error={!!state.description.error}
        value={state.description.value ?? ''}
        required={true}
        onChange={(e) =>
          dispatch({ type: 'SET_DESCRIPTION_VAL', payload: e.target.value })
        }
      />
    </QuickEntryModal>
  );
};

export default ExpenseEntryModal;
