export const textFieldStyles = {
  // Change the shrunken version of each TextField's label text from blue to gray
  '& .MuiInputLabel-formControl.MuiInputLabel-shrink': {
    color: 'rgba(0, 0, 0, 0.6)',
  },

  '& .MuiInputBase-root': {
    '& input': {
      // The default shadow depends on the standard focus attribute, but Mui uses a custom class.
      boxShadow: 'none',
    },
    '&.Mui-focused': {
      '& input': {
        // [RP-2024/04/14] - I'm having trouble getting the box shadow to work
        // well with the shrunken label, so disabling for now.
        boxShadow: 'none', // '0 0 8px rgba(255, 0, 191, 0.3)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FF00BF',
      },
      '& .MuiInputBase-inputMultiline': {
        boxShadow: 'none',
      },
    },
  },
};
