import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { termsVersions } from '../legal/TermsVersions';
import TermsModal from '../legal/TermsModal';
import { useAuthenticatedSession } from '../authenticatedSession/AuthenticatedSessionContext';

interface TermsContextProps {
  hasAcceptedLatestTerms: boolean;
}

interface TermsProviderProps {
  userId: string;
  children: ReactNode;
}

const TermsContext = createContext<TermsContextProps>({
  hasAcceptedLatestTerms: true,
});

export const TermsProvider: React.FC<TermsProviderProps> = ({
  userId,
  children,
}) => {
  const [modalData, setModalData] = useState<{
    versionData: (typeof termsVersions)[number];
    showChangelog: boolean;
  } | null>(null);

  // Use the hook at the top level of the component
  const authSession = useAuthenticatedSession();
  const firmId = authSession?.tenantId;

  useEffect(() => {
    const checkTerms = async () => {
      try {
        if (!firmId) {
          console.error('Firm ID is not available.');
          return;
        }

        const latestVersion = termsVersions[termsVersions.length - 1];
        const userRef = doc(db, 'firms', firmId, 'users', userId);

        console.log(
          'Trying to fetch document path:',
          `firms/${firmId}/users/${userId}`,
        );

        const userDoc = await getDoc(userRef);

        const userLatestVersion = userDoc.exists()
          ? userDoc.data()?.latestAcceptedVersion || null
          : null;

        if (userLatestVersion !== latestVersion.versionNumber) {
          setModalData({
            versionData: latestVersion,
            showChangelog: !!userLatestVersion,
          });
        }
      } catch (error) {
        console.error('Error checking terms:', error);
      }
    };

    checkTerms();
  }, [firmId, userId]);

  const handleAccept = async () => {
    if (modalData) {
      try {
        if (!firmId) {
          console.error('Firm ID is not available.');
          return;
        }

        const userRef = doc(db, 'firms', firmId, 'users', userId);
        await updateDoc(userRef, {
          latestAcceptedVersion: modalData.versionData.versionNumber,
        });
        setModalData(null); // Closes the modal
      } catch (error) {
        console.error('Error updating terms acceptance:', error);
      }
    }
  };

  return (
    <TermsContext.Provider value={{ hasAcceptedLatestTerms: !modalData }}>
      {children}
      {modalData && (
        <TermsModal
          versionData={modalData.versionData}
          onAccept={handleAccept}
        />
      )}
    </TermsContext.Provider>
  );
};

export const useTermsContext = () => useContext(TermsContext);
