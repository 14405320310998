import { TypesenseHighlightObject } from '../../typesense/types';

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): ((...args: Parameters<T>) => Promise<ReturnType<T>>) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: Parameters<T>): Promise<ReturnType<T>> => {
    return new Promise((resolve) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const result = func(...args);
        resolve(result);
      }, wait);
    });
  };
};

export const highlightedSegments = <T>(
  highlight: TypesenseHighlightObject,
  text: string,
) => {
  if (!highlight.matchedTokens) {
    return [{ text, isHighlighted: false }];
  }

  const segments: { text: string; isHighlighted: boolean }[] = [];
  let currentIndex = 0;

  highlight.matchedTokens.forEach((token) => {
    const regex = new RegExp(`(${token})`, 'gi');
    let match;

    while ((match = regex.exec(text)) !== null) {
      // Add non-highlighted text before the match
      if (match.index > currentIndex) {
        segments.push({
          text: text.slice(currentIndex, match.index),
          isHighlighted: false,
        });
      }

      // Add highlighted text
      segments.push({
        text: match[1],
        isHighlighted: true,
      });

      currentIndex = match.index + match[0].length;
    }
  });

  // Add remaining text
  if (currentIndex < text.length) {
    segments.push({
      text: text.slice(currentIndex),
      isHighlighted: false,
    });
  }

  return segments;
};
