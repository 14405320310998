import { FlattenedMatter } from '../../../database/aggregateTypes';
import { MatterData } from '../../../database/baseTypes';
import { FirmDatabase } from '../../../database/firmDatabase';
import { AddEditMatterFormData } from '../types';
import { addOrUpdateMatterContacts } from './addOrUpdateMatterContacts';
import { assertUnreachable } from './saveFormData';

export const updateExistingMatter = async (
  db: FirmDatabase,
  matter: FlattenedMatter,
  formData: AddEditMatterFormData,
  useAlternateComponents: boolean = false,
) => {
  if (!formData.state) {
    throw new Error('State is required');
  }

  if (!formData.matterStatus) {
    throw new Error('Matter status is required');
  }

  if (!formData.matterType) {
    throw new Error('Matter type is required');
  }

  // Update the contacts
  await addOrUpdateMatterContacts(db, matter.matterRef.id, formData);

  // Create the raw matter data
  const matterData: Partial<MatterData> = {
    matterType: formData.matterType ?? `Don't Know`,
    responsibleAttorney: formData.responsibleAttorneyId || undefined,
    state: formData.state,
    status: formData.matterStatus,
  };

  if (formData.adverseParty) {
    matterData.adverseParty = formData.adverseParty;
  }

  if (formData.description) {
    matterData.description = formData.description;
  }

  if (formData.leadSourceId) {
    matterData.leadSourceId = formData.leadSourceId;
  }

  if (formData.responsibleAttorneyId) {
    matterData.responsibleAttorney = formData.responsibleAttorneyId;
  }

  if (formData.scope) {
    matterData.scope = formData.scope;
  }

  if (formData.matterSubStatusId) {
    matterData.subStatusId = formData.matterSubStatusId;
  }

  switch (formData.matterType) {
    case 'ARAG':
      matterData.caseAssist = formData.caseAssist ?? undefined;
      matterData.memberId = formData.memberId ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'MetLife':
      matterData.metLifeClaimNumber = formData.metLifeClaimNumber ?? undefined;
      matterData.socialSecurityNumber =
        formData.socialSecurityNumber ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'LegalEase':
      matterData.caseNumber = formData.caseNumber ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'Hourly':
      matterData.retainer = formData.retainer ?? undefined;
      matterData.responsibleAttyHourlyRate =
        formData.responsibleAttyHourlyRate ?? undefined;
      matterData.referralOriginator =
        formData.referralOriginatorId ?? undefined;
      matterData.engagementOriginator =
        formData.engagementOriginatorId ?? undefined;
      break;
    case 'FlatFee':
      matterData.flatFeeAmt = formData.flatFeeAmt ?? undefined;
      matterData.referralOriginator =
        formData.referralOriginatorId ?? undefined;
      matterData.engagementOriginator =
        formData.engagementOriginatorId ?? undefined;
      break;
    case `Don't Know`:
      break;
    default:
      return assertUnreachable(formData);
  }
  await db.updateMatter(matter.id, matterData);
};
