import { Matter } from '../MatterAutocomplete';

type FormField<T> = { value: T; error?: string };
export interface FormState {
  matter: FormField<Matter | undefined>;
  date: FormField<string>;
  description: FormField<string | undefined>;
  amount: FormField<number | undefined>;
}

export const defaultState = (
  matter: Matter | undefined,
  date: string
): FormState => {
  return {
    // Specifying 'en-CA' to get the 'yyyy-MM-dd' format expected by mui's TextField.
    // The alternative options that I've found aren't pretty
    // (https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd).
    date: { value: date },
    description: { value: undefined },
    amount: { value: undefined },
    matter: { value: matter },
  };
};

export interface FormAction {
  type:
    | 'SET_DATE_VAL'
    | 'SET_DESCRIPTION_VAL'
    | 'SET_AMOUNT_VAL'
    | 'SET_MATTER_VAL'
    | 'SET_DATE_ERROR'
    | 'SET_DESCRIPTION_ERROR'
    | 'SET_MATTER_ERROR'
    | 'SET_AMOUNT_ERROR'
    | 'CLEAR_FORM';
  payload: any;
}

export const formReducer = (
  state: FormState,
  action: FormAction
): FormState => {
  switch (action.type) {
    case 'SET_DATE_VAL': {
      return { ...state, date: { value: action.payload } };
    }
    case 'SET_DESCRIPTION_VAL': {
      return { ...state, description: { value: action.payload } };
    }
    case 'SET_AMOUNT_VAL': {
      return { ...state, amount: { value: action.payload } };
    }
    case 'SET_MATTER_VAL': {
      return { ...state, matter: { value: action.payload } };
    }
    case 'SET_DATE_ERROR': {
      return { ...state, date: { ...state.date, error: action.payload } };
    }
    case 'SET_DESCRIPTION_ERROR': {
      return {
        ...state,
        description: { ...state.description, error: action.payload },
      };
    }
    case 'SET_AMOUNT_ERROR': {
      return { ...state, amount: { ...state.amount, error: action.payload } };
    }
    case 'SET_MATTER_ERROR': {
      return { ...state, matter: { ...state.matter, error: action.payload } };
    }
    case 'CLEAR_FORM': {
      return defaultState(state.matter.value, state.date.value);
    }
    default: {
      return state;
    }
  }
};
