import { FirmDatabase } from '../../../database/firmDatabase';
import firebase from 'firebase/compat/app';
import DocumentReference = firebase.firestore.DocumentReference;
import {
  MatterData,
  HumanData,
  OrganizationData,
} from '../../../database/baseTypes';
import {
  AddEditMatterFormData,
  HumanContact,
  OrganizationContact,
} from '../types';

export const addOrUpdateMatterContacts = async (
  firmDb: FirmDatabase,
  matterId: string,
  formData: AddEditMatterFormData,
) => {
  const humanResults = await addOrUpdateHumans(firmDb, formData);
  const orgRef = await addOrUpdateOrganization(
    firmDb,
    formData,
    humanResults.map((result) => result.humanRef),
  );
  await updateMatterData({
    firmDb,
    matterId,
    formData,
    humanResults,
    orgRef,
  });
};

// Private functions

const addOrUpdateHumans = async (
  db: FirmDatabase,
  formData: AddEditMatterFormData,
) => {
  const results: {
    formContact: HumanContact;
    humanRef: DocumentReference<HumanData>;
  }[] = [];
  for (const humanContact of formData.humanContacts) {
    if (humanContact.firestoreId) {
      const humanRef = await updateExistingHuman(
        db,
        humanContact,
        humanContact.firestoreId,
      );
      results.push({ formContact: humanContact, humanRef });
    } else {
      const humanRef = await addNewHuman(db, humanContact);
      results.push({ formContact: humanContact, humanRef });
    }
  }

  return results;
};

const addNewHuman = async (db: FirmDatabase, humanContact: HumanContact) => {
  return await db.addHuman({
    firstName: humanContact.firstName,
    lastName: humanContact.lastName,
    emailAddresses: humanContact.emailAddresses,
    primaryEmailAddressId: humanContact.primaryEmailAddressId ?? undefined,
    phoneNumbers: humanContact.phoneNumbers,
    primaryPhoneNumberId: humanContact.primaryPhoneNumberId ?? undefined,
  });
};

const updateExistingHuman = async (
  db: FirmDatabase,
  humanContact: HumanContact,
  humanFirestoreId: string,
) => {
  await db.updateHuman(humanFirestoreId, {
    firstName: humanContact.firstName,
    lastName: humanContact.lastName,
    emailAddresses: humanContact.emailAddresses,
    primaryEmailAddressId: humanContact.primaryEmailAddressId ?? undefined,
    phoneNumbers: humanContact.phoneNumbers,
    primaryPhoneNumberId: humanContact.primaryPhoneNumberId ?? undefined,
  });
  return db.query.human(humanFirestoreId);
};

const addOrUpdateOrganization = async (
  db: FirmDatabase,
  formData: AddEditMatterFormData,
  representatives: DocumentReference<HumanData>[],
) => {
  if (!formData.orgContact) {
    return undefined;
  }
  if (!formData.orgContact.companyName) {
    return undefined;
  }

  if (formData.orgContact.firestoreId) {
    return await updateExistingOrganization(
      db,
      formData.orgContact,
      formData.orgContact.firestoreId,
      representatives,
    );
  } else {
    return await addNewOrganization(db, formData.orgContact, representatives);
  }
};

const addNewOrganization = async (
  db: FirmDatabase,
  orgContact: OrganizationContact,
  representatives: DocumentReference<HumanData>[],
) => {
  return await db.addOrganization({
    companyName: orgContact.companyName,
    representatives,
  });
};

const updateExistingOrganization = async (
  db: FirmDatabase,
  orgContact: OrganizationContact,
  orgFirestoreId: string,
  representatives: DocumentReference<HumanData>[],
) => {
  await db.updateOrganization(orgFirestoreId, {
    companyName: orgContact.companyName,
    representatives,
  });
  return db.query.organization(orgFirestoreId);
};

const updateMatterData = async (props: {
  firmDb: FirmDatabase;
  matterId: string;
  formData: AddEditMatterFormData;
  humanResults: {
    formContact: HumanContact;
    humanRef: DocumentReference<HumanData>;
  }[];
  orgRef: DocumentReference<OrganizationData> | undefined;
}) => {
  const { firmDb, matterId, formData, humanResults, orgRef } = props;
  const humansAndOrganizations: {
    id: string;
    type: 'human' | 'organization';
  }[] = humanResults.map((result) => ({
    id: result.humanRef.id,
    type: 'human',
  }));
  if (orgRef) {
    humansAndOrganizations.push({ id: orgRef.id, type: 'organization' });
  }
  const updateData: Partial<MatterData> = { humansAndOrganizations };
  if (formData.primaryContactTempId) {
    updateData.primaryHumanContactId = humanResults.find(
      (result) => result.formContact.tempId === formData.primaryContactTempId,
    )?.humanRef.id;
  }
  if (!updateData.primaryHumanContactId) {
    updateData.primaryHumanContactId = humanResults[0]?.humanRef.id;
  }
  await firmDb.updateMatter(matterId, updateData);
};
