import React from 'react';
import { SxProps, TextField } from '@mui/material';
import { textFieldStyles } from '../muiStyles/textFieldStyles';

interface DateTextFieldProps {
  label?: string;
  value?: string;
  error: boolean;
  required: boolean;
  sx?: SxProps;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const DateTextField = ({
  label = 'Date',
  value,
  error = false,
  required = false,
  sx,
  onChange,
  onBlur,
}: DateTextFieldProps) => {
  const [localValue, setLocalValue] = React.useState<string>(value ?? '');
  return (
    <TextField
      sx={sx ?? textFieldStyles}
      label={label}
      // Force label to always sit at top of field (https://github.com/mui/material-ui/issues/8131)
      InputLabelProps={{ shrink: true }}
      type='date'
      value={localValue}
      error={error}
      onChange={(e) => {
        setLocalValue(e.target.value);
        onChange(e);
      }}
      onBlur={(e) => {
        setLocalValue(e.target.value);
        onBlur?.(e);
      }}
      required={required}
    />
  );
};

export default DateTextField;
