import { useContext, useState, useMemo, useEffect } from 'react';
import './SiteHeader.css';
import PlusButton from '../PlusButton/PlusButton';
import { AuthenticatedSessionContext } from '../../authenticatedSession/AuthenticatedSessionContext';
import { MatterSelectOption } from '../types';
import MenuIcon from '@mui/icons-material/Menu'; // <-- Import the hamburger icon
import { useMediaQuery } from '@mui/material'; // <-- Import to check screen size
import { useMatters } from '../../database/hooks';
import TimeEntryModal from '../TimeEntryModal/TimeEntryModal';
import { Timer, Pause, Square, Search, TimerReset } from 'lucide-react';
import { spotlight } from '@mantine/spotlight';

interface StopwatchState {
  isRunning: boolean;
  isStopped: boolean;
  startTime: number | null;
  elapsedTime: number;
}

const Header = ({ openMobileMenu }: { openMobileMenu: () => void }) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const [selectedOption, setSelectedOption] =
    useState<MatterSelectOption | null>(null);
  const isMobile = useMediaQuery('(max-width: 768px)'); // <-- Check if it's mobile view
  const allMatters = useMatters();
  const allMatterSelectOptions = useMemo(() => {
    if (!allMatters) {
      return [];
    }
    return allMatters.map((matter) => ({
      value: matter.id,
      label: matter.name,
      scope: matter.scope,
      matterType: matter.matterType,
    }));
  }, [allMatters]);

  const [stopwatch, setStopwatch] = useState<StopwatchState>({
    isRunning: false,
    isStopped: false,
    startTime: null,
    elapsedTime: 0,
  });
  const [showTimeEntry, setShowTimeEntry] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (stopwatch.isRunning) {
      intervalId = setInterval(() => {
        setStopwatch((prev) => ({
          ...prev,
          elapsedTime: Date.now() - (prev.startTime || Date.now()),
        }));
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [stopwatch.isRunning]);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: 24,
      borderColor: '#CCCCCC',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#FF00BF',
      },
    }),
    container: (provided: any) => ({
      ...provided,
      width: '240px',
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
  };

  const loadOptions = async (
    inputValue: string,
  ): Promise<MatterSelectOption[]> => {
    if (!allMatterSelectOptions) {
      return [];
    }
    return allMatterSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const formatOptionLabel = ({
    label,
    matterType,
    scope,
  }: MatterSelectOption) => (
    <div>
      <div>{label}</div>
      {(matterType || scope) && (
        <div style={{ fontSize: '0.8em', color: 'gray' }}>
          {truncateMiddle(
            `${matterType ? `${matterType} - ` : ''}${scope || ''}`,
            80,
          )}
        </div>
      )}
    </div>
  );

  const handleStartStop = () => {
    if (stopwatch.isRunning) {
      // When pausing, also set isStopped to true
      setStopwatch((prev) => ({
        ...prev,
        isRunning: false,
        isStopped: true,
      }));
    } else {
      // When starting/resuming, clear isStopped
      setStopwatch((prev) => ({
        ...prev,
        isRunning: true,
        isStopped: false,
        startTime: Date.now() - prev.elapsedTime,
      }));
    }
  };

  const handleStopClick = () => {
    setStopwatch((prev) => ({
      ...prev,
      isRunning: false,
      isStopped: true,
    }));
    setShowTimeEntry(true);
  };

  const handleReset = () => {
    setStopwatch({
      isRunning: false,
      isStopped: false,
      startTime: null,
      elapsedTime: 0,
    });
  };

  const formatTime = (ms: number) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    return `${hours.toString().padStart(2, '0')}:${(minutes % 60)
      .toString()
      .padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;
  };

  const convertToDecimalHours = (ms: number) => {
    const hours = ms / (1000 * 60 * 60);
    return Math.ceil(hours * 10) / 10; // Round up to nearest 0.1
  };

  return authSession ? (
    <>
      <div className='site-header'>
        {isMobile && (
          <MenuIcon
            className='hamburger-icon'
            onClick={() => {
              console.log('Hamburger menu clicked');
              openMobileMenu();
            }}
            style={{
              position: 'absolute',
              top: '20px',
              left: '20px',
              cursor: 'pointer',
            }}
          />
        )}
        <div className='stopwatch-widget'>
          <button
            className={
              stopwatch.isRunning
                ? 'isrunning-stopwatch-button'
                : 'stopwatch-button'
            }
            onClick={handleStartStop}
          >
            {stopwatch.isRunning ? <Pause size={16} /> : <Timer size={16} />}
          </button>
          <div className='stopwatch-display'>
            {formatTime(stopwatch.elapsedTime)}
            {stopwatch.isRunning && (
              <div className='site-header-hint'>Keep tab open</div>
            )}
          </div>
          {stopwatch.isRunning && (
            <button
              className='outline-stopwatch-button'
              onClick={handleStopClick}
            >
              <Square size={16} />
            </button>
          )}
          {stopwatch.isStopped && !stopwatch.isRunning && (
            <button className='outline-stopwatch-button' onClick={handleReset}>
              <TimerReset size={16} />
            </button>
          )}
        </div>
        <button className='search-button' onClick={() => spotlight.open()}>
          <Search size={16} />
          <div className='site-header-hint'>⌘K / Ctrl+K to search</div>
        </button>
        <PlusButton />
      </div>
      {showTimeEntry && (
        <TimeEntryModal
          fixedMatterId={undefined}
          initialHours={convertToDecimalHours(stopwatch.elapsedTime)}
          onClose={(submitted?: boolean) => {
            setShowTimeEntry(false);
            if (submitted) {
              setStopwatch({
                isRunning: false,
                isStopped: false,
                startTime: null,
                elapsedTime: 0,
              });
            } else {
              console.log('Cancel clicked, setting isStopped to true');
              setStopwatch((prev) => ({
                ...prev,
                isRunning: false,
                isStopped: true,
              }));
            }
          }}
        />
      )}
    </>
  ) : null;
};

export default Header;

function truncateMiddle(text: string | null, maxChars: number): string {
  if (!text || text.length <= maxChars) {
    return text || '';
  }
  const availableChars = maxChars - 3; // -3 for the ellipsis
  const prefixLength = Math.ceil(availableChars / 2);
  const suffixLength = availableChars - prefixLength;

  return `${text.substring(0, prefixLength)}...${text.substring(
    text.length - suffixLength,
  )}`;
}
