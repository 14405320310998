import { CSSProperties } from 'react';
import classes from './LandingGradientBackground.module.css';

interface LandingGradientBackgroundProps {
  children: React.ReactNode;
  primaryColor?: string;
  secondaryColor?: string;
  tertiaryColor?: string;
}

const LandingGradientBackground = ({
  children,
  primaryColor = '#ff00bf',
  secondaryColor = '#5271FF',
  tertiaryColor,
}: LandingGradientBackgroundProps) => {
  // If tertiaryColor is not provided, use primaryColor to create a loop
  const thirdColor = tertiaryColor || primaryColor;

  // Create custom styles for the gradient
  const customGradientStyle: CSSProperties = {
    '--primary-color': primaryColor,
    '--secondary-color': secondaryColor,
    '--tertiary-color': thirdColor,
  } as CSSProperties;

  return (
    <div className={classes.background} style={customGradientStyle}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default LandingGradientBackground;
