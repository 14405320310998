import { GetScopedTypesenseKey } from '../../functions/src/functions/typesense/getScopedTypesenseKey';
import { PerformInitialTypesenseSync } from '../../functions/src/functions/typesense/performInitialTypesenseSync';
import { firebase } from '../firebaseConfig';

export const getScopedTypesenseKey = async (
  props: GetScopedTypesenseKey.Props,
): Promise<GetScopedTypesenseKey.Result> => {
  const result = await firebase
    .functions()
    .httpsCallable('getScopedTypesenseKey')(props);
  return result.data as GetScopedTypesenseKey.Result;
};

export const performInitialTypesenseSync =
  async (): Promise<PerformInitialTypesenseSync.Result> => {
    const result = await firebase
      .functions()
      .httpsCallable('performInitialTypesenseSync')();
    return result.data as PerformInitialTypesenseSync.Result;
  };
