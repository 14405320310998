import { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { analytics, firebase, storage } from '../../firebaseConfig';
import SignatureCanvas from 'react-signature-canvas';
import './SignEngagementLetter.css';
import { captureException } from '@sentry/react';
import { LoadingView } from '../../components/LoadingView';
import { logEvent } from 'firebase/analytics';
import { Group } from '@mantine/core';
import { composeEngagementLetterPdf } from './composeEngagementLetterPdf';
import { SaveDialog } from './SaveDialog';

interface EngagementLetterParams extends Record<string, string | undefined> {
  letterId: string;
  matterId: string;
  formattedName: string;
  firmId: string;
}

const SignEngagementLetter = () => {
  const { letterId, matterId, formattedName, firmId } =
    useParams<EngagementLetterParams>();
  const [letterData, setLetterData] = useState<
    { logoUrl: string; text: string; textBlocks: string[] } | undefined
  >();
  const [signatureStatus, setSignatureStatus] = useState<
    'unsigned' | 'signed' | 'saving' | 'saved'
  >('unsigned');
  const abortControllerRef = useRef<AbortController | undefined>();
  const sigCanvas = useRef<SignatureCanvas | undefined>();
  const [timeStamp, setTimeStamp] = useState<string | undefined>();
  const [loading, setLoading] = useState(true); // State for loading status
  const [pdfUrl, setPdfUrl] = useState<string | undefined>();
  const [pctSaved, setPctSaved] = useState<number>(0);

  let specificClientName = 'Client'; // Default value

  // Check if formattedName is defined before trying to split it
  if (formattedName) {
    const [formattedFirstName, formattedLastName] = formattedName.split('_');
    if (formattedFirstName && formattedLastName) {
      specificClientName = `${formattedFirstName} ${formattedLastName}`;
    }
  }

  const analyticsPayload = useMemo(() => {
    return {
      signatureStatus: signatureStatus,
      letterId: letterId,
      matterId: matterId,
      formattedName: formattedName,
      firmId: firmId,
    };
  }, [signatureStatus, letterId, matterId, formattedName, firmId]);

  useEffect(() => {
    logEvent(
      analytics,
      'engagement_letter_signature_status_change',
      analyticsPayload,
    );
  }, [signatureStatus]);

  useEffect(() => {
    // Add an event to the engagement letter object so that we can tell the
    // attorney whether this page has been visited.
    const fn = firebase.functions().httpsCallable('recordSignaturePortalView');
    fn({
      firmId: firmId,
      letterId: letterId,
    });
  }, []);

  const handleClearClick = () => {
    logEvent(
      analytics,
      'engagement_letter_signature_clear_click',
      analyticsPayload,
    );

    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setSignatureStatus('unsigned');
    }
  };

  const handleSaveSignatureClick = async () => {
    logEvent(
      analytics,
      'engagement_letter_signature_save_click',
      analyticsPayload,
    );

    if (signatureStatus != 'signed') {
      return;
    }
    setSignatureStatus('saving');
    const newTimeStamp = new Date().toLocaleString();
    setTimeStamp(newTimeStamp);

    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current!.signal;
    setPctSaved(0);

    const progressAfterUpload = 90; // 90% done when letter has been uploaded.
    const progressAfterSave = 99; // 99% done when letter info is saved to firestore

    const pdf = await composeEngagementLetterPdf(
      firmId,
      letterData?.textBlocks ?? [],
      sigCanvas.current?.toDataURL('image/png') ?? '',
    );

    logEvent(analytics, 'engagement_letter_pdf_rendered', analyticsPayload);

    if (signal.aborted) {
      return;
    }

    if (!pdf) {
      console.warn('No PDF found');
      return;
    }

    const getPdfBlob = async () => {
      return new Promise<Blob>((resolve, reject) => {
        pdf.getBlob((blob) => {
          resolve(blob);
        });
      });
    };

    const blob = await getPdfBlob();

    const letterStorageRef = storage.ref(`signedLetters/${letterId}.pdf`);
    const snapshot = await letterStorageRef.put(blob);
    logEvent(
      analytics,
      'engagement_letter_pdf_saved_to_bucket',
      analyticsPayload,
    );
    if (signal.aborted) {
      return;
    }
    const downloadUrl = await snapshot.ref.getDownloadURL();
    if (signal.aborted) {
      return;
    }
    setPctSaved(progressAfterUpload);

    const saveSignatureFunction = firebase
      .functions()
      .httpsCallable('saveEngagementLetterSignatureSecondGen');
    await saveSignatureFunction({
      firmId: firmId,
      matterId: matterId,
      letterUrl: downloadUrl,
      letterId: letterId,
      timestamp: newTimeStamp,
    });
    logEvent(analytics, 'engagement_letter_saved_to_firestore', {
      ...analyticsPayload,
      downloadUrl,
      timestamp: newTimeStamp,
    });
    if (signal.aborted) {
      return;
    }
    setPctSaved(progressAfterSave);
    setPdfUrl(downloadUrl);
    setSignatureStatus('saved');
  };

  const onEnd = () => {
    if (sigCanvas.current) {
      const isCanvasBlank = sigCanvas.current.isEmpty();
      setSignatureStatus(isCanvasBlank ? 'unsigned' : 'signed');
    }
  };

  const handleSaveACopyClick = () => {
    logEvent(analytics, 'engagement_letter_save_copy_click', {
      ...analyticsPayload,
      pdfUrl,
    });
    open(pdfUrl, '_blank');
  };

  useEffect(() => {
    const getLetterFunction = firebase
      .functions()
      .httpsCallable('getEngagementLetterSecondGen');
    getLetterFunction({ firmId: firmId, letterId: letterId })
      .then((response) => {
        if (response) {
          const blocks = (response.data.text as string).split('\n\n');
          response.data.textBlocks = blocks;
          setLetterData(response.data);
        } else {
          console.error('No engagement letter found.');
          captureException(
            new Error('No engagement letter found.'),
            (scope) => {
              scope.setExtras({ ...analyticsPayload });
              return scope;
            },
          );
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
        captureException(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [firmId, letterId]);

  if (loading) {
    return <LoadingView />;
  }

  const handleCancelClick = () => {
    abortControllerRef.current?.abort();
    abortControllerRef.current = undefined;
    setPctSaved(0);
    setSignatureStatus('signed');
  };

  return (
    <>
      <div className='sign-engagement-letter-container'>
        <div className='sign-engagement-letter-content'>
          <Group justify='center'>
            <img
              src={letterData?.logoUrl ?? ''}
              alt='Law Firm Logo'
              className='sign-engagement-letter-logo'
            />
          </Group>
          <h1>Engagement Letter</h1>
          {(letterData?.textBlocks ?? []).map((block, idx) => (
            <p
              key={idx}
              style={{ whiteSpace: 'pre-line', breakBefore: 'page' }}
            >
              {block}
            </p>
          ))}
          {['unsigned', 'signed'].includes(signatureStatus) ? (
            <div>
              <div className='signature-field'>
                <SignatureCanvas
                  penColor='black'
                  backgroundColor='#fff'
                  canvasProps={{
                    className: 'signature-canvas',
                  }}
                  ref={sigCanvas as any}
                  onEnd={onEnd}
                />
              </div>
              <button className='outline-button' onClick={handleClearClick}>
                Clear Signature
              </button>
              <button
                className='primary-button'
                onClick={() => {
                  handleSaveSignatureClick();
                }}
                disabled={signatureStatus != 'signed'}
              >
                Save Signature
              </button>
            </div>
          ) : (
            <div>
              <div className='signature-field-signed'>
                <SignatureCanvas
                  backgroundColor='#fff'
                  penColor='black'
                  canvasProps={{
                    className: 'signature-canvas',
                  }}
                  ref={sigCanvas as any}
                />
              </div>
              <p>
                Signed by {specificClientName} on {timeStamp}
              </p>
            </div>
          )}
        </div>
        <SaveDialog
          signatureStatus={signatureStatus}
          pctSaved={pctSaved}
          onCancelClick={handleCancelClick}
          onSaveACopyClick={handleSaveACopyClick}
        />
      </div>
    </>
  );
};

export default SignEngagementLetter;
