import * as Sentry from '@sentry/react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { logEvent } from 'firebase/analytics';
import { onAuthStateChanged } from 'firebase/auth';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticatedSessionContext } from './authenticatedSession/AuthenticatedSessionContext';
import { AuthenticatedSession } from './authenticatedSession/authenticatedSession';
import SignEngagementLetter from './views/SignEngagementLetter/SignEngagementLetter';
import FirebaseAnalytics from './components/FirebaseAnalytics';
import LandingPage from './components/LandingPage/LandingPage';
import SidebarMenu from './components/SidebarMenu/SidebarMenu';
import Header from './components/SiteHeader/SiteHeader';
import { FirmDatabase } from './database/firmDatabase';
import { analytics, auth, db } from './firebaseConfig';
import { attemptSsoSilentMsalLogin } from './msal/msal';
import { msalConfig } from './msal/msalConfig';
import AppRoutes from './routes';
import './styles.css';
import FindALawyer from './views/FindALawyer/FindALawyer';
const PrivacyPolicy = lazy(() => import('./views/PrivacyPolicy/PrivacyPolicy'));
import { MantineProvider } from '@mantine/core';
import { theme } from './styles/mantine';
import { Notifications } from '@mantine/notifications';
import GoogleVerification from './components/GoogleVerification';
import { ErrorView } from './components/ErrorView';
import { LoadingView } from './components/LoadingView';
import { TermsProvider } from './contexts/TermsContext';
import { SpotlightSearch } from './components/SpotlightSearch/SpotlightSearch';
const PaymentPage = lazy(() => import('./views/PaymentPage/PaymentPage'));
const HostedPaymentRedirect = lazy(
  () => import('./views/PaymentPage/HostedPaymentRedirect'),
);
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';

const EmployeeAuth = lazy(() => import('./views/EmployeeAuth/EmployeeAuth'));

import { useLocalStorage } from '@mantine/hooks';

type ShadowEdge = 'top' | 'right' | 'bottom' | 'left';

function App() {
  const authSessionRef = useRef<AuthenticatedSession>();
  const [authSession, setAuthSession] = useState<
    AuthenticatedSession | undefined
  >(authSessionRef.current);
  const [authStateUnknown, setAuthStateUnknown] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const [colorScheme, setColorScheme] = useLocalStorage<'light' | 'dark'>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setAuthStateUnknown(false);

      if (user && user.tenantId && user.email) {
        const pcaConfig = msalConfig(location);
        const msalApp = new PublicClientApplication(pcaConfig);

        authSessionRef.current = {
          sessionId: uuidv4(),
          tenantId: user.tenantId,
          userId: user.uid,
          userEmail: user.email,
          db: new FirmDatabase(user.tenantId, db),
          msalApp: new PublicClientApplication(pcaConfig),
        };
        setAuthSession(authSessionRef.current);
        logAuthSessionCreated(authSessionRef.current, pcaConfig);

        Sentry.setUser({
          email: user.email,
          id: user.uid,
          ip_address: '{{auto}}',
        });

        // Attempt silent MSAL login
        attemptSsoSilentMsalLogin(msalApp, user.tenantId, user.uid, user.email);
      } else {
        authSessionRef.current = undefined;
        setAuthSession(authSessionRef.current);
        logEvent(analytics, 'auth_session_missing');
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, eventId, resetError }) => {
        // Cast the error to any to access its properties without TypeScript complaints
        const errorAny = error as any;

        // Check if it's a chunk loading error
        if (
          errorAny?.message?.includes(
            'Failed to fetch dynamically imported module',
          ) ||
          errorAny?.message?.includes('Loading chunk') ||
          errorAny?.message?.includes('Loading CSS chunk') ||
          errorAny?.message?.includes('ChunkLoadError')
        ) {
          // Automatically refresh after showing the message
          setTimeout(() => window.location.reload(), 1500);

          // Show a friendly "updating" message
          return (
            <div
              style={{
                padding: '20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              <h2>Refreshing with updates!</h2>
              <p>We've made some improvements to the app. Reloading...</p>
            </div>
          );
        }

        // For other errors, use the default ErrorView
        return <ErrorView />;
      }}
    >
      <MantineProvider defaultColorScheme={colorScheme} theme={theme}>
        <AuthenticatedSessionContext.Provider value={authSession}>
          <Notifications position='bottom-left' />
          <BrowserRouter>
            <FirebaseAnalytics />

            <Routes>
              {/*
                1) Engagement Letters: Unauthenticated routes
                (Need to be accessible to non-logged-in users)
              */}
              <Route
                path='/engagement/:letterId/:matterId/:formattedName/:firmId'
                element={<SignEngagementLetter />}
              />
              <Route path='/findalawyer' element={<FindALawyer />} />

              <Route
                path='/privacy'
                element={
                  <Suspense fallback={<LoadingView />}>
                    <PrivacyPolicy />
                  </Suspense>
                }
              />
              <Route
                path='/paymentpage'
                element={
                  <Suspense fallback={<LoadingView />}>
                    <PaymentPage />
                  </Suspense>
                }
              />
              <Route
                path='/hostedpaymentredirect'
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          // This color matches the background color of the
                          // payment form, which is where this fallback view will
                          // be displayed.
                          backgroundColor: 'var(--mantine-color-gray-0)',
                          width: '100%',
                          height: '100%',
                        }}
                      ></div>
                    }
                  >
                    <HostedPaymentRedirect />
                  </Suspense>
                }
              />
              <Route
                path='/google9db2b2215cb7590d.html'
                element={<GoogleVerification />}
              />

              {/*
                2) Authenticated Routes: If user is logged in, use TermsProvider
              */}
              {authSession ? (
                <Route
                  path='*'
                  element={
                    <TermsProvider userId={authSession.userId}>
                      <SpotlightSearch>
                        <div
                          className={`App ${
                            isSidebarCollapsed ? 'sidebar-collapsed' : ''
                          }`}
                        >
                          <Header openMobileMenu={() => {}} />
                          <SidebarMenu collapsed={isSidebarCollapsed} />
                          <MainAppContent
                            authSession={authSession}
                            authStateUnknown={authStateUnknown}
                            shouldCollapseSidebar={(collapse) =>
                              setIsSidebarCollapsed(collapse)
                            }
                          />
                        </div>
                      </SpotlightSearch>
                    </TermsProvider>
                  }
                />
              ) : authStateUnknown ? null : (
                // When the browser is refreshed, the auth state resets and is
                // unknown for a moment. Without the above authStateUnknown
                // check, we'll enter the "unauthenticated" path below and
                // redirect the user to the login page, losing the current path.

                // 3) Unauthenticated Routes
                <>
                  <Route path='/' element={<LandingPage />} />
                  <Route
                    path='/login'
                    element={
                      <Suspense fallback={<LoadingView />}>
                        <EmployeeAuth />
                      </Suspense>
                    }
                  />
                  <Route path='*' element={<Navigate to='/login' />} />
                </>
              )}
            </Routes>
          </BrowserRouter>
        </AuthenticatedSessionContext.Provider>
      </MantineProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;

const MainAppContent = ({
  authSession,
  shouldCollapseSidebar,
}: {
  authSession: AuthenticatedSession;
  authStateUnknown: boolean;
  shouldCollapseSidebar: (collapse: boolean) => void;
}) => {
  const [shadowEdges, setShadowEdges] = useState<ShadowEdge[]>([]);
  const location = useLocation();

  useEffect(() => {
    shouldCollapseSidebar(
      ['/spreadsheet', '/claims'].includes(location.pathname),
    );
  }, [location.pathname]);

  return (
    <main
      className='main-content'
      style={{
        borderTop: shadowEdges.includes('top')
          ? '1px solid rgba(0, 0, 0, 0.1)'
          : 'none',
        borderLeft: shadowEdges.includes('left')
          ? '1px solid rgba(0, 0, 0, 0.1)'
          : 'none',
      }}
      onScroll={(e) => {
        const newShadowEdges: ShadowEdge[] = [];
        if ((e.target as HTMLElement).scrollTop > 0) {
          newShadowEdges.push('top');
        }
        if ((e.target as HTMLElement).scrollLeft > 0) {
          newShadowEdges.push('left');
        }
        setShadowEdges(newShadowEdges);
      }}
    >
      <AppRoutes authSession={authSession} />
    </main>
  );
};

const logAuthSessionCreated = (
  authSession: AuthenticatedSession,
  pcaConfig: Configuration,
) => {
  logEvent(analytics, 'auth_session_created', {
    tenant_id: authSession.tenantId,
    user_id: authSession.userId,
    user_email: authSession.userEmail,
    client_id: pcaConfig.auth.clientId,
    authority: pcaConfig.auth.authority,
    redirect_uri: pcaConfig.auth.redirectUri,
  });
  console.info(
    `Successfully signed into tenant ${authSession.tenantId} as ${authSession.userEmail}`,
  );
};
