import {
  Button,
  Group,
  Paper,
  Radio,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { ActionIcon } from '@mantine/core';
import { AtSignIcon, PhoneIcon, PlusIcon, Trash2Icon } from 'lucide-react';
import styles from './HumanContactForm.module.css';
import { v4 as uuidv4 } from 'uuid';
import { EmailAddress, PhoneNumber } from '../../../../database/baseTypes';
import { Form, HumanContact } from '../../types';

const HumanContactForm = ({
  form,
  title,
  humanIndex,
  isPrimary,
  onWantsPrimary,
  onDelete,
}: {
  form: Form;
  title: string;
  humanIndex: number;
  isPrimary: boolean;
  onWantsPrimary: () => void;
  onDelete: () => void;
}) => {
  const getHuman = () => {
    return form.getValues().humanContacts[humanIndex];
  };

  const setHuman = (fn: (human: HumanContact) => HumanContact) => {
    form.setValues((prevValues) => {
      if (!prevValues.humanContacts) {
        return prevValues;
      }
      prevValues.humanContacts[humanIndex] = fn(
        prevValues.humanContacts[humanIndex],
      );
      return prevValues;
    });
  };

  return (
    <Paper withBorder p='md' w='100%' className={styles.humanContactCard}>
      <Group align='stretch' pt='xs' pb='xs'>
        <Stack justify='flex-start'>
          <Radio
            aria-label='Primary contact'
            checked={isPrimary}
            onChange={onWantsPrimary}
            color='pink.4'
          />
        </Stack>

        <Stack align='stretch' flex={1}>
          <MatterContactFormHeader
            title={title}
            isPrimary={isPrimary}
            canDelete={true}
            onDelete={onDelete}
          />
          <Stack gap='sm'>
            <Group>
              <TextInput
                autoFocus={
                  form.getValues().humanContacts[humanIndex].firstName === ''
                }
                label='First Name'
                key={form.key(`humanContacts.${humanIndex}.firstName`)}
                {...form.getInputProps(`humanContacts.${humanIndex}.firstName`)}
                flex={1}
              />
              <TextInput
                label='Last Name'
                key={form.key(`humanContacts.${humanIndex}.lastName`)}
                {...form.getInputProps(`humanContacts.${humanIndex}.lastName`)}
                flex={1}
              />
            </Group>
            <PhoneNumbersForm getHuman={getHuman} setHuman={setHuman} />
            <EmailAddressesForm getHuman={getHuman} setHuman={setHuman} />
          </Stack>
        </Stack>
      </Group>
    </Paper>
  );
};

export default HumanContactForm;

const PhoneNumbersForm = ({
  getHuman,
  setHuman,
}: {
  getHuman: () => HumanContact;
  setHuman: (fn: (human: HumanContact) => HumanContact) => void;
}) => {
  const handleDelete = (phone: PhoneNumber) => {
    setHuman((prevHuman) => {
      let nextPrimaryPhoneNumberId = prevHuman.primaryPhoneNumberId;
      if (prevHuman.primaryPhoneNumberId === phone.id) {
        nextPrimaryPhoneNumberId =
          prevHuman.phoneNumbers?.find((p) => p.id !== phone.id)?.id ?? null;
      }
      return {
        ...prevHuman,
        phoneNumbers: prevHuman.phoneNumbers?.filter((p) => p.id !== phone.id),
        primaryPhoneNumberId: nextPrimaryPhoneNumberId,
      };
    });
  };

  const handleAdd = () => {
    setHuman((human) => {
      const updatedHuman = {
        ...human,
        phoneNumbers: [
          ...(human.phoneNumbers ?? []),
          { id: uuidv4(), value: '' },
        ],
      };
      return updatedHuman;
    });
  };

  const handleValueChange = (newValue: string, phone: PhoneNumber) => {
    setHuman((human) => {
      const updatedHuman = {
        ...human,
        phoneNumbers: human.phoneNumbers?.map((p) =>
          p.id === phone.id ? { ...p, value: newValue } : p,
        ),
      };
      return updatedHuman;
    });
  };

  const handlePrimaryChange = (phone: PhoneNumber) => {
    setHuman((human) => ({
      ...human,
      primaryPhoneNumberId: phone.id,
    }));
  };

  return (
    <div className='phone-numbers-form'>
      <CollectionHeader
        collectionName='Phone Numbers'
        collectionLength={getHuman().phoneNumbers?.length ?? 0}
      />
      <ul style={{ margin: '0', paddingInlineStart: '0' }}>
        {getHuman().phoneNumbers?.map((phone, phoneIndex) => (
          <CollectionRow
            key={phone.id}
            item={phone}
            onValueChange={(e) => {
              handleValueChange(e.target.value, phone);
            }}
            label={`Phone number ${phoneIndex + 1}`}
            isPrimary={getHuman().primaryPhoneNumberId === phone.id}
            canDelete={getHuman().phoneNumbers?.length > 1}
            textInputLeftSection={<PhoneIcon size={16} />}
            onPrimaryChange={() => handlePrimaryChange(phone)}
            onDelete={() => handleDelete(phone)}
          />
        ))}
      </ul>
      <AddToCollectionButton title='Add a phone number' onClick={handleAdd} />
    </div>
  );
};

const EmailAddressesForm = ({
  getHuman,
  setHuman,
}: {
  getHuman: () => HumanContact;
  setHuman: (fn: (human: HumanContact) => HumanContact) => void;
}) => {
  const handleDelete = (email: EmailAddress) => {
    setHuman((prevHuman) => {
      let nextPrimaryEmailAddressId = prevHuman.primaryEmailAddressId;
      if (nextPrimaryEmailAddressId === email.id) {
        nextPrimaryEmailAddressId =
          prevHuman.emailAddresses?.find((e) => e.id !== email.id)?.id ?? null;
      }
      return {
        ...prevHuman,
        emailAddresses: prevHuman.emailAddresses?.filter(
          (e) => e.id !== email.id,
        ),
        primaryEmailAddressId: nextPrimaryEmailAddressId,
      };
    });
  };

  const handleAdd = () => {
    setHuman((human) => ({
      ...human,
      emailAddresses: [
        ...(human.emailAddresses ?? []),
        { id: uuidv4(), value: '' },
      ],
    }));
  };

  const handleValueChange = (newValue: string, email: EmailAddress) => {
    setHuman((human) => ({
      ...human,
      emailAddresses: human.emailAddresses?.map((e) =>
        e.id === email.id ? { ...e, value: newValue } : e,
      ),
    }));
  };

  const handlePrimaryChange = (email: EmailAddress) => {
    setHuman((human) => ({
      ...human,
      primaryEmailAddressId: email.id,
    }));
  };

  return (
    <div className='email-addresses-form'>
      <CollectionHeader
        collectionName='Email Addresses'
        collectionLength={getHuman().emailAddresses?.length ?? 0}
      />
      <ul style={{ margin: '0', paddingInlineStart: '0' }}>
        {getHuman().emailAddresses?.map((email, emailIndex) => (
          <CollectionRow
            key={email.id}
            item={email}
            label={`Email address ${emailIndex + 1}`}
            onValueChange={(e) => {
              handleValueChange(e.target.value, email);
            }}
            isPrimary={getHuman().primaryEmailAddressId === email.id}
            canDelete={getHuman().emailAddresses?.length > 1}
            textInputLeftSection={<AtSignIcon size={16} />}
            onPrimaryChange={() => handlePrimaryChange(email)}
            onDelete={() => handleDelete(email)}
          />
        ))}
      </ul>
      <AddToCollectionButton title='Add an email address' onClick={handleAdd} />
    </div>
  );
};

const MatterContactFormHeader = ({
  title,
  isPrimary,
  canDelete,
  onDelete,
}: {
  title: string;
  isPrimary: boolean;
  canDelete: boolean;
  onDelete: () => void;
}) => {
  return (
    <Group justify='space-between'>
      <Group align='baseline' gap='sm' h='2rem'>
        <Title order={3} style={{ margin: '0', color: '#333' }}>
          {title}
        </Title>
        {isPrimary && (
          <Text
            aria-label='Is primary contact'
            fw={400}
            c='pink.4'
            style={{ fontSize: '0.75rem', letterSpacing: '0.02rem' }}
          >
            Primary
          </Text>
        )}
      </Group>
      {canDelete && (
        <ActionIcon aria-label={`Delete ${title}`} onClick={onDelete}>
          <Trash2Icon size={16} />
        </ActionIcon>
      )}
    </Group>
  );
};

const CollectionHeader = ({
  collectionName,
  collectionLength,
}: {
  collectionName: string;
  collectionLength: number;
}) => {
  return (
    <div className={styles.collectionGridLine}>
      <Text size='sm' fw={500}>
        {collectionName}
      </Text>
      <Text size='xs' style={{ justifySelf: 'center', alignSelf: 'flex-end' }}>
        {collectionLength > 0 && 'Primary'}
      </Text>
      <Text size='xs'></Text>
    </div>
  );
};

const CollectionRow = ({
  item,
  label,
  isPrimary,
  canDelete,
  textInputLeftSection,
  onValueChange,
  onPrimaryChange,
  onDelete,
}: {
  item: PhoneNumber | EmailAddress;
  label: string;
  isPrimary: boolean;
  canDelete: boolean;
  textInputLeftSection: React.ReactNode;
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPrimaryChange: () => void;
  onDelete: () => void;
}) => {
  return (
    <li className={styles.collectionGridLine} key={item.id}>
      <TextInput
        aria-label={label}
        leftSection={textInputLeftSection}
        key={`${item.id}-input`}
        value={item.value}
        onChange={onValueChange}
        flex={1}
      />

      <Radio
        aria-label={`Set ${label} as primary`}
        classNames={styles}
        style={{ justifySelf: 'center' }}
        checked={isPrimary}
        onChange={onPrimaryChange}
        color='blue.4'
      />

      {canDelete && (
        <ActionIcon
          aria-label={`Delete ${label}`}
          variant='subtle'
          onClick={onDelete}
        >
          <Trash2Icon size={16} />
        </ActionIcon>
      )}
    </li>
  );
};

const AddToCollectionButton = ({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) => {
  return (
    <Button
      variant='subtle'
      leftSection={<PlusIcon size={16} />}
      className={styles.addToCollectionButton}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};
