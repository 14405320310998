import { SelectOption } from '../../types';
import CurrencyInput from './CurrencyInput';
import { MatterType } from '../../../optionSets';
import { Form } from '../types';
import { TextInput } from '@mantine/core';
import ControlledSelect from './ControlledSelect';
import { ChevronsUpDownIcon } from 'lucide-react';

const InsuranceCaseInstructions = () => {
  return (
    <div style={{ textAlign: 'center', margin: '0.75rem 0' }}>
      <p style={{ margin: '0', fontSize: '0.9rem', fontWeight: '700' }}>
        If case is reduced fee out-of-pocket, choose Flat Fee or Hourly instead.
      </p>
      <p style={{ margin: '0', fontSize: '0.9rem', fontWeight: '700' }}>
        Verify coverage before assigning to attorney.
      </p>
    </div>
  );
};

const MatterTypeFields = ({
  matterType,
  attorneys,
  form,
}: {
  matterType: MatterType | undefined;
  attorneys: SelectOption[];
  form: Form;
}) => {
  const DropdownIndicator = () => {
    return (
      <div
        style={{ paddingRight: '7px', display: 'flex', alignItems: 'center' }}
      >
        <ChevronsUpDownIcon size={14} color='#b3b3b3' strokeWidth={2} />
      </div>
    );
  };

  const IndicatorSeparator = () => null;

  const selectProps = {
    isClearable: false,
    components: {
      DropdownIndicator,
      IndicatorSeparator,
    },
    options: attorneys,
    menuPortalTarget: document.body,
    styles: {
      menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
      }),
      control: (base: any) => ({
        ...base,
        borderColor: '#e0e0e0',
        '&:hover': {
          borderColor: '#e0e0e0',
        },
        fontSize: '14px',
        minHeight: '34px',
        height: '34px',
      }),
      option: (base: any) => ({
        ...base,
        fontSize: '14px',
      }),
      singleValue: (base: any) => ({
        ...base,
        fontSize: '14px',
      }),
    },
  };

  if (!matterType) {
    return null;
  }

  return (
    <div className='matter-type-specific-fields'>
      {matterType === 'ARAG' && (
        <>
          <InsuranceCaseInstructions />
          <div className='input-row'>
            <TextInput
              className='field'
              {...form.getInputProps('caseAssist')}
              label='Case Assist'
            />
            <TextInput
              className='field'
              {...form.getInputProps('memberId')}
              label='Member ID'
            />
          </div>
          <div className='input-row'>
            <CurrencyInput form={form} name='caseValue' label='Case Value' />
            <TextInput
              className='field'
              {...form.getInputProps('insuranceCaseType')}
              label='Insurance Case Type'
              id='insuranceCaseType'
            />
          </div>
        </>
      )}
      {matterType === 'MetLife' && (
        <>
          <InsuranceCaseInstructions />
          <div className='input-row'>
            <TextInput
              className='field'
              label='MetLife Claim Number'
              {...form.getInputProps('metLifeClaimNumber')}
              id='metLifeClaimNumber'
            />
            <TextInput
              className='field'
              label='Last 4 of SSN / Member ID / Eligibility ID'
              {...form.getInputProps('socialSecurityNumber')}
              id='socialSecurityNumber'
            />
          </div>
          <div className='input-row'>
            <CurrencyInput form={form} name='caseValue' label='Case Value' />
            <TextInput
              className='field'
              label='Insurance Case Type'
              {...form.getInputProps('insuranceCaseType')}
              id='insuranceCaseType'
            />
          </div>
        </>
      )}
      {matterType === 'LegalEase' && (
        <>
          <InsuranceCaseInstructions />
          <div className='input-row'>
            <TextInput
              className='field'
              label='Case Number'
              {...form.getInputProps('caseNumber')}
              id='caseNumber'
            />
          </div>
          <div className='input-row'>
            <CurrencyInput form={form} name='caseValue' label='Case Value' />
            <TextInput
              className='field'
              label='Insurance Case Type'
              {...form.getInputProps('insuranceCaseType')}
              id='insuranceCaseType'
            />
          </div>
        </>
      )}
      {matterType === 'Hourly' && (
        <>
          <div className='input-row'>
            <CurrencyInput form={form} name='retainer' label='Retainer' />
            <CurrencyInput
              form={form}
              name='responsibleAttyHourlyRate'
              label='Responsible Atty Hourly Rate'
            />
          </div>
          <div className='input-row'>
            <ControlledSelect
              fieldName='referralOriginatorId'
              label='Referral Originator'
              options={attorneys}
              form={form}
              selectProps={selectProps}
            />
            <ControlledSelect
              fieldName='engagementOriginatorId'
              label='Engagement Originator'
              options={attorneys}
              form={form}
              selectProps={selectProps}
            />
          </div>
        </>
      )}
      {matterType === 'FlatFee' && (
        <>
          <div className='input-row'>
            <CurrencyInput
              form={form}
              name='flatFeeAmt'
              label='Flat Fee Amount'
            />
          </div>
          <div className='input-row'>
            <ControlledSelect
              fieldName='referralOriginatorId'
              label='Referral Originator'
              options={attorneys}
              form={form}
              selectProps={selectProps}
            />
            <ControlledSelect
              fieldName='engagementOriginatorId'
              label='Engagement Originator'
              options={attorneys}
              form={form}
              selectProps={selectProps}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MatterTypeFields;
