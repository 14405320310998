import {
  TypesenseHit,
  TypesenseHuman,
  TypesenseOrganization,
  TypesenseSearchMode,
} from '../../typesense/types';
import {
  Combobox,
  Group,
  Loader,
  Text,
  TextInput,
  useCombobox,
  ScrollArea,
} from '@mantine/core';
import { useRef, useState } from 'react';
import { TypesenseClient } from '../../typesense/typesenseClient';
import { debounce } from './util';
import { ContactHit } from './ContactHit';
import { v4 as uuidv4 } from 'uuid';
import classes from './ContactSearchField.module.css';
import { Building2Icon, UserPlusIcon } from 'lucide-react';

type ContactSearchState =
  | { type: 'idle'; query: string; mode: TypesenseSearchMode }
  | { type: 'loading'; query: string; mode: TypesenseSearchMode }
  | {
      type: 'error';
      query: string;
      error: string;
      mode: TypesenseSearchMode;
    }
  | {
      type: 'success';
      query: string;
      mode: TypesenseSearchMode;
      results: TypesenseHit<TypesenseHuman | TypesenseOrganization>[];
    };

export type ContactSearchSelection =
  | { type: 'existingHuman'; value: TypesenseHuman }
  | { type: 'existingOrganization'; value: TypesenseOrganization }
  | { type: 'newHuman'; id: string; query: string }
  | { type: 'newOrganization'; id: string; query: string };

export const ContactSearchField = ({
  mode,
  onSelect,
  excludeContacts = [],
  autoFocus,
  isDisabled,
}: {
  mode: TypesenseSearchMode;
  onSelect: (value: ContactSearchSelection) => void;
  excludeContacts?: string[];
  autoFocus?: boolean;
  isDisabled: boolean;
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const client = useRef(new TypesenseClient());
  const [state, setState] = useState<ContactSearchState>({
    type: 'idle',
    query: '',
    mode,
  });

  const fetchOptions = async (query: string) => {
    setState({ type: 'idle', query, mode });

    if (!query.trim()) {
      return;
    }

    try {
      const response = await debounce(async () => {
        setState((prevState) => {
          if (prevState.query !== query) {
            return prevState;
          }
          if (prevState.mode !== mode) {
            return prevState;
          }
          return { type: 'loading', query, mode };
        });
        switch (mode) {
          case 'human':
            return await client.current.searchHumans({ query });
          case 'organization':
            return await client.current.searchOrganizations({ query });
          case 'all':
            return await client.current.searchHumansAndOrganizations({ query });
        }
      }, 300)();
      setState((prevState) => {
        if (prevState.query !== query) {
          return prevState;
        }
        if (prevState.mode !== mode) {
          return prevState;
        }
        return { type: 'success', query, mode, results: response.hits ?? [] };
      });
    } catch (err) {
      console.error('Error searching:', err);
      setState((prevState) => {
        if (prevState.query !== query) {
          return prevState;
        }
        if (prevState.mode !== mode) {
          return prevState;
        }
        return {
          type: 'error',
          query,
          mode,
          error:
            'Failed to perform search. Please check if Typesense server is running.',
        };
      });
    }
  };

  const handleSelect = (optionValue: string) => {
    combobox.closeDropdown();

    if (optionValue === 'new-human') {
      onSelect({ type: 'newHuman', id: uuidv4(), query: state.query });
      setState({ type: 'idle', query: '', mode });
      return;
    }

    if (optionValue === 'new-organization') {
      onSelect({ type: 'newOrganization', id: uuidv4(), query: state.query });
      setState({ type: 'idle', query: '', mode });
      return;
    }

    if (state.type !== 'success') {
      return;
    }

    const hit = state.results.find((hit) => hit.document.id === optionValue);
    if (hit) {
      if (hit.document.companyName) {
        onSelect({
          type: 'existingOrganization',
          value: hit.document as TypesenseOrganization,
        });
      } else {
        onSelect({
          type: 'existingHuman',
          value: hit.document as TypesenseHuman,
        });
      }
    }
    setState({ type: 'idle', query: '', mode });
  };

  const options = (
    state.type === 'success'
      ? state.results.filter(
          (item) => !excludeContacts.includes(item.document.id),
        )
      : []
  ).map((item) => (
    <Combobox.Option value={item.document.id} key={item.document.id}>
      <ContactHit hit={item} />
    </Combobox.Option>
  ));

  return (
    <Combobox
      onOptionSubmit={(optionValue) => {
        handleSelect(optionValue);
      }}
      withinPortal={false}
      store={combobox}
      classNames={{
        dropdown: classes.dropdown,
        option: classes.option,
      }}
    >
      <Combobox.Target>
        <TextInput
          style={{ width: '100%' }}
          placeholder={
            mode === 'human'
              ? 'Search by contact name, email or phone'
              : mode === 'organization'
              ? 'Search by company name'
              : 'Search by contact name, email, phone or company name'
          }
          value={state.query}
          onChange={(event) => {
            combobox.openDropdown();
            fetchOptions(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => {
            if (state.query) {
              combobox.openDropdown();
              if (state.type !== 'success') {
                fetchOptions(state.query);
              }
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'ArrowDown') {
              combobox.openDropdown();
            }
          }}
          onBlur={() => combobox.closeDropdown()}
          rightSection={state.type === 'loading' && <Loader size={18} />}
          autoFocus={autoFocus}
          classNames={{
            input: classes.search,
          }}
          disabled={isDisabled}
        />
      </Combobox.Target>

      <Combobox.Dropdown
        style={{ boxShadow: 'var(--mantine-shadow-md)' }}
        hidden={!combobox.dropdownOpened}
      >
        <Combobox.Options>
          <ScrollArea.Autosize type="scroll" mah="50vh">
            {state.type === 'loading' ? (
              <Group justify="center" py="md">
                <Loader size="sm" />
              </Group>
            ) : (
              <>
                {options}
                {(mode === 'human' || mode === 'all') && (
                  <Combobox.Option value="new-human">
                    <Group gap="xs" className={classes.createNewContact}>
                      <UserPlusIcon size={16} />
                      <Text size="sm" fw={500} lts="0.03em">
                        Create new person
                      </Text>
                    </Group>
                  </Combobox.Option>
                )}

                {(mode === 'organization' || mode === 'all') && (
                  <Combobox.Option value="new-organization">
                    <Group gap="xs" className={classes.createNewContact}>
                      <Building2Icon size={16} />
                      <Text size="sm" fw={500} lts="0.03em">
                        Create new company
                      </Text>
                    </Group>
                  </Combobox.Option>
                )}
              </>
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
